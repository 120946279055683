import { useCallback, useState, useContext, useEffect, createContext } from 'react';

import { ContextMenu } from '../components/components.mjs';
import { Kernel } from '../kernel/Kernel.mjs';

const KernelContext = createContext({
  currentLicense: {},
  setCurrentLicense: () => { },
});

const kernelState = new Kernel();
window.kernel = kernelState;

export const KernelProvider = ({ children }) => {
  const [kernel, setKernel] = useState(kernelState);
  const [currentLicense, setCurrentLicense] = useState({});
  const [contextMenuState, setContextMenuState] = useState({ position: [-100, -100], contextMenuItems: [], contextData: {} });

  //функция для работы с контекстом универсальным компонентом ContextMenu
  const setContextMenu = useCallback((items, position, contextData) => {
    setContextMenuState({ position: position, contextMenuItems: items, contextData: contextData });
  }, []);

  const closeMenu = useCallback(() => {
    setContextMenuState((state) => {
      return {
        ...state,
        position: undefined,
      };
    });
  }, []);

  const setLicense = (licenses) => {
    setCurrentLicense(licenses);
  };

  useEffect(() => {
    document.body.addEventListener('click', closeMenu);
    return () => {
      document.body.removeEventListener('click', closeMenu);
    };
  }, [closeMenu]);

  return (
    <KernelContext.Provider value={{ kernel, setContextMenu, setLicense, currentLicense }}>
      {contextMenuState?.position && <ContextMenu contextMenuState={contextMenuState} />}
      {children}
    </KernelContext.Provider>
  );
};

export const useKernel = () => useContext(KernelContext);
