import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Utils from '../../utils/Utils.mjs';
import { ReactComponent as BsArrowRightCircleFill } from '../../assets/icon/BsArrowLeftCircle.svg';
import { ContentApp, HeaderApp, NavApp } from '../../layout/layout.mjs';
import { TreeConfiguration, ErrorMessagePopup, ConfirmationPopUp, ConfirmationPopUpWithTimer } from '../../components/components.mjs';
import { ConfigurationService } from '../../services/services.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { ComponentPropertyEditor } from '../../hoc/hoc.mjs';

import styles from './propertyEditor.module.scss';

export default function PropertyEditor() {
  const location = useLocation();
  const navigate = useNavigate();
  const { kernel } = useKernel();
  const [configuration, setConfiguration] = useState(null);
  const [configurationName, setConfigurationName] = useState(null);
  const [stateElementConfigurationId, setStateElementConfigurationId] = useState(kernel.stateElementConfigurationId);
  const [userName, setUserName] = useState('');
  const [isError, setIsError] = useState(false);
  const [isErrorLoadingConfiguration, setIsErrorLoadingConfiguration] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Ошибка!');
  const [componentsTypeId, setComponentsTypeId] = useState(0);
  const [selectedParameter, setSelectedParameter] = useState({});
  const [property, setProperty] = useState({});

  const [removeObject, setRemoveObject] = useState({ objectId: null, isVisible: false, message: '', canSave: false, sending: false });
  const [restoreObject, setRestoreObject] = useState({ objectId: null, isVisible: false, message: '', canSave: false, sending: false });
  const [rotate, setRotate] = useState(false);
  const [confirmationPopUpVisible, setConfirmationPopUpVisible] = useState(false);

  const buildConfigurationTree = async () => {
    try {
      const { tree } = await kernel.buildTree(location);

      setConfiguration(tree);
      setConfigurationName(kernel.configuration.name);
      setUserName(kernel.getUser().name);
    } catch (error) {
      setIsErrorLoadingConfiguration(true);
      setErrorMessage('Ошибка загрузки конфигурации!');
    }
  };

  const deleteObject = async () => {
    try {
      setRemoveObject({
        ...removeObject,
        sending: true,
      });

      const configId = kernel.getActiveConfiguration().id;
      await ConfigurationService.deleteObject(removeObject.objectId.toString(), configId);

      await buildConfigurationTree();

      setTimeout(() => {
        setRemoveObject({ objectId: null, isVisible: false, message: '', canSave: false, sending: false });
      }, 500);
    } catch (error) {
      setRemoveObject({ objectId: null, isVisible: false, message: '', canSave: false, sending: false });
      setIsError(true);
      setErrorMessage(error.message);
    }
  };

  const recoveryObject = async () => {
    try {
      setRestoreObject({
        ...restoreObject,
        sending: true,
      });

      const configId = kernel.getActiveConfiguration().id;
      await ConfigurationService.recoveryObject(restoreObject.objectId.toString(), configId);

      await buildConfigurationTree();

      setTimeout(() => {
        setRestoreObject({ objectId: null, isVisible: false, message: '', canSave: false, sending: false });
      }, 500);
    } catch (error) {
      setRestoreObject({ objectId: null, isVisible: false, message: '', canSave: false, sending: false });
      setIsError(true);
      setErrorMessage(error.message);
    }
  };

  const moveNodeInConfigurationTree = (treeNodeDrag, treeNodeDragOver) => {
    kernel.setDragOverNodeId(treeNodeDragOver.id.toString());
    setConfirmationPopUpVisible(true);
  };

  const updateConfiguration = () => {
    setConfiguration(null);
    setStateElementConfigurationId(0);
    buildConfigurationTree();
  };

  const confirmationMoveNodeInConfigurationTree = async () => {
    try {
      setConfiguration(null);
      setConfirmationPopUpVisible(false);

      const treeNodeDrag = kernel.getParameterById(kernel.getDragStartNodeId());
      const treeNodeDragOver = kernel.getParameterById(kernel.getDragOverNodeId());

      const configId = kernel.getActiveConfiguration().id;
      const parameterId = treeNodeDrag.id.toString();
      const parameterParentId = treeNodeDragOver.id.toString();

      treeNodeDrag.parent.children = treeNodeDrag.parent.children.filter((parameter) => parameter.id.toString() !== parameterId); // удаление объекта
      treeNodeDragOver.children.push(treeNodeDrag); // добавление объекта
      treeNodeDragOver.children = Utils.sortParameterChildren(treeNodeDragOver.children); // сортировка потомков у параметра
      treeNodeDrag.parameterStruct.parentId = BigInt(parameterParentId); // изменение parentId

      kernel.setDragOverNodeId(null); // сброс id элементов над которыми возникало событие onDropEnter
      kernel.setDragStartNodeId(null); // сброс id элементов над которыми возникало событие onDropStart

      setConfiguration(kernel.getParameterById(0)); // обновление состояния дерева

      await ConfigurationService.changeParentIdOfParameter(parameterId, parameterParentId, configId);
      await buildConfigurationTree();
    } catch (error) {
      await buildConfigurationTree();
      kernel.setDragOverNodeId(null);
      kernel.setDragStartNodeId(null);
      setIsError(true);
      setErrorMessage(error.message);
    }
  };

  const closePopupConfirmationPopUp = () => {
    setConfirmationPopUpVisible(false);
  };

  const buildTree = async () => {
    try {
      await kernel.buildLicensesTree();
    } catch (error) {
      setIsError(true);
      setErrorMessage('Ошибка загрузки лицензии!');
    }
  };

  useEffect(() => {
    buildConfigurationTree();
    buildTree();
    kernel.timerRefreshToken(); //запуск обновления токена
    kernel.setNavigatePath('/property-editor');
    return () => {
      kernel.stateElementConfigurationId = 0n;
    };
  }, []);

  return (
    <div className={styles.propertyEditor}>
      {isError && <ErrorMessagePopup errorMessage={errorMessage} actionPerform={() => setIsError(false)} />}
      {isErrorLoadingConfiguration && (
        <ErrorMessagePopup
          errorMessage={errorMessage}
          actionPerform={() => {
            setIsErrorLoadingConfiguration(false);
            kernel.setNavigatePath('/property-editor');
            navigate('/configuration-selection');
          }}
        />
      )}
      {removeObject.isVisible && <ConfirmationPopUpWithTimer callBack={deleteObject} state={removeObject} setState={setRemoveObject} />}
      {restoreObject.isVisible && <ConfirmationPopUpWithTimer callBack={recoveryObject} state={restoreObject} setState={setRestoreObject} />}
      {confirmationPopUpVisible && (
        <ConfirmationPopUp
          actionConfirmation={confirmationMoveNodeInConfigurationTree}
          closePopup={closePopupConfirmationPopUp}
          message="Вы точно хотите переместить объект?"
        />
      )}
      <NavApp />
      <ContentApp>
        <div className={styles.headerContainer}>
          <HeaderApp configurationName={configurationName} userName={userName} />
        </div>
        <div className={styles.contentContainer}>
          <div className={classNames(styles.treeContainer, rotate && styles.collapsedTree)} id="treeConfigurationContainer">
            <div className={classNames(styles.treeWrap, rotate && styles.collapsedTreeWrap)}>
              <TreeConfiguration
                configuration={configuration}
                stateElementConfigurationId={stateElementConfigurationId}
                setStateElementConfigurationId={setStateElementConfigurationId}
                componentsTypeId={componentsTypeId}
                setComponentsTypeId={setComponentsTypeId}
                setSelectedParameter={setSelectedParameter}
                removeObject={removeObject}
                setRemoveObject={setRemoveObject}
                restoreObject={restoreObject}
                setRestoreObject={setRestoreObject}
                moveNodeInConfigurationTree={moveNodeInConfigurationTree}
                setIsError={setIsError}
                setErrorMessage={setErrorMessage}
                updateConfiguration={updateConfiguration}
              />
            </div>
            <BsArrowRightCircleFill
              className={classNames(styles.treeControl, rotate && styles.treeControlActive)}
              onClick={() => setRotate(!rotate)}
            />
          </div>
          <ComponentPropertyEditor
            componentsTypeId={componentsTypeId}
            setComponentsTypeId={setComponentsTypeId}
            stateElementConfigurationId={stateElementConfigurationId}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
            selectedParameter={selectedParameter}
            setConfiguration={setConfiguration}
            property={property}
            setProperty={setProperty}
            buildConfigurationTree={buildConfigurationTree}
          />
        </div>
      </ContentApp>
    </div>
  );
}
