
import { LRLanguage, LanguageSupport, indentNodeProp, foldNodeProp, foldInside } from "@codemirror/language";
import { completeFromList } from "@codemirror/autocomplete";

import { parser } from "./func.parser.js";
import { highlighting } from './func.highlight.js';



const parserQsSrvCalcFuncLanguage = parser.configure({
  props: [
    highlighting,
    // indentNodeProp.add({
    //   Application: context => context.column(context.node.from) + context.unit,
    // }),
    // foldNodeProp.add({
    //   Application: foldInside,
    // }),
  ],
});

export const qsSrvCalcFuncLanguage = LRLanguage.define({
  parser: parserQsSrvCalcFuncLanguage,
});

// export const qsSrvCalcFuncLanguageCompletion = qsSrvCalcFuncLanguage.data.of({
//   autocomplete: completeFromList([
//     { label: "SUM", type: "function" },
//     { label: "SUB", type: "function" },
//     { label: "MUL", type: "function" },
//     { label: "DIV", type: "function" },
//     { label: "POW", type: "function" },
//     { label: "ROOT", type: "function" },
//     { label: "ABS", type: "function" },
//     { label: "MAX", type: "function" },
//     { label: "MAXP", type: "function" },
//     { label: "MIN", type: "function" },
//     { label: "MINP", type: "function" },
//     { label: "AVG", type: "function" },
//     { label: "AVGP", type: "function" },
//     { label: "MED", type: "function" },
//     { label: "INT", type: "function" },
//     { label: "TS", type: "function" },
//     { label: "BOD", type: "function" },
//     { label: "BOM", type: "function" },
//     { label: "ADDHOUR", type: "function" },
//     { label: "REPHOUR", type: "function" },
//     { label: "IF", type: "function" },
//     { label: "EQUAL", type: "function" },
//     { label: "NEQUAL", type: "function" },
//     { label: "MORE", type: "function" },
//     { label: "NMORE", type: "function" },
//     { label: "LESS", type: "function" },
//     { label: "NLESS", type: "function" },
//     { label: "AND", type: "function" },
//     { label: "OR", type: "function" },
//     { label: "XOR", type: "function" },
//     { label: "NOT", type: "function" },
//     { label: "EXP", type: "function" },
//     { label: "LN", type: "function" },
//     { label: "LOG", type: "function" },
//     { label: "HOUR", type: "function" },
//     { label: "DAY", type: "function" },
//     { label: "MONTH", type: "function" },
//     { label: "YEAR", type: "function" },
//     { label: "DAYWEEK", type: "function" },
//     { label: "ROUND", type: "function" },
//     { label: "BITAND", type: "function" },
//     { label: "BITOR", type: "function" },
//     { label: "BITXOR", type: "function" },
//     { label: "BITML", type: "function" },
//     { label: "BITMR", type: "function" },
//   ]),
// });

export function wrapperQsSrvCalcFuncLanguage() {
  // return new LanguageSupport(qsSrvCalcFuncLanguage, [qsSrvCalcFuncLanguageCompletion]);
  return new LanguageSupport(qsSrvCalcFuncLanguage);
}