import { useEffect, useRef, useState } from 'react';
import { useYMaps } from '@pbe/react-yandex-maps';
import { BsArrowLeftCircle, BsPlusSquareDotted } from 'react-icons/bs';
import { AiTwotoneSave } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';

import { Loading, LoadingInTableSave } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './polylineEditor.module.scss';

export default function PolylineEditor(props) {
  const {
    saveProperty,
    setOpenPolylineEditor,
    setValue,
    name,
    coordinatesLineValue,
    setIsError,
    setErrorMessage,
    sendingData,
    dataSentSuccessfully,
    coordObject,
  } = props;

  const ymaps = useYMaps(['Map', 'geoObject.addon.editor', 'ObjectManager', 'Polyline', 'Placemark']);
  const mapRefElem = useRef(null);
  const filesRef = useRef();
  const myMap = useRef(null);
  const polylineRef = useRef('');

  const { currentLicense } = useKernel();

  const [geoJsonFileName, setGeoJsonFileName] = useState('');
  const [loading, setLoading] = useState(false);

  const getFileScheme = (file) => file && { file: file, type: file.type, name: file.name };

  const parseCoordinateFromYMap = () => {
    const coordinateInYMap = coordinatesLineValue.split('|');

    if (coordinateInYMap.length >= 1 && coordinateInYMap[0] !== '') {
      const newcoordinateInYMap = coordinateInYMap.map((coordinate) => {
        const [x, y] = coordinate.split(',');
        return [parseFloat(x), parseFloat(y)];
      });

      return newcoordinateInYMap;
    }
    return [];
  };

  const setGeoJsonCoordinates = () => {
    if (!sendingData) {
      filesRef.current.click();
    }
  };

  const getCoordinatesPolyline = async () => {
    if (polylineRef.current?.geometry) {
      const coordinatesPolyline = polylineRef.current.geometry.getCoordinates();
      const coordinatesItems = coordinatesPolyline.map((coordinates) => {
        let [x, y] = [...coordinates];

        x = parseInt(x * 1000000) / 1000000;
        y = parseInt(y * 1000000) / 1000000;

        return `${x},${y}|`;
      });
      const coordinatesStr = coordinatesItems.join('').slice(0, -1); // координаты в правильном формате в виде строки
      setValue(name, coordinatesStr);

      await saveProperty({ value: coordinatesStr }); // сохранение параметра в kernel и на бэке

      setOpenPolylineEditor(false);
    } else {
      setValue(name, '');
      await saveProperty({ value: '' }); // сохранение параметра в kernel и на бэке

      setOpenPolylineEditor(false);
    }
  };

  const dataChecking = (event, files, file) => {
    //проверка на количество файлов
    if (files.length === 1) {
      setGeoJsonFileName(file.name);

      const fileName = file.name.split('.');
      const fileExtension = fileName[fileName.length - 1];

      if (fileExtension === 'json') {
        //проверка на тип файла
        const reader = new FileReader();
        let fileData;
        reader.onload = (event) => {
          fileData = JSON.parse(event.target.result);
          fileData = fileData.map((obj, i) => {
            obj.coordinates = obj.coordinates.map((coords) => {
              const [x, y] = coords;
              return [y, x];
            });
            return {
              type: 'Feature',
              id: i,
              geometry: obj,
            };
          });

          myMap.current.geoObjects.removeAll();

          const objectManager = new ymaps.ObjectManager();
          objectManager.add(fileData);
          myMap.current.geoObjects.add(objectManager);
          myMap.current.setCenter([fileData[0].geometry.coordinates[0][0], fileData[0].geometry.coordinates[0][1]]);

          const initialCoord = parseCoordinateFromYMap(); // получение координат линии
          createPolyline(initialCoord); // отрисовка линии

          // проверка существуют ли координаты вообще
          if (coordObject.length > 0) {
            // получение координат объекта
            const [mapsCoordinateObjectLatitude, mapsCoordinateObjectLongitude] = coordObject.split(',');
            createPlacemark([mapsCoordinateObjectLatitude, mapsCoordinateObjectLongitude]); // отрисовка точки объекта
          }

          setLoading(false);
        };
        reader.readAsText(files[0]);
      } else {
        event.target.value = null;
        setIsError(true);
        setLoading(false);
        setErrorMessage('Выбранный вами файл содержит невалидный формат!');
      }
    } else {
      event.target.value = null;
      setIsError(true);
      setLoading(false);
      setErrorMessage('Вы можете прикрепить только 1 файл лицензии за раз!');
    }
  };

  const addGeoJsonFile = (event) => {
    setLoading(true);

    const { files } = event.target;
    const file = getFileScheme(files.item(0));

    dataChecking(event, files, file);
  };

  const createPolyline = (initialCoord) => {
    // отрисовка линии
    const polyline = new ymaps.Polyline(
      initialCoord,
      {},
      {
        strokeColor: currentLicense.MAPS_POLYLINE_STROKE_COLOR || '#c81f1f',
        strokeWidth: 4, // ширина линии
        editorMaxPoints: 11, // максимальное количество точек линии
        strokeOpacity: 0.6, // прозрачность линии
        editorMenuManager: function (items) {
          items.splice(1, 1);

          items.push({
            title: 'Завершить',
            onClick: function () {
              polyline.editor.stopDrawing();
            },
          });

          items.push({
            title: 'Продолжить',
            onClick: function () {
              polyline.editor.startDrawing();
            },
          });

          items.push({
            title: 'Очистить',
            onClick: function () {
              myMap.current.geoObjects.remove(polyline);
              polylineRef.current = '';
              createPolyline([]);
            },
          });
          return items;
        },
      },
    );

    myMap.current.geoObjects.add(polyline);
    polylineRef.current = polyline;
    polyline.editor.startEditing();
    polyline.editor.startDrawing();
  };

  const createPlacemark = (initialCoord) => {
    const placemark = new ymaps.Placemark(initialCoord, {}, { preset: 'twirl#blueStretchyIcon', draggable: false });

    myMap.current.geoObjects.add(placemark);
    myMap.current.setCenter(placemark.geometry.getCoordinates());
  };

  useEffect(() => {
    if (!ymaps || !mapRefElem.current) {
      return;
    }

    if (coordObject.length > 0) {
      const [mapsCoordinateObjectLatitude, mapsCoordinateObjectLongitude] = coordObject.split(',');

      myMap.current = new ymaps.Map(mapRefElem.current, {
        center: [parseFloat(mapsCoordinateObjectLatitude), parseFloat(mapsCoordinateObjectLongitude)],
        zoom: parseInt(currentLicense.MAPS_ZOOM),
      });

      createPlacemark([mapsCoordinateObjectLatitude, mapsCoordinateObjectLongitude]);
    } else {
      myMap.current = new ymaps.Map(mapRefElem.current, {
        center: [parseFloat(currentLicense.MAPS_COORDINATES_LATITUDE), parseFloat(currentLicense.MAPS_COORDINATES_LONGITUDE)],
        zoom: parseInt(currentLicense.MAPS_ZOOM),
      });
    }

    const initialCoord = parseCoordinateFromYMap();
    createPolyline(initialCoord);
  }, [ymaps]);

  return (
    <>
      {loading && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}

      <div className={styles.polylineEditor}>
        <dir className={styles.mapContainer}>
          {/* карта */}
          <div ref={mapRefElem} className={styles.map} style={{ width: '100vw', height: '100vh' }}></div>
          {/* карта */}

          <div className={styles.polylineEditorControlContainer}>
            <div className={styles.polylineEditorControlRow}>
              <h3 className={styles.title}>Редактор ломанной линии</h3>
              <BsArrowLeftCircle className={styles.buttonBack} onClick={() => setOpenPolylineEditor(false)} />
            </div>
            <div className={styles.polylineEditorControlRow}>
              <span className={styles.uploadedFileNameText}>
                {geoJsonFileName.length > 0 ? (
                  <>
                    Вы загрузили файл
                    <strong className={styles.uploadedFileNameTextActive} title={geoJsonFileName}>
                      {geoJsonFileName}
                    </strong>
                  </>
                ) : (
                  <>Загрузите файл</>
                )}
              </span>

              <div className={styles.controlBlock}>
                <BsPlusSquareDotted className={styles.addFile} onClick={setGeoJsonCoordinates} />
                {dataSentSuccessfully ? (
                  <HiCheck className={styles.checkControl} />
                ) : sendingData ? (
                  <LoadingInTableSave />
                ) : (
                  <AiTwotoneSave className={styles.saveCoord} onClick={getCoordinatesPolyline} />
                )}

                <input
                  className={styles.geoJsonFiles}
                  type="file"
                  name="geoJsonFiles"
                  ref={filesRef}
                  accept=".json"
                  onChange={(event) => addGeoJsonFile(event)}
                  disabled={sendingData}
                />
              </div>
            </div>
          </div>
        </dir>
      </div>
    </>
  );
}
