
import styles from './preload.module.scss';

export default function Preload(props) {
  const { width, height, marginBottom = 0, backgroundColor = '#2f2e41' } = props;

  return (
    <div
      className={styles.preload}
      style={{
        width: width,
        height: height,
        marginBottom: marginBottom,
        backgroundColor: backgroundColor,
      }}
    ></div>
  );
}
