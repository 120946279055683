import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { HiCheck } from 'react-icons/hi';

import { useKernel } from '../../context/ContextKernel.mjs';
import { UsersService } from '../../services/services.mjs';
import { LoadingInUserPageForm } from '../components.mjs';

import styles from './groupForm.module.scss';

export default function GroupForm(props) {
  const { setComponentsTypeId, setIsError, setErrorMessage, usersTree, initialStateUserTree, setUsersTree, setInitialStateUserTree } = props;

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });

  const [nameGroupInputValue, setNameGroupInputValue] = useState();
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const { kernel } = useKernel();

  const handleChangeInputValue = (event) => {
    setNameGroupInputValue(event.target.value);
  };

  const groupFormButtonBackClick = () => {
    setComponentsTypeId(0);
  };

  const onSubmit = async (data) => {
    setSendingData(true);

    try {
      const response = await UsersService.usersCreatingNewGroup(data);

      //запись в kernel и добавление в state
      const newUserGroup = kernel.addNewGroupInParametersUserTree(response); //обновил kernel
      setUsersTree([...usersTree, newUserGroup]);
      setInitialStateUserTree([...initialStateUserTree, newUserGroup]);

      setTimeout(() => {
        setSendingData(false);
        setDataSentSuccessfully(true);
      }, 800);

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setComponentsTypeId(0);
      }, 1500);
    } catch (error) {
      setSendingData(false);
      //сообщение об ошибке
      setIsError(true);
      const errorMessage = error.toString().split('Error: ')[1];
      setErrorMessage(errorMessage);
      console.warn('error: ', error);
    }
  };

  return (
    <div className={styles.groupForm}>
      <div className={styles.groupFormHeader}>
        <h2 className={styles.groupFormTitle}>Новая группа</h2>
        <BsArrowLeftCircle className={styles.groupFormButtonBack} onClick={groupFormButtonBackClick} />
      </div>
      <form className={styles.groupFormForm} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.container}>
          <div className={styles.inputWrap}>
            <input
              className={classNames(styles.input, errors.nameGroup && styles.inputError)}
              type="text"
              placeholder="Имя группы"
              onInput={handleChangeInputValue}
              value={nameGroupInputValue}
              {...register('nameGroup', {
                required: 'Поле обязательно к заполнению',
                minLength: {
                  value: 3,
                  message: 'Поле должно содержать минимум 3 символа',
                },
                maxLength: {
                  value: 255,
                  message: 'Поле может содержать максимум 255 символа',
                },
              })}
            />
            {errors?.nameGroup && <p className={styles.errorMessage}>{errors?.nameGroup?.message || 'Ошибка заполнения!'}</p>}
          </div>
        </div>
        <button
          className={
            sendingData || dataSentSuccessfully
              ? styles.buttonSending
              : isValid
                ? classNames(styles.button, styles.formValid)
                : classNames(styles.button, styles.formNoValid)
          }
          type="submit"
          disabled={!isValid}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Создать'}
        </button>
      </form>
    </div>
  );
}
