import { useEffect } from 'react';
import { BsBorderStyle, BsFillKeyFill } from 'react-icons/bs';
import { ImUsers } from 'react-icons/im';
import { MdAccountTree, MdEditNote, MdMenuBook, MdOutlineSettings } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { GoTasklist } from 'react-icons/go';
import { RiAlarmWarningFill } from 'react-icons/ri';

import { flagsParser } from '../../utils/flags-parser/flagsParser.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { useUserTokenDecode } from '../../hooks/hooks.mjs';

import styles from './sectionSelection.module.scss';

export default function SectionSelection() {
  const { kernel, currentLicense } = useKernel();

  const {
    hasAccessUsersModule = undefined,
    hasAccessEventLogsModule = undefined,
    hasAccessLicensesModule = undefined,
    hasAccessCalculationServerModule = undefined,
    hasAccessTaskServerModule = undefined,
    hasAccessSetpointsModule = undefined,
    hasAccessAlarmServerModule = undefined,
  } = currentLicense;

  const { flags } = useUserTokenDecode();
  const {
    hasAccessRightsUsersModule,
    hasAccessRightsEventLogsModule,
    hasAccessRightsLicensesModule,
    hasAccessRightsCalculationServerModule,
    hasAccessRightsTaskServerModule,
    hasAccessRightsSetpointsModule,
    hasAccessRightsAlarmServerModule,
  } = flagsParser(flags);

  useEffect(() => {
    kernel.timerRefreshToken(); //запуск обновления токена
    return () => {};
  }, []);

  const setLinkOnCurrentPageOrConfigurationSelectionPage = (linkOnCurrentPage = '/configuration-selection') => {
    const id = JSON.parse(localStorage.getItem('activeIdConfig'))?.id;
    return id ? linkOnCurrentPage : '/configuration-selection';
  };

  return (
    <div className={styles.sectionSelection}>
      <div className={styles.wrap}>
        <div className={styles.containerText}>
          <h1 className={styles.title}>Выберите раздел</h1>
        </div>
        <div className={styles.sectionSelectionWrap}>
          <Link to={setLinkOnCurrentPageOrConfigurationSelectionPage('/configuration-selection')}>
            <div className={styles.sectionSelectionItem}>
              <div className={styles.imgContainer}>
                <MdEditNote className={styles.icon} />
              </div>
              <p className={styles.sectionSelectionItemText}>Конфигурация</p>
            </div>
          </Link>

          {hasAccessUsersModule === 'true' && hasAccessRightsUsersModule === true && (
            <Link to="/users">
              <div className={styles.sectionSelectionItem}>
                <div className={styles.imgContainer}>
                  <ImUsers className={styles.icon} />
                </div>
                <p className={styles.sectionSelectionItemText}>Пользователи</p>
              </div>
            </Link>
          )}

          {hasAccessEventLogsModule === 'true' && hasAccessRightsEventLogsModule === true && (
            <Link to="/action-logs">
              <div className={styles.sectionSelectionItem}>
                <div className={styles.imgContainer}>
                  <MdMenuBook className={styles.icon} />
                </div>
                <p className={styles.sectionSelectionItemText}>Журналы</p>
              </div>
            </Link>
          )}

          {hasAccessLicensesModule === 'true' && hasAccessRightsLicensesModule === true && (
            <Link to="/licenses">
              <div className={styles.sectionSelectionItem}>
                <div className={styles.imgContainer}>
                  <BsFillKeyFill className={styles.icon} />
                </div>
                <p className={styles.sectionSelectionItemText}>Лицензии</p>
              </div>
            </Link>
          )}

          {hasAccessCalculationServerModule === 'true' && hasAccessRightsCalculationServerModule === true && (
            <Link to={setLinkOnCurrentPageOrConfigurationSelectionPage('/settlement-server')}>
              <div className={styles.sectionSelectionItem}>
                <div className={styles.imgContainer}>
                  <MdAccountTree className={styles.icon} />
                </div>
                <p className={styles.sectionSelectionItemText}>Сервер расчетов</p>
              </div>
            </Link>
          )}

          {hasAccessTaskServerModule === 'true' && hasAccessRightsTaskServerModule === true && (
            <Link to={setLinkOnCurrentPageOrConfigurationSelectionPage('/task-server')}>
              <div className={styles.sectionSelectionItem}>
                <div className={styles.imgContainer}>
                  <GoTasklist className={styles.icon} />
                </div>
                <p className={styles.sectionSelectionItemText}>Сервер задач</p>
              </div>
            </Link>
          )}

          {hasAccessSetpointsModule === 'true' && hasAccessRightsSetpointsModule === true && (
            <Link to={setLinkOnCurrentPageOrConfigurationSelectionPage('/setpoints')}>
              <div className={styles.sectionSelectionItem}>
                <div className={styles.imgContainer}>
                  <BsBorderStyle className={styles.icon} />
                </div>
                <p className={styles.sectionSelectionItemText}>Уставки</p>
              </div>
            </Link>
          )}

          {hasAccessAlarmServerModule === 'true' && hasAccessRightsAlarmServerModule === true && (
            <Link to={setLinkOnCurrentPageOrConfigurationSelectionPage('/alarm')}>
              <div className={styles.sectionSelectionItem}>
                <div className={styles.imgContainer}>
                  <RiAlarmWarningFill className={styles.icon} />
                </div>
                <p className={styles.sectionSelectionItemText}>Тревоги</p>
              </div>
            </Link>
          )}

          <Link to="/settings">
            <div className={styles.sectionSelectionItem}>
              <div className={styles.imgContainer}>
                <MdOutlineSettings className={styles.icon} />
              </div>
              <p className={styles.sectionSelectionItemText}>Настройки</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
