import { memo } from 'react';

import { useKernel } from '../../context/ContextKernel.mjs';
import { TableRow } from '../components.mjs';
import { VirtualTableRow } from '../../hoc/hoc.mjs';

import styles from './property.module.scss';

export default memo(function Property(props) {
  const { setComponentsTypeId, stateElementConfigurationId, setIsError, setErrorMessage, setProperty, buildConfigurationTree } = props;

  const { kernel } = useKernel();
  const selectedParameter = kernel.getParameterById(stateElementConfigurationId);

  return (
    <div className={styles.property}>
      <div className={styles.propertyWrapTable}>
        <table className={styles.table}>
          <thead className={styles.tableThead}>
            <tr className={styles.tableTr}>
              <th className={styles.tableTh}>Свойства</th>
              <th className={styles.tableTh}>Значения</th>
              <th className={styles.tableTh}>Дата</th>
              <th className={styles.tableTh}></th>
            </tr>
          </thead>
          <tbody className={styles.tableTbody}>
            {selectedParameter?.properties &&
              selectedParameter.properties.map((item) => {
                return item.canViewParameter ? (
                  <TableRow
                    id={item.id}
                    key={item.id}
                    canEdit={item.canEdit}
                    isWritable={item.isWritable}
                    isDeleted={item.isDeleted}
                    description={item.description}
                    systemType={item.systemType}
                    propertyValueTimestamp={item.propertyValueTimestamp}
                    setComponentsTypeId={setComponentsTypeId}
                    propertyValueRoundUp={item.propertyValueRoundUp}
                    setIsError={setIsError}
                    setErrorMessage={setErrorMessage}
                    setProperty={setProperty}
                    buildConfigurationTree={buildConfigurationTree}
                  />
                ) : (
                  <></>
                );
              })}

            <VirtualTableRow
              stateElementConfigurationId={stateElementConfigurationId}
              selectedParameter={selectedParameter}
              setIsError={setIsError}
              setErrorMessage={setErrorMessage}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
});
