import { Struct } from '../Struct.mjs'
import { StringStruct } from '../StringStruct.mjs'

class UserStruct extends Struct {

    constructor(id = 0, groupId = 0, name = new StringStruct('')) {
        super()
        this.id = id
        this.groupId = groupId
        this.name = name
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        this.id = dataView.getUint32(endOffset, isLittleEndian)
        endOffset += 4
        this.groupId = dataView.getUint32(endOffset, isLittleEndian)
        endOffset += 4
        endOffset = this.name.readFrom(dataView, endOffset, isLittleEndian)
        return endOffset
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        dataView.setUint32(endOffset, this.id, isLittleEndian)
        endOffset += 4
        dataView.setUint32(endOffset, this.groupId, isLittleEndian)
        endOffset += 4
        endOffset = this.name.writeTo(dataView, endOffset, isLittleEndian)
        return endOffset
    }

    getCurrentSize() {
        return 0
            + 4 // id
            + 4 // groupId
            + this.name.getCurrentSize() // name
    }

    clone() {
        let clone = new UserStruct(
            this.id,
            this.groupId,
            this.name.clone(),
        )
        clone.setParserOptions(this.getParserOptions())
        return clone
    }
}

export {
    
    UserStruct
}
