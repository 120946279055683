import { AddNewLicenses, EditingLicenses, LicenseAdditionStatus, SelectActionReplaceLicense, SetLicensesName } from '../components/components.mjs';

export function ComponentLicenses(props) {
  const { setComponentsTypeId, componentsTypeId, setIsError, setErrorMessage, drag, setDrag, licensesTree, setLicensesTree, idTreeElement } = props;

  const componentsByLicenses = {};

  const ADD_NEW_LICENSES = 0;
  const SET_LICENSES_NAME = 1;
  const STATUS_LICENSES = 2;
  const EDITING_LICENSES = 3;
  const SELECT_ACTION_REPLACE_LICENSE = 4;

  componentsByLicenses[ADD_NEW_LICENSES] = (
    <AddNewLicenses
      setComponentsTypeId={setComponentsTypeId}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      drag={drag}
      setDrag={setDrag}
    />
  );
  componentsByLicenses[SET_LICENSES_NAME] = (
    <SetLicensesName
      setComponentsTypeId={setComponentsTypeId}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      licensesTree={licensesTree}
      setLicensesTree={setLicensesTree}
    />
  );
  componentsByLicenses[STATUS_LICENSES] = (
    <LicenseAdditionStatus
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      setLicensesTree={setLicensesTree}
      setComponentsTypeId={setComponentsTypeId}
    />
  );
  componentsByLicenses[EDITING_LICENSES] = (
    <EditingLicenses
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      licensesTree={licensesTree}
      setLicensesTree={setLicensesTree}
      setComponentsTypeId={setComponentsTypeId}
      idTreeElement={idTreeElement}
    />
  );
  componentsByLicenses[SELECT_ACTION_REPLACE_LICENSE] = (
    <SelectActionReplaceLicense
      setComponentsTypeId={setComponentsTypeId}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      licensesTree={licensesTree}
      setLicensesTree={setLicensesTree}
    />
  );

  let components;
  const setComponent = () => {
    if (componentsTypeId in componentsByLicenses) {
      components = componentsByLicenses[componentsTypeId];
    } else {
      components = <p>Произошла ошибка при выборе действия! Обновите страницу!</p>;
    }
  };
  setComponent();

  return <>{components}</>;
}
