import {
  RangeEditor,
  TableRowBoardAddressesModule,
  TableRowBoardAddressesModuleRs,
  TableRowConnectionTypeModuleTcp,
  TableRowDeadZone,
  TableRowDeviceAddressModuleRs,
  TableRowInterfaceSpeedModuleRs,
  TableRowPipelineNumberModuleRs,
  TableRowReportingHourModuleRs,
  TableRowSensorTypeModuleTcp,
  TableRowWorkingModeModuleAiDi,
} from '../components/components.mjs';
import { useKernel } from '../context/ContextKernel.mjs';
import Utils from '../utils/Utils.mjs';

export function VirtualTableRow(props) {
  const { stateElementConfigurationId, selectedParameter, setIsError, setErrorMessage } = props;
  const { kernel } = useKernel();

  const selectedParameterBlockTypeId = selectedParameter?.blockType?.id;
  const selectedParameterBlockTypeIdParent = selectedParameter?.parent?.blockType?.id;
  const selectedParameterBlockTypeIdParentParent = selectedParameter?.parent?.parent?.blockType?.id;
  const renderTableRowDeadZone = selectedParameter?.properties.filter((property) => property.systemTypeId === 75).length > 0 ?? false;
  const renderTableRowRangeEditor = Utils.getRangeProperties(selectedParameter?.properties).length === 8 ?? false;

  const renderVirtualComponents = [
    {
      items: (
        <TableRowWorkingModeModuleAiDi
          stateElementConfigurationId={stateElementConfigurationId}
          selectedParameter={selectedParameter}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
        />
      ),
      renderCondition: selectedParameter?.parent?.blockType?.blockTypeStruct?.id === 41,
    },
    {
      items: (
        <TableRowBoardAddressesModule
          stateElementConfigurationId={stateElementConfigurationId}
          selectedParameter={selectedParameter}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
        />
      ),
      renderCondition: selectedParameterBlockTypeId === 41 || selectedParameterBlockTypeId === 42 || selectedParameterBlockTypeId === 43,
    },
    {
      items: (
        <TableRowSensorTypeModuleTcp
          stateElementConfigurationId={stateElementConfigurationId}
          selectedParameter={selectedParameter}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
        />
      ),
      renderCondition: selectedParameter?.parent?.blockType?.blockTypeStruct?.id === 42,
    },
    {
      items: (
        <TableRowConnectionTypeModuleTcp
          stateElementConfigurationId={stateElementConfigurationId}
          selectedParameter={selectedParameter}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
        />
      ),
      renderCondition: selectedParameter?.parent?.blockType?.blockTypeStruct?.id === 42,
    },
    {
      items: (
        <TableRowBoardAddressesModuleRs
          stateElementConfigurationId={stateElementConfigurationId}
          selectedParameter={selectedParameter}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
        />
      ),
      renderCondition: selectedParameter?.blockType?.id === 44,
    },
    {
      items: (
        <TableRowDeviceAddressModuleRs
          stateElementConfigurationId={stateElementConfigurationId}
          selectedParameter={selectedParameter}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
        />
      ),
      renderCondition:
        [45, 46, 47, 48, 32, 31, 49, 33, 36, 50, 51, 34, 52].includes(selectedParameterBlockTypeId) &&
        stateElementConfigurationId &&
        stateElementConfigurationId !== null &&
        kernel.getParameterById(stateElementConfigurationId).path.find((parameter) => parameter.blockType?.id === 40) !== undefined,
    },
    {
      items: (
        <TableRowInterfaceSpeedModuleRs
          stateElementConfigurationId={stateElementConfigurationId}
          selectedParameter={selectedParameter}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
        />
      ),
      renderCondition:
        [45, 46, 47, 48, 32, 31, 49, 33, 36, 50, 51, 34, 52].includes(selectedParameterBlockTypeId) &&
        stateElementConfigurationId &&
        stateElementConfigurationId !== null &&
        kernel.getParameterById(stateElementConfigurationId).path.find((parameter) => parameter.blockType?.id === 40) !== undefined,
    },
    {
      items: (
        <TableRowPipelineNumberModuleRs
          stateElementConfigurationId={stateElementConfigurationId}
          selectedParameter={selectedParameter}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
        />
      ),
      renderCondition:
        [32, 31, 49, 33, 36, 50, 51, 34, 52].includes(selectedParameterBlockTypeId) &&
        stateElementConfigurationId &&
        stateElementConfigurationId !== null &&
        kernel.getParameterById(stateElementConfigurationId).path.find((parameter) => parameter.blockType?.id === 40) !== undefined,
    },
    {
      items: (
        <TableRowReportingHourModuleRs
          stateElementConfigurationId={stateElementConfigurationId}
          selectedParameter={selectedParameter}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
        />
      ),
      renderCondition:
        [32, 31, 49, 33, 36, 50, 51, 34, 52].includes(selectedParameterBlockTypeId) &&
        stateElementConfigurationId &&
        stateElementConfigurationId !== null &&
        kernel.getParameterById(stateElementConfigurationId).path.find((parameter) => parameter.blockType?.id === 40) !== undefined,
    },
    {
      items: (
        <TableRowDeadZone
          stateElementConfigurationId={stateElementConfigurationId}
          selectedParameter={selectedParameter}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
        />
      ),
      renderCondition:
        ([40].includes(selectedParameterBlockTypeIdParent) || [40].includes(selectedParameterBlockTypeIdParentParent)) &&
        renderTableRowDeadZone &&
        stateElementConfigurationId &&
        stateElementConfigurationId !== null &&
        kernel.getParameterById(stateElementConfigurationId).path.find((parameter) => parameter.blockType?.id === 40) !== undefined,
    },
    {
      items: (
        <RangeEditor
          stateElementConfigurationId={stateElementConfigurationId}
          selectedParameter={selectedParameter}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
        />
      ),
      renderCondition:
        renderTableRowRangeEditor &&
        stateElementConfigurationId &&
        stateElementConfigurationId !== null &&
        kernel.getParameterById(stateElementConfigurationId).path.find((parameter) => parameter.blockType?.id === 40) !== undefined,
    },
  ];

  return (
    <>
      {renderVirtualComponents.map((row) => {
        if (row.renderCondition) {
          return row.items;
        } else {
          return <></>;
        }
      })}
    </>
  );
}
