export const sampleTypes = [
  { value: 1, text: 'AI' },
  { value: 2, text: 'DI' },
  { value: 3, text: 'Дата и время на устройстве' },
  { value: 4, text: 'На связи/не на связи' },
  { value: 5, text: 'Последнее время выхода прибора на связь' },
  { value: 6, text: 'Уровень сигнала' },
  { value: 7, text: 'Статус объекта' },
  { value: 8, text: 'Температура' },
  { value: 9, text: 'Напряжение' },
];