export const sortOption = (optionsList, sortKey = "label") => {
  return optionsList.sort((a, b) => {
    if (a[sortKey] > b[sortKey]) {
      return 1;
    }
    if (a[sortKey] < b[sortKey]) {
      return -1;
    }
    return 0;
  });

};