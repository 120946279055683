import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiCheck } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

import { useKernel } from '../../context/ContextKernel.mjs';
import { CreateNewObjectService } from '../../services/services.mjs';
import { LoadingInUserPageForm } from '../components.mjs';
import Utils from '../../utils/Utils.mjs';

import styles from './creatingNewModule.module.scss';

export default function CreatingNewModule(props) {
  const { setComponentsTypeId, selectedParameter, setIsError, setErrorMessage, setConfiguration } = props;

  const location = useLocation();
  const [moduleType, setModuleType] = useState(41);
  const [moduleName, setModuleName] = useState('');
  const [boardAddress, setBoardAddress] = useState('01');
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const { kernel } = useKernel();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    setSendingData(true);
    try {
      await CreateNewObjectService.createNewModule(
        kernel.getActiveConfiguration().id,
        data.moduleType, //элемент из списка
        selectedParameter.id.toString(), //id параметра в котором создают модуль
        data.moduleName, // имя модуля
        data,
      );

      kernel.unloadConfiguration();
      const { tree } = await kernel.buildTree(location);

      setSendingData(false);
      setDataSentSuccessfully(true);

      //строим дерево
      setConfiguration(tree);

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setComponentsTypeId(0);
      }, 1200);
    } catch (error) {
      setIsError(true);
      setSendingData(false);
      const errorMessage = error.toString().split('Error: ')[1];
      setErrorMessage(errorMessage);
      console.warn('error: ', error);
    }
  };

  return (
    <div className={styles.creatingNewModule}>
      <div className={styles.creatingNewModuleTitle}>
        <span className={styles.creatingNewModuleTitleText}>Добавление модуля в объект:</span>
        <span className={classNames(styles.creatingNewModuleTitleText, styles.creatingNewModuleTitleTextAccent)}>
          {selectedParameter?.displayName}
        </span>
      </div>
      <div className={styles.creatingNewModuleFormWrap}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.creatingNewModuleForm}>
          <div className={styles.creatingNewModuleFormContainer}>
            <div className={styles.creatingNewModuleFormItem}>
              <span className={styles.creatingNewModuleFormItemText}>Выберите тип конфигурации:</span>
              <select
                value={moduleType}
                onInput={(event) => setModuleType(event.target.value)}
                className={styles.creatingNewModuleFormItemSelect}
                {...register('moduleType')}
              >
                <option value={41} className={styles.creatingNewModuleFormItemSelectOption}>
                  Модуль AI/DI
                </option>
                <option value={42} className={styles.creatingNewModuleFormItemSelectOption}>
                  Модуль датчиков ТСП
                </option>
                <option value={43} className={styles.creatingNewModuleFormItemSelectOption}>
                  Модуль АСКИТ
                </option>
                <option value={44} className={styles.creatingNewModuleFormItemSelectOption}>
                  Модуль RS
                </option>
              </select>
            </div>
            <div className={styles.creatingNewModuleFormItem}>
              <span className={styles.creatingNewModuleFormItemText}>Укажите наименование модуля:</span>
              <div className={styles.creatingNewModuleFormItemInputWrap}>
                <input
                  type="text"
                  className={styles.creatingNewModuleFormItemInput}
                  placeholder="Укажите наименование модуля"
                  value={moduleName}
                  onInput={(event) => setModuleName(event.target.value)}
                  {...register('moduleName', {
                    required: 'Поле обязательно к заполнению',
                    minLength: {
                      value: 3,
                      message: 'Поле должно содержать минимум 3 символа',
                    },
                    maxLength: {
                      value: 64,
                      message: 'Поле может содержать максимум 64 символа',
                    },
                  })}
                />
                {errors?.moduleName && <p className={styles.errorMessage}>{errors?.moduleName?.message || 'Ошибка заполнения!'}</p>}
              </div>
            </div>
            <div className={styles.creatingNewModuleFormItem}>
              <span className={styles.creatingNewModuleFormItemText}>Выберите адрес платы:</span>
              <select
                value={boardAddress}
                onInput={(event) => setBoardAddress(event.target.value)}
                className={styles.creatingNewModuleFormItemSelect}
                {...register('boardAddress')}
              >
                {Utils.boardAddressOptionValue.map((item, index) => {
                  return (
                    <option key={item.valueOption} value={item.valueOption} className={styles.creatingNewModuleFormItemSelectOption}>
                      {item.textOption}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <button
            className={
              sendingData || dataSentSuccessfully
                ? styles.creatingNewModuleFormSubmitSending
                : isValid
                  ? classNames(styles.creatingNewModuleFormSubmit, styles.formValid)
                  : classNames(styles.creatingNewModuleFormSubmit, styles.formNoValid)
            }
            disabled={!isValid}
          >
            {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Создать'}
          </button>
        </form>
      </div>
    </div>
  );
}
