import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { ConfirmationPopUp, LoadingInUserPageForm } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';
import { CreateNewObjectService } from '../../services/services.mjs';
import { ComponentTableRow } from '../../hoc/hoc.mjs';

import { createPropertyValue } from './model/createPropertyValue.mjs';
import { testIncludeInEditorSettings, testIncludesPropertyName } from './model/testData.mjs';
import styles from './createNewPropertyForm.module.scss';

const TYPE_LINK1 = 15;
const TYPE_INVALID_TYPE = 16;

export default function CreateNewPropertyForm(props) {
  const { setComponentsTypeId, setConfiguration, setIsError, setErrorMessage, selectedParameter } = props;

  const location = useLocation();
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [state, setState] = useState({ systemTypeId: 113, value: 0 });
  const [popupState, setPopupState] = useState({ isVisible: false, message: '', canSave: false, sending: false });
  const { activeConfigurationId } = useActiveConfigurationId();
  const { kernel } = useKernel();

  const systemType = useRef(kernel.getSystemTypeById(113));
  const systemTypesListSorted = kernel.getSystemTypes().sort((a, b) => {
    if (a.description > b.description) return 1;

    if (a.description < b.description) return -1;

    return 0;
  });

  const {
    register,
    unregister,
    clearErrors,
    setValue,
    getValues,
    trigger,
    setFocus,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });

  const handleChange = ({ target }) => {
    const name = target.name;
    const value = target.value;
    setValue(name, value);

    setState({
      ...state,
      [name]: value,
    });
  };

  const createNewProperty = async (systemTypeId, value) => {
    setSendingData(true);
    setPopupState({
      ...popupState,
      sending: true,
    });

    try {
      await CreateNewObjectService.createNewProperty(activeConfigurationId, {
        parentId: selectedParameter.id.toString(),
        systemTypeId,
        value,
      });

      //выгружаем конфигурацию
      kernel.unloadConfiguration();
      const { tree } = await kernel.buildTree(location);

      setSendingData(false);
      setDataSentSuccessfully(true);

      //строим дерево
      setConfiguration(tree);

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setComponentsTypeId(0);
      }, 1200);

      setPopupState({
        ...popupState,
        sending: false,
        isVisible: false,
      });
    } catch (error) {
      setIsError(true);
      setSendingData(false);
      const errorMessage = error.toString().split('Error: ')[1];
      setErrorMessage(errorMessage);
      console.warn('error: ', error);
    }
  };

  const onSubmit = async (data) => {
    const value = data.value;
    const systemTypeId = state.systemTypeId;

    setState({ ...state, value: data.value }); // необходим в том случае если свойство скрытое

    let test = testIncludesPropertyName(systemTypeId, kernel, selectedParameter);

    if (test) {
      setIsError(true);
      setErrorMessage('Вы создаете свойство, которое уже существует у данного элемента!');
      return;
    }

    const editorSettings = kernel.editorSettings;
    const editorSettingsListBySystemTypeId = editorSettings[systemTypeId];
    const systemTypeParent = selectedParameter.typeNode.systemTypeId; // системный тип параметра в который добавляем свойство
    const typeBlockParent = kernel.getParameterById(selectedParameter.id).parent.typeNode.blockType?.id; // тип блока родителя параметра, в который добавляем свойство
    test = testIncludeInEditorSettings(editorSettingsListBySystemTypeId, systemTypeParent, typeBlockParent);

    if (test.status) {
      // показать popUp
      setPopupState({
        ...popupState,
        isVisible: true,
        message: test.message,
      });
      return;
    }
    await createNewProperty(systemTypeId, value);
  };

  useEffect(() => {
    systemType.current = kernel.getSystemTypeById(state.systemTypeId);
    return () => {};
  }, [state.systemTypeId]);

  return (
    <div className={styles.container}>
      {popupState.isVisible && (
        <ConfirmationPopUp
          actionConfirmation={() => createNewProperty(state.systemTypeId, state.value)}
          closePopup={() => {
            setPopupState({
              ...popupState,
              isVisible: false,
            });
          }}
          message={popupState.message}
          loading={popupState.sending}
        />
      )}
      <div className={styles.title}>
        <span className={styles.titleText}>Добавление свойства в параметр:</span>
        <span className={classNames(styles.titleText, styles.titleTextAccent)}>{selectedParameter?.displayName}</span>
      </div>
      <div className={styles.wrap}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.formContainer}>
            <div className={styles.formItem}>
              <span className={styles.formItemText}>Выберите системный тип:</span>
              <div className={styles.formItemFieldWrap}>
                <select
                  value={state.systemTypeId}
                  onInput={handleChange}
                  className={classNames(styles.formItemSelect, sendingData && styles.sending)}
                  disabled={sendingData}
                  {...register('systemTypeId')}
                >
                  {systemTypesListSorted.map((systemType) => {
                    return (
                      systemType.type !== TYPE_LINK1 &&
                      systemType.type !== TYPE_INVALID_TYPE &&
                      systemType.id !== 1 && (
                        <option key={systemType.id} value={systemType.id} className={styles.formItemSelectOption}>
                          {systemType.description}
                        </option>
                      )
                    );
                  })}
                </select>
              </div>
            </div>

            <div className={styles.formItem}>
              <span className={styles.formItemText}>Укажите начальное значение:</span>
              <div className={styles.formItemFieldWrap}>
                <ComponentTableRow
                  canEdit={false}
                  isWritable={true}
                  isDeleted={false}
                  systemType={systemType.current}
                  propertyValueRoundUp={createPropertyValue(state.systemTypeId, systemType.current.type)}
                  setHandleChangeComponentTableRow={() => {}}
                  register={register}
                  unregister={unregister}
                  errors={errors}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  getValues={getValues}
                  trigger={trigger}
                  saveProperty={onSubmit}
                  setFocus={setFocus}
                  sendingData={sendingData}
                  dataSentSuccessfully={dataSentSuccessfully}
                  setIsError={setIsError}
                  setErrorMessage={setErrorMessage}
                  coordObject=""
                />
              </div>
            </div>
          </div>
          <button
            className={
              sendingData || dataSentSuccessfully
                ? styles.formSubmitSending
                : isValid
                ? classNames(styles.formSubmit, styles.formValid)
                : classNames(styles.formSubmit, styles.formNoValid)
            }
            disabled={!isValid}
          >
            {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Создать'}
          </button>
        </form>
      </div>
    </div>
  );
}
