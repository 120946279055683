import { RulesListStruct } from '../quantum-lib/Arm/Parser/Configurations/RulesListStruct.mjs';

import { setWorkingMode } from './services.mjs';

export class RulesService {
  static async getRulesListStruct(configId) {
    return fetch(`${setWorkingMode()}/get-rules-list-struct/${configId}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const licensesListStruct = new RulesListStruct();

        licensesListStruct.readFrom(dataView, offset, isLittleEndian);
        return licensesListStruct;
      });
  }

  static async changeRules(data) {
    return fetch(`${setWorkingMode()}/change-rules`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const licensesListStruct = new RulesListStruct();

        licensesListStruct.readFrom(dataView, offset, isLittleEndian);
        return licensesListStruct;
      });
  }
}
