import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { HiCheck } from 'react-icons/hi';

import { CreateNewObjectService } from '../../../../services/services.mjs';
import { useActiveConfigurationId } from '../../../../hooks/hooks.mjs';
import { useKernel } from '../../../../context/ContextKernel.mjs';
import { InputText, LoadingInUserPageForm } from '../../../components.mjs';
import { createValidationSchemeByInputText } from '../../model/validationScheme.mjs';

import styles from './creatingCustomParametersForms.module.scss';

const TYPE_LINK1 = 15;
const TYPE_INVALID_TYPE = 16;

export function CreatingCustomParametersForms(props) {
  const { setComponentsTypeId, setConfiguration, setIsError, setErrorMessage, selectedParameter } = props;

  const location = useLocation();
  const { kernel } = useKernel();
  const [systemType, setSystemType] = useState(kernel.getSystemTypeById(113));
  const [sendingData, setSendingData] = useState(false);
  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [showTooltip, setShowTooltip] = useState({ nameTag: false, nameContainerSystemStructure: false });
  const { activeConfigurationId } = useActiveConfigurationId();
  const systemTypesListSorted = kernel.getSystemTypes().sort((a, b) => {
    if (a.description > b.description) return 1;

    if (a.description < b.description) return -1;

    return 0;
  });

  const {
    register,
    unregister,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const handleFocus = useCallback(
    ({ target }) => {
      const name = target.name;
      setShowTooltip({
        ...showTooltip,
        [name]: true,
      });
    },
    [showTooltip],
  );
  const handleBlur = useCallback(
    ({ target }) => {
      const name = target.name;
      setShowTooltip({
        ...showTooltip,
        [name]: false,
      });
    },
    [showTooltip],
  );

  const onSubmit = async (data) => {
    setSendingData(true);
    try {
      const parentId = selectedParameter.id.toString();
      data.parentId = parentId;

      await CreateNewObjectService.createNewCustomParameter(activeConfigurationId, data);

      kernel.unloadConfiguration();
      const { tree } = await kernel.buildTree(location);
      setSendingData(false);
      setDataSentSuccessfully(true);
      //строим дерево
      setConfiguration(tree);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setComponentsTypeId(0);
      }, 1200);
    } catch (error) {
      setIsError(true);
      setSendingData(false);
      const errorMessage = error.toString().split('Error: ')[1];
      setErrorMessage(errorMessage);
      console.warn('error: ', error);
    }
  };

  const handleChangeSystemType = ({ target }) => {
    const systemTypeStruct = kernel.getSystemTypeById(target.value);
    setSystemType(systemTypeStruct);
    setValue('systemTypeId', target.value);
  };

  useEffect(() => {
    setValue('nameContainerSystemStructure', systemType.description);
    setValue('nameTag', systemType.description);

    trigger(['nameContainerSystemStructure', 'nameTag']);

    return () => {};
  }, [systemType]);

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>
        <span className={styles.titleText}>Добавление нового параметра в :</span>
        <span className={classNames(styles.titleText, styles.titleTextAccent)}>{selectedParameter?.displayName}</span>
      </div>
      <div className={styles.formWrap}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.formItem}>
            <span className={styles.formItemText}>Выберите системный тип контейнера:</span>
            <div className={styles.itemContainer}>
              <select
                value={systemType.id}
                onInput={handleChangeSystemType}
                className={classNames(styles.select, sendingData && styles.sending)}
                disabled={sendingData}
                {...register('systemTypeId')}
              >
                {systemTypesListSorted.map((systemType, index) => {
                  return (
                    systemType.type !== TYPE_LINK1 &&
                    systemType.type !== TYPE_INVALID_TYPE &&
                    systemType.id !== 1 && (
                      <option key={systemType.id} value={systemType.id} className={styles.selectOption}>
                        {systemType.description}
                      </option>
                    )
                  );
                })}
              </select>
            </div>
          </div>
          <div className={styles.formItem}>
            <span className={styles.formItemText}>Наименование контейнера:</span>
            <div className={styles.itemContainer}>
              <InputText
                value={systemType.description}
                setValue={setValue}
                placeholder="Наименование контейнера"
                canEdit={false}
                isWritable={true}
                isDeleted={false}
                register={register}
                unregister={unregister}
                name="nameContainerSystemStructure"
                handleFocus={handleFocus}
                showTooltip={showTooltip.nameContainerSystemStructure}
                errors={errors}
                validationScheme={createValidationSchemeByInputText(handleBlur, 255)}
                setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
                sendingData={sendingData}
              />
            </div>
          </div>
          <div className={styles.formItem}>
            <span className={styles.formItemText}>Наименование параметра:</span>
            <div className={styles.itemContainer}>
              <InputText
                value={systemType.description}
                setValue={setValue}
                placeholder="Наименование параметра"
                canEdit={false}
                isWritable={true}
                isDeleted={false}
                register={register}
                unregister={unregister}
                name="nameTag"
                handleFocus={handleFocus}
                showTooltip={showTooltip.nameTag}
                errors={errors}
                validationScheme={createValidationSchemeByInputText(handleBlur)}
                setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
                sendingData={sendingData}
              />
            </div>
          </div>

          <button
            className={
              sendingData || dataSentSuccessfully
                ? styles.formSubmitSending
                : isValid
                ? classNames(styles.formSubmit, styles.formValid)
                : classNames(styles.formSubmit, styles.formNoValid)
            }
            disabled={!isValid}
          >
            {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Создать'}
          </button>
        </form>
      </div>
    </div>
  );
}
