import { LicensesListStruct } from '../quantum-lib/Arm/Parser/Licenses/LicensesListStruct.mjs';
import { LicensesTypesListStruct } from '../quantum-lib/Arm/Parser/Licenses/LicensesTypesListStruct.mjs';

import { setWorkingMode } from './services.mjs';

export class LicensesService {

  static async licensesListStruct() {
    return fetch(`${setWorkingMode()}/licenses-list`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const licensesListStruct = new LicensesListStruct();

        licensesListStruct.readFrom(dataView, offset, isLittleEndian);
        return licensesListStruct;
      });
  }

  static async licensesTypesListStruct() {
    return fetch(`${setWorkingMode()}/licenses-types-list`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const licensesTypesListStruct = new LicensesTypesListStruct();

        licensesTypesListStruct.readFrom(dataView, offset, isLittleEndian);
        return licensesTypesListStruct;
      });
  }

  static async getCurrentLicense() {
    return fetch(`${setWorkingMode()}/get-current-license`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((response) => {
     
      return response.json();
    });
  }

  static async checkLicensesName(name, uuid) {
    const response = await fetch(`${setWorkingMode()}/check-licenses-name`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ name, uuid }),
    });
    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }

  static async checkLicensesUuid(uuid) {
    const response = await fetch(`${setWorkingMode()}/check-licenses-uuid`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ uuid }),
    });
    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }

  static async addNewLicenses(licenses) {
    const response = await fetch(`${setWorkingMode()}/add-new-licenses`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ ...licenses }),
    });
    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }

  static async updatingExistingLicense(licenses) {
    const response = await fetch(`${setWorkingMode()}/updating-existing-license`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(licenses),
    });
    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }
}