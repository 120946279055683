import Utils from "../../../../../utils/Utils.mjs";

export const alarmConfigId = (value, kernel) => {
  const isInteger = Utils.testIsInteger(value);
  if (!isInteger) {
    return 'Допустимый формат ввода - целые числа!';
  }

  const alarm = kernel.getAlarmById(value);
  if (!alarm) {
    return 'Тревоги с таким id не существует';
  }

  return true;
};

export const validateListSourcesAlarm = (values, kernel) => {
  const valuesList = values.toString().split(';');
  let errorMessage = '';

  valuesList.every((value) => {
    const isInteger = Utils.testIsInteger(value);
    if (!isInteger) {
      errorMessage = 'Допустимый формат ввода - целые, положительные числа!';
      return false;
    }

    const parameterStruct = kernel.getParameterById(value);
    if (!parameterStruct) {
      errorMessage = `Такого параметра с id ${value} не существует!`;
      return false;
    }

    return true;
  });

  return errorMessage.length > 0 ? errorMessage : true;
};

export const validateParameterId = (value, kernel) => {
  const isInteger = Utils.testIsInteger(value);
  if (!isInteger) {
    return 'Допустимый формат ввода - целые числа!';
  }

  const parameterStruct = kernel.getParameterById(value);
  if (!parameterStruct) {
    return 'Такого параметра не существует!';
  }

  const parameterIsDeleted = parameterStruct.isDeleted;
  if (parameterIsDeleted) {
    return 'Для удаленных объектов нельзя создавать тревоги!';
  }

  return true;
};
