import classNames from 'classnames';
import { useRef } from 'react';
import { HiOutlinePlusSm } from 'react-icons/hi';

import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './addNewLicenses.module.scss';

export default function AddNewLicenses(props) {
  const { setComponentsTypeId, setIsError, setErrorMessage, drag, setDrag } = props;

  const ref = useRef(null);
  const { kernel } = useKernel();

  const addFileTrigger = (event) => {
    event.preventDefault();
    const inputAddNewLicensesFile = ref;
    inputAddNewLicensesFile.current.click();
  };

  const getFileSchema = (file) =>
    file && {
      file: file,
      type: file.type,
      name: file.name,
    };

  const dragStartHandler = (event) => {
    event.preventDefault();
    setDrag(true);
  };

  const dragLeaveHandler = (event) => {
    event.preventDefault();
    setDrag(false);
  };

  const dataChecking = (event, files, file) => {
    if (files.length === 1) {
      //проверка на количество файлов
      const fileName = file.name.split('.');
      const fileExtension = fileName[fileName.length - 1];

      if (fileExtension === 'lic') {
        //проверка на тип файла
        const reader = new FileReader();

        reader.onload = ({ target: { result } }) => {
          const fileDataArr = result.split(/\r?\n/);
          const [uuid, licType, dataBlockType, publicContent, privateContent, closeSign, name] = fileDataArr;

          kernel.setLicensesFile({
            uuid,
            licType,
            dataBlockType,
            publicContent,
            privateContent,
            closeSign,
            name,
          });
          setComponentsTypeId(1);
        };

        reader.readAsText(files[0]);
      } else {
        event.target.value = null;
        setIsError(true);
        setDrag(false);
        setErrorMessage('Выбранный вами файл содержит невалидный формат!');
      }
    } else {
      event.target.value = null;
      setIsError(true);
      setDrag(false);
      setErrorMessage('Вы можете прикрепить только 1 файл лицензии за раз!');
    }
  };

  const onDropHandler = (event) => {
    event.preventDefault();
    const files = [...event.dataTransfer.files];
    const file = getFileSchema(files[0]);

    setDrag(false);
    dataChecking(event, files, file);
  };

  const addFile = (event) => {
    const { files } = event.target;
    const file = getFileSchema(files.item(0));

    dataChecking(event, files, file);
  };

  return (
    <div className={styles.addNewLicenses}>
      <div
        className={classNames(styles.addNewLicensesOnDragContainer, drag && styles.addNewLicensesOnDrag)}
        onDragStart={(event) => dragStartHandler(event)}
        onDragLeave={(event) => dragLeaveHandler(event)}
        onDragOver={(event) => dragStartHandler(event)}
        onDrop={(event) => onDropHandler(event)}
      >
        <div className={styles.containerButton} title="Добавить новую лицензию">
          <HiOutlinePlusSm className={styles.button} onClick={(event) => addFileTrigger(event)} />
          <input
            className={styles.inputAddNewLicensesFile}
            type="file"
            name="newLicensesFile"
            ref={ref}
            accept=".lic"
            onChange={(event) => addFile(event)}
          />
        </div>
      </div>
    </div>
  );
}
