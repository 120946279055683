import { Struct } from './Struct.mjs'

class StringStruct extends Struct {

    constructor(value = '') {
        super()
        this.value = value === null || typeof value === 'undefined' ? '' : `${value}`
        this.endOfString = 0
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        for (; endOffset < dataView.byteLength; endOffset ++) {
            let byte = dataView.getUint8(endOffset)
            if (byte === this.endOfString) {
                break
            }
        }
        let textDecoder = new TextDecoder() // utf-8 by default
        let arrayView = new Uint8Array(dataView.buffer, dataView.byteOffset + startOffset, endOffset - startOffset)
        this.value = textDecoder.decode(arrayView)
        endOffset ++
        return endOffset
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        let srcUint8Array = this.getEncodedBytes()
        let dstUint8ArrayView = new Uint8Array(dataView.buffer, dataView.byteOffset)
        dstUint8ArrayView.set(srcUint8Array, startOffset)
        let endOffset = startOffset + srcUint8Array.length
        dstUint8ArrayView[endOffset] = this.endOfString
        endOffset ++
        return endOffset
    }

    getCurrentSize() {
        return 0
            + this.getEncodedBytes().length // value
            + 1 // endOfString
    }

    getEncodedBytes() {
        let textEncoder = new TextEncoder() // utf-8 by default
        let srcUint8Array = textEncoder.encode(this.value)
        return srcUint8Array
    }

    clone() {
        let clone = new StringStruct(`${this.value}`)
        clone.setParserOptions(this.getParserOptions())
        return clone
    }
}

export {
    
    StringStruct
}
