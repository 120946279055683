import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { HiCheck } from 'react-icons/hi';
import { TiEye } from 'react-icons/ti';

import { useKernel } from '../../context/ContextKernel.mjs';
import { StringStruct } from '../../quantum-lib/Scada/Parser/StringStruct.mjs';
import { UsersService } from '../../services/services.mjs';
import { LoadingInUserPageForm } from '../components.mjs';

import styles from './userFormChangeCurrentUser.module.scss';

export default function UserFormChangeCurrentUser(props) {
  const { setComponentsTypeId, dataForm, setIsError, setErrorMessage, usersTree, initialStateUserTree, setUsersTree, setInitialStateUserTree } = {
    ...props,
  };
  const { kernel } = useKernel();

  const userName = dataForm?.name.length !== 0 ? dataForm?.name : '';
  const userPassword = dataForm?.password.length !== 0 ? dataForm?.password : '';
  const groupId = dataForm?.groupId.length !== 0 ? dataForm?.groupId : null;
  const userId = dataForm?.id;
  const groups = kernel.usersGroups;
  const groupsList = Object.values(groups);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const [tiEyeButtonState, setTiEyeButtonState] = useState(false);
  const [nameUserType, setNameUserType] = useState('password');

  const [nameUserInputValue, setNameUserInputValue] = useState();
  const [passwordUserInputValue, setPasswordUserInputValue] = useState();
  const [userFormChangeCurrentUserGroupSelectValue, setUserFormGroupSelectValue] = useState();
  const [handleChangeNameUser, setHandleChangeNameUser] = useState(false);
  const [handleChangePasswordUser, setHandleChangePasswordUser] = useState(false);
  const [handleChangeuserFormChangeCurrentUserGroupSelect, setHandleChangeuserFormChangeCurrentUserGroupSelect] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);

  const [userEditingCheckState, setUserEditingCheckState] = useState(); //состояние может ли редактировать сам себя

  const handleState = handleChangeNameUser || handleChangePasswordUser || handleChangeuserFormChangeCurrentUserGroupSelect;

  //проверка на редактирование самого себя
  const userEditingCheck = () => {
    const dataFormId = dataForm.id;
    const userAuthId = kernel.getUser().id;
    if (dataFormId === userAuthId) {
      setUserEditingCheckState(true);
    } else {
      setUserEditingCheckState(false);
    }
  };

  const handleChangePasswordUserInput = (event) => {
    if (event.target.value === userName) {
      setHandleChangeNameUser(false);
    } else {
      setHandleChangeNameUser(true);
    }
    setNameUserInputValue(event.target.value);
  };

  const handleChangepassworUserInput = (event) => {
    if (event.target.value === userPassword) {
      setHandleChangePasswordUser(false);
    } else {
      setHandleChangePasswordUser(true);
    }
    setPasswordUserInputValue(event.target.value);
  };

  const handleChangeUserFormGroupSelect = (event) => {
    if (Number(event.target.value) === Number(groupId)) {
      setHandleChangeuserFormChangeCurrentUserGroupSelect(false);
    } else {
      setHandleChangeuserFormChangeCurrentUserGroupSelect(true);
    }
    setUserFormGroupSelectValue(event.target.value);
  };

  const onSubmit = async (data) => {
    setSendingData(true);
    setHandleChangeNameUser(false);
    setHandleChangePasswordUser(false);
    setHandleChangeuserFormChangeCurrentUserGroupSelect(false);
    try {
      if (userId) {
        const response = await UsersService.usersChangingExistingUser(data, userId);
        //запись в kernel и добавление в state
        if (response.groupId !== kernel.getUserById(userId).groupId) {
          const newUser = kernel.changingUserInParametersUserTree(response, kernel.getUserById(userId).groupId);

          kernel.changingUserInParametersUserTree(response, kernel.getUserById(userId).groupId);
          setUsersTree(
            usersTree.map((group) => {
              if (group.id === newUser.groupId) {
                //добавление пользователя в новую группу
                group.children = [...group.children];
              } else if (group.id === kernel.getUserById(userId).groupId) {
                //удаление пользователя из старой группы
                group.children.map((user, index) => {
                  if (user.id === userId) {
                    group.children.splice(index, 1);
                  }
                  return user;
                });
              }
              return group;
            }),
          );

          setInitialStateUserTree(
            initialStateUserTree.map((group) => {
              if (group.id === newUser.groupId) {
                //добавление пользователя в новую группу
                group.children = [...group.children];
              } else if (group.id === kernel.getUserById(userId).groupId) {
                //удаление пользователя из старой группы
                group.children.map((user, index) => {
                  if (user.id === userId) {
                    group.children.splice(index, 1);
                  }
                  return user;
                });
              }
              return group;
            }),
          );
        } else {
          const newUser = kernel.changingUserInParametersUserTree(response, kernel.getUserById(userId).groupId);

          //пользователь поменял не группу (логи или пароль)
          setUsersTree(
            usersTree.map((group) => {
              if (group.id === kernel.getUserById(userId).groupId) {
                group.children.map((user) => {
                  if (user.id === userId) {
                    user.userStruct.name = new StringStruct(newUser.name);
                    user.userStruct.password = new StringStruct(newUser.password);
                  }
                  return user;
                });
              }
              return group;
            }),
          );

          setInitialStateUserTree(
            initialStateUserTree.map((group) => {
              if (group.id === kernel.getUserById(userId).groupId) {
                group.children.map((user) => {
                  if (user.id === userId) {
                    user.userStruct.name = new StringStruct(newUser.name);
                    user.userStruct.password = new StringStruct(newUser.password);
                  }
                  return user;
                });
              }
              return group;
            }),
          );
        }
      }
      setTimeout(() => {
        setSendingData(false);
        setDataSentSuccessfully(true);
      }, 800);

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setComponentsTypeId(0);
      }, 1600);
    } catch (error) {
      setSendingData(false);
      setHandleChangeNameUser(true);
      setHandleChangePasswordUser(true);
      setHandleChangeuserFormChangeCurrentUserGroupSelect(true);
      //сообщение об ошибке
      setIsError(true);
      const errorMessage = error.toString().split('Error: ')[1];
      setErrorMessage(errorMessage);
      console.warn('error: ', error);
    }
  };

  const userFormChangeCurrentUserButtonBackClick = () => {
    setComponentsTypeId(0);
  };

  const showPasswordFieldValues = (event) => {
    setTiEyeButtonState(!tiEyeButtonState);
    if (tiEyeButtonState) {
      setNameUserType('password');
    } else {
      setNameUserType('text');
    }
  };

  const initialData = () => {
    setValue('nameUser', userName);
    setValue('passwordUser', userPassword);
    setValue('groupUser', groupId);
    trigger('nameUser');
    trigger('passwordUser');
    trigger('groupUser');
    setNameUserInputValue(userName);
    setPasswordUserInputValue(userPassword);
    setUserFormGroupSelectValue(groupId);
  };

  useEffect(() => {
    initialData();
    userEditingCheck();
    return () => {};
  }, [dataForm?.id]);

  return (
    <div className={styles.userFormChangeCurrentUser}>
      <div className={styles.userFormChangeCurrentUserHeader}>
        <h2 className={styles.userFormChangeCurrentUserTitle}>Пользователь</h2>
        <BsArrowLeftCircle className={styles.userFormChangeCurrentUserButtonBack} onClick={userFormChangeCurrentUserButtonBackClick} />
      </div>
      <form className={styles.userFormChangeCurrentUserForm} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.container}>
          <div className={styles.inputWrap}>
            <input
              className={classNames(styles.input, errors.nameUser && styles.inputError, userEditingCheckState && styles.userEditingCheckState)}
              title={userEditingCheckState && "Вы не можете редактировать поле 'Имя пользователя' сами у себя"}
              type="text"
              placeholder="Имя пользователя"
              value={nameUserInputValue}
              onInput={handleChangePasswordUserInput}
              readOnly={sendingData || dataSentSuccessfully || userEditingCheckState}
              {...register('nameUser', {
                required: 'Поле обязательно к заполнению',
                minLength: {
                  value: 3,
                  message: 'Поле должно содержать минимум 3 символа',
                },
                maxLength: {
                  value: 255,
                  message: 'Поле может содержать максимум 255 символа',
                },
              })}
            />
            {errors?.nameUser && <p className={styles.errorMessage}>{errors?.nameUser?.message || 'Ошибка заполнения!'}</p>}
          </div>
          <div className={styles.inputWrap}>
            <input
              className={classNames(styles.input, styles.inputPassword, errors.passwordUser && styles.inputError)}
              type={nameUserType}
              placeholder="Пароль"
              autoComplete="new-password"
              value={passwordUserInputValue}
              onInput={handleChangepassworUserInput}
              readOnly={sendingData || dataSentSuccessfully}
              {...register('passwordUser', {
                required: 'Поле обязательно к заполнению',
                minLength: {
                  value: 5,
                  message: 'Поле должно содержать минимум 5 символов',
                },
                maxLength: {
                  value: 255,
                  message: 'Поле может содержать максимум 255 символа',
                },
              })}
            />
            <TiEye className={classNames(styles.tiEyeButton, tiEyeButtonState && styles.tiEyeButtonActive)} onClick={showPasswordFieldValues} />
            {errors?.passwordUser && <p className={styles.errorMessage}>{errors?.passwordUser?.message || 'Ошибка заполнения!'}</p>}
          </div>

          <select
            className={styles.userFormChangeCurrentUserGroupSelect}
            value={userFormChangeCurrentUserGroupSelectValue}
            onInput={handleChangeUserFormGroupSelect}
            disabled={dataSentSuccessfully || userEditingCheckState}
            title={userEditingCheckState && "Вы не можете редактировать поле 'Выбор группы пользователей' сами у себя"}
            {...register('groupUser')}
          >
            {groupsList.map((group) => (
              <option className={styles.userFormChangeCurrentUserGroupSelectOption} key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </select>
        </div>
        <button
          className={
            sendingData || dataSentSuccessfully
              ? styles.buttonSending
              : (handleChangeNameUser || handleChangePasswordUser || handleChangeuserFormChangeCurrentUserGroupSelect) && isValid
                ? classNames(styles.button, styles.formValid)
                : classNames(styles.button, styles.formNoValid)
          }
          type="submit"
          disabled={!isValid || !handleState}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Изменить'}
        </button>
      </form>
    </div>
  );
}
