import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import { AiTwotoneSave } from 'react-icons/ai';

import { LoadingInTableSave, Tooltip } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import Utils from '../../utils/Utils.mjs';

import styles from './rangeEditor.module.scss';

const InputRangeEditor = (props) => {
  const { type, placeholder, title, name, value, className, register, onInput, errors, sendingData } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  const handleFocus = useCallback(() => {
    setShowTooltip(true);
  }, []);
  const handleBlur = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return (
    <div className={styles.componentContainer}>
      <input
        type={type}
        placeholder={placeholder}
        title={title}
        value={value}
        className={className}
        {...register}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onInput={onInput}
        readOnly={sendingData}
      />
      {showTooltip && errors[name] && <Tooltip message={`${errors[name]?.message || 'Ошибка заполнения!'} `} />}
    </div>
  );
};

const SavePropertiesButton = (props) => {
  const { sendingData, handleChangeComponentTableRow, isValid } = props;

  return (
    <>
      {sendingData ? (
        <LoadingInTableSave />
      ) : (
        <button
          type="submit"
          form="rangeEditorForm"
          className={styles.saveControlButton}
          title={Utils.titleFromButtonSaveVirtualProperty(handleChangeComponentTableRow)}
          disabled={!handleChangeComponentTableRow || !isValid}
        >
          <AiTwotoneSave
            className={handleChangeComponentTableRow && isValid ? classNames(styles.saveControl, styles.saveControlActive) : styles.saveControl}
          />
        </button>
      )}
    </>
  );
};

export default function RangeEditor(props) {
  const { stateElementConfigurationId, selectedParameter, setIsError, setErrorMessage } = props;
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [changesDate, setChangesDate] = useState({ day: '', hour: '' }); // вывод даты и времени последнего изменения свойства (выводится время драйвер адреса)
  const [state, setState] = useState({}); // состояние компонента
  const initialValue = useRef(); // начальное состояние компонента
  const { kernel } = useKernel();

  const saveProperties = async (data) => {
    setHandleChangeComponentTableRow(false);
    setSendingData(true);

    try {
      const rangePropertiesInVolts = Utils.convertArbitraryUnitsInVolts(state);
      const resultValuesArr = [];

      for (const key in rangePropertiesInVolts) {
        const parameterStruct = kernel.getParameterById(rangePropertiesInVolts[key].propertyId);
        const newValue = rangePropertiesInVolts[key].propertyValueRoundUp;
        resultValuesArr.push({ parameterStruct, newValue });
      }

      await kernel.setValues(resultValuesArr);
      initialValue.current = state;

      setTimeout(() => {
        setDataSentSuccessfully(true);
        setSendingData(false);
      }, 800);

      setTimeout(() => {
        setDataSentSuccessfully(false);
      }, 1600);
    } catch (error) {
      setSendingData(false);
      setIsError(true);
      setErrorMessage('Ошибка сохранения!');
      setHandleChangeComponentTableRow(true);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (value === '-') {
      setState({
        ...state,
        [name]: { ...state[name], propertyValueRoundUp: value },
      });
    }

    if (Utils.testIsNumber(value)) {
      setState({
        ...state,
        [name]: { ...state[name], propertyValueRoundUp: value },
      });
    }
    setValue(name, value, { shouldValidate: true });
    trigger([
      'lowerEmergencyLimit',
      'upperEmergencyLimit',
      'lowerInputValue',
      'upperInputValue',
      'lowerWarningLimit',
      'upperWarningLimit',
      'lowerOutputValue',
      'upperOutputValue',
    ]);
  };

  useEffect(() => {
    const properties = selectedParameter?.properties;
    const rangePropertiesChangesDate = Utils.rangePropertiesChangesDate(properties); // дата и время изменения свойств (самое последнее)
    setChangesDate(rangePropertiesChangesDate);

    const rangePropertiesInArbitraryUnits = Utils.convertVoltsInArbitraryUnits(properties);

    setValue('upperInputValue', rangePropertiesInArbitraryUnits.upperInputValue.propertyValueRoundUp);
    setValue('upperOutputValue', rangePropertiesInArbitraryUnits.upperOutputValue.propertyValueRoundUp);
    setValue('lowerInputValue', rangePropertiesInArbitraryUnits.lowerInputValue.propertyValueRoundUp);
    setValue('lowerOutputValue', rangePropertiesInArbitraryUnits.lowerOutputValue.propertyValueRoundUp);

    setValue('upperEmergencyLimit', rangePropertiesInArbitraryUnits.upperEmergencyLimit.propertyValueRoundUp);
    setValue('upperWarningLimit', rangePropertiesInArbitraryUnits.upperWarningLimit.propertyValueRoundUp);
    setValue('lowerEmergencyLimit', rangePropertiesInArbitraryUnits.lowerEmergencyLimit.propertyValueRoundUp);
    setValue('lowerWarningLimit', rangePropertiesInArbitraryUnits.lowerWarningLimit.propertyValueRoundUp);

    trigger([
      'lowerEmergencyLimit',
      'upperEmergencyLimit',
      'lowerInputValue',
      'upperInputValue',
      'lowerWarningLimit',
      'upperWarningLimit',
      'lowerOutputValue',
      'upperOutputValue',
    ]);

    setState(rangePropertiesInArbitraryUnits);
    initialValue.current = rangePropertiesInArbitraryUnits;

    return () => {};
  }, [stateElementConfigurationId]);

  useEffect(() => {
    const valueChangedTest = Utils.valueChangedTest(state, initialValue.current);
    if (valueChangedTest) {
      setHandleChangeComponentTableRow(true);
    } else {
      setHandleChangeComponentTableRow(false);
    }
    return () => {};
  }, [state]);

  const validationSchemeLowerEmergencyLimit = {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      // min
      if (parseFloat(str) < getValues('lowerInputValue')) {
        return 'Значение не должно быть меньше чем "Нижнее входное значение!"';
      }
      // max
      if (parseFloat(str) > getValues('upperInputValue')) {
        return 'Значение не должно быть больше чем "Верхнее входное значение!"';
      }

      // проверка на допустимость значения границы
      if (parseFloat(str) > getValues('upperEmergencyLimit')) {
        return 'Значение не должно быть больше чем "Верхняя аварийная граница"!';
      }
    },
  };

  const validationSchemeLowerWarningLimit = {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      // min
      if (parseFloat(str) < getValues('lowerInputValue')) {
        return 'Значение не должно быть меньше чем "Нижнее входное значение!"';
      }
      // max
      if (parseFloat(str) > getValues('upperInputValue')) {
        return 'Значение не должно быть больше чем "Верхнее входное значение!"';
      }
      if (parseFloat(str) > getValues('upperWarningLimit')) {
        return 'Значение не должно быть больше чем "Верхняя предупредительная граница"!';
      }
    },
  };

  const validationSchemeUpperWarningLimit = {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      // min
      if (parseFloat(str) < getValues('lowerInputValue')) {
        return 'Значение не должно быть меньше чем "Нижнее входное значение!"';
      }
      // max
      if (parseFloat(str) > getValues('upperInputValue')) {
        return 'Значение не должно быть больше чем "Верхнее входное значение!"';
      }

      if (parseFloat(str) < getValues('lowerWarningLimit')) {
        return 'Значение не должно быть меньше чем "Нижняя предупредительная граница"!';
      }
    },
  };

  const validationSchemeUpperEmergencyLimit = {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      // min
      if (parseFloat(str) < getValues('lowerInputValue')) {
        return 'Значение не должно быть меньше чем "Нижнее входное значение!"';
      }
      // max
      if (parseFloat(str) > getValues('upperInputValue')) {
        return 'Значение не должно быть больше чем "Верхнее входное значение!"';
      }

      // проверка на допустимость значения границы
      if (parseFloat(str) < getValues('lowerEmergencyLimit')) {
        return 'Значение не должно быть больше чем "Нижняя аварийная граница"!';
      }
    },
  };

  const validationSchemeLowerInputValue = {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      if (parseFloat(str) >= getValues('upperInputValue')) {
        return 'Значение не должно быть больше чем "Верхнее входное значение!"';
      }
    },
  };

  const validationSchemeLowerOutputValue = {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      if (parseFloat(str) >= parseFloat(getValues('upperOutputValue'))) {
        return 'Значение не должно быть больше чем "Верхнее выходное значение!"';
      }
    },
  };

  const validationSchemeUpperInputValue = {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      if (parseFloat(str) <= getValues('lowerInputValue')) {
        return 'Значение не должно быть меньше чем "Нижнее входное значение!"';
      }
    },
  };

  const validationSchemeUpperOutputValue = {
    required: 'Поле обязательно к заполнению!',
    validate: (value) => {
      const str = value.toString();
      if (str.split('')[str.length - 1] === '.') {
        return 'Неправильный формат заполнения!';
      }
      if (str.split('')[0] === '-' && str.split('').length < 2) {
        return 'Неправильный формат заполнения!';
      }
      if (parseFloat(str) <= getValues('lowerOutputValue')) {
        return 'Значение не должно быть меньше чем "Нижнее выходное значение!"';
      }
    },
  };

  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableRowTd}>
        <table className={styles.subTable}>
          <tr className={classNames(styles.subTableRow,styles.firstRow)} >
            <span className={styles.tableRowText} title={'Аппаратные уставки'}>
              Аппаратные уставки
            </span>
          </tr>
          <tr className={styles.subTableRow}>
            <span className={styles.tableRowText} title={'Верхняя аварийная граница'}>
              Верхняя аварийная
            </span>
          </tr>
          <tr className={styles.subTableRow}>
            <span className={styles.tableRowText} title={'Верхняя предупредительная граница'}>
              Верхняя предупредительная
            </span>
          </tr>
          <tr className={styles.subTableRow}>
            <span className={styles.tableRowText} title={'Нижняя предупредительная граница'}>
              Нижняя предупредительная
            </span>
          </tr>
          <tr className={styles.subTableRow}>
            <span className={styles.tableRowText} title={'Нижняя аварийная граница'}>
              Нижняя аварийная
            </span>
          </tr>
        </table>
      </td>
      <td className={styles.tableRowTd}>
        <form id="rangeEditorForm" onSubmit={handleSubmit(saveProperties)}></form>
        <table className={styles.subTable}>
          <tr className={styles.subTableRow}>
            <table className={styles.subTable}>
              <tr className={styles.subTableRow}>
                <td className={styles.subTableRowTd}></td>
                <td className={styles.subTableRowTd}>
                  <span className={classNames(styles.tableRowText, styles.tableRowTextCenter)} title={'Нижняя граница'}>
                    Нижние
                  </span>
                </td>
                <td className={styles.subTableRowTd}>
                  <span className={classNames(styles.tableRowText, styles.tableRowTextCenter)} title={'Верхняя граница'}>
                    Верхние
                  </span>
                </td>
                <td className={styles.subTableRowTd}></td>
              </tr>
              <tr className={styles.subTableRow}>
                <td className={styles.subTableRowTd}>
                  <span className={styles.tableRowText} title={'Входные значения'}>
                    Входные
                  </span>
                </td>
                <td className={styles.subTableRowTd}>
                  <InputRangeEditor
                    type="text"
                    placeholder="НГ"
                    title="Входная величина, нижняя граница"
                    name="lowerInputValue"
                    value={state?.lowerInputValue?.propertyValueRoundUp}
                    className={classNames(
                      styles.inputNumber,
                      errors?.lowerInputValue && styles.inputNumberError,
                      sendingData && styles.inputNumberSending,
                    )}
                    register={{ ...register('lowerInputValue', validationSchemeLowerInputValue) }}
                    onInput={handleChange}
                    errors={errors}
                    sendingData={sendingData}
                  />
                </td>
                <td className={styles.subTableRowTd}>
                  <InputRangeEditor
                    type="text"
                    placeholder="ВГ"
                    title="Входная величина, верхняя граница"
                    name="upperInputValue"
                    value={state?.upperInputValue?.propertyValueRoundUp}
                    className={classNames(
                      styles.inputNumber,
                      errors?.upperInputValue && styles.inputNumberError,
                      sendingData && styles.inputNumberSending,
                    )}
                    register={{ ...register('upperInputValue', validationSchemeUpperInputValue) }}
                    onInput={handleChange}
                    errors={errors}
                    sendingData={sendingData}
                  />
                </td>
              </tr>
              <tr className={styles.subTableRow}>
                <td className={styles.subTableRowTd}>
                  <span className={styles.tableRowText} title={'Выходные значения'}>
                    Выходные
                  </span>
                </td>
                <td className={styles.subTableRowTd}>
                  <InputRangeEditor
                    type="text"
                    placeholder="НГ"
                    title="Выходная величина, нижняя граница"
                    name="lowerOutputValue"
                    value={state?.lowerOutputValue?.propertyValueRoundUp}
                    className={classNames(
                      styles.inputNumber,
                      errors?.lowerOutputValue && styles.inputNumberError,
                      sendingData && styles.inputNumberSending,
                    )}
                    register={{ ...register('lowerOutputValue', validationSchemeLowerOutputValue) }}
                    onInput={handleChange}
                    errors={errors}
                    sendingData={sendingData}
                  />
                </td>
                <td className={styles.subTableRowTd}>
                  <InputRangeEditor
                    type="text"
                    placeholder="ВГ"
                    title="Выходная величина, верхняя граница"
                    name="upperOutputValue"
                    value={state?.upperOutputValue?.propertyValueRoundUp}
                    className={classNames(
                      styles.inputNumber,
                      errors?.upperOutputValue && styles.inputNumberError,
                      sendingData && styles.inputNumberSending,
                    )}
                    register={{ ...register('upperOutputValue', validationSchemeUpperOutputValue) }}
                    onInput={handleChange}
                    errors={errors}
                    sendingData={sendingData}
                  />
                </td>
              </tr>
            </table>
          </tr>
          <tr className={styles.subTableRow}>
            <td className={styles.subTableRowTd}>
              <InputRangeEditor
                type="text"
                placeholder="Верхняя аварийная граница"
                title="Верхняя аварийная граница"
                name="upperEmergencyLimit"
                value={state?.upperEmergencyLimit?.propertyValueRoundUp}
                className={classNames(
                  styles.inputNumber,
                  errors?.upperEmergencyLimit && styles.inputNumberError,
                  sendingData && styles.inputNumberSending,
                )}
                register={{ ...register('upperEmergencyLimit', validationSchemeUpperEmergencyLimit) }}
                onInput={handleChange}
                errors={errors}
                sendingData={sendingData}
              />
            </td>
          </tr>
          <tr className={styles.subTableRow}>
            <td className={styles.subTableRowTd}>
              <InputRangeEditor
                type="text"
                placeholder="Верхняя предупредительная граница"
                title="Верхняя предупредительная граница"
                name="upperWarningLimit"
                value={state?.upperWarningLimit?.propertyValueRoundUp}
                className={classNames(
                  styles.inputNumber,
                  errors?.upperWarningLimit && styles.inputNumberError,
                  sendingData && styles.inputNumberSending,
                )}
                register={{ ...register('upperWarningLimit', validationSchemeUpperWarningLimit) }}
                onInput={handleChange}
                errors={errors}
                sendingData={sendingData}
              />
            </td>
          </tr>
          <tr className={styles.subTableRow}>
            <td className={styles.subTableRowTd}>
              <InputRangeEditor
                type="text"
                placeholder="Нижняя предупредительная граница"
                title="Нижняя предупредительная граница"
                name="lowerWarningLimit"
                value={state?.lowerWarningLimit?.propertyValueRoundUp}
                className={classNames(
                  styles.inputNumber,
                  errors?.lowerWarningLimit && styles.inputNumberError,
                  sendingData && styles.inputNumberSending,
                )}
                register={{ ...register('lowerWarningLimit', validationSchemeLowerWarningLimit) }}
                onInput={handleChange}
                errors={errors}
                sendingData={sendingData}
              />
            </td>
          </tr>
          <tr className={styles.subTableRow}>
            <td className={styles.subTableRowTd}>
              <InputRangeEditor
                type="text"
                placeholder="Нижняя аварийная граница"
                title="Нижняя аварийная граница"
                name="lowerEmergencyLimit"
                value={state?.lowerEmergencyLimit?.propertyValueRoundUp}
                className={classNames(
                  styles.inputNumber,
                  errors?.lowerEmergencyLimit && styles.inputNumberError,
                  sendingData && styles.inputNumberSending,
                )}
                register={{ ...register('lowerEmergencyLimit', validationSchemeLowerEmergencyLimit) }}
                onInput={handleChange}
                errors={errors}
                sendingData={sendingData}
              />
            </td>
          </tr>
        </table>
      </td>
      <td className={styles.tableRowTd}>
        <table className={styles.subTable}>
          <tr className={styles.subTableRow}>
            <div className={styles.dateContainer}>
              <span className={classNames(styles.tableRowText, styles.tableDate)}>{changesDate?.day}</span>
              <span className={styles.tableRowText}>{changesDate?.hour}</span>
            </div>
          </tr>
        </table>
      </td>
      <td className={styles.tableRowTd}>
        <table className={styles.subTable}>
          <tr className={styles.subTableRow}>
            <div className={styles.controlContainer}>
              {dataSentSuccessfully ? (
                <HiCheck className={styles.checkControl} />
              ) : (
                <SavePropertiesButton sendingData={sendingData} handleChangeComponentTableRow={handleChangeComponentTableRow} isValid={isValid} />
              )}
            </div>
          </tr>
        </table>
      </td>
    </tr>
  );
}
