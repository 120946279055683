import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';

import { AlarmService } from '../../services/services.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { ConfirmationPopUp } from '../components.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';

import { AlarmForm, AlarmList } from './ui/index.mjs';
import styles from './alarmEditor.module.scss';

export default function AlarmEditor(props) {
  const { stateElementConfigurationId, alarmEditorState, setAlarmEditorState, configuration, setIsError, setErrorMessage } = props;

  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [popUpDeleteAlarmVisible, setPopUpDeleteAlarmVisible] = useState(false);
  const [status, setStatus] = useState({ loading: false, success: false });
  const { kernel } = useKernel();
  const { activeConfigurationId } = useActiveConfigurationId();
  const deleteAlarmId = useRef();

  const createAlarm = async () => {
    try {
      setSendingData(true);

      const newAlarm = await AlarmService.createAlarm(activeConfigurationId, stateElementConfigurationId.toString());

      kernel.addNewAlarm(newAlarm);

      const alarmList = kernel.getAlarmListByParameterId(stateElementConfigurationId);
      setAlarmEditorState({
        alarmList,
        activeAlarm: alarmList.find((alarm) => alarm.id === parseInt(newAlarm.id)),
        activeAlarmId: parseInt(newAlarm.id),
      });

      setTimeout(() => {
        setDataSentSuccessfully(true);
        setSendingData(false);
      }, 600);

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setPopUpVisible(false);
      }, 1400);
    } catch (error) {
      setSendingData(false);
      setPopUpVisible(false);
      setIsError(true);
      setErrorMessage('Ошибка сохранения!');
    }
  };

  const handleChangeAlarmList = (event) => {
    const alarmId = parseInt(event.currentTarget.dataset.id);
    const alarmListStruct = kernel.getAlarmListByParameterId(stateElementConfigurationId);

    setAlarmEditorState({
      ...alarmEditorState,
      activeAlarm: alarmListStruct.find((alarm) => alarm.id === alarmId),
      activeAlarmId: alarmId,
    });
  };

  const getActiveAlarm = useCallback((alarmListStruct, currentActiveAlarmId) => {
    return alarmListStruct.find((alarmStruct) => alarmStruct.id === currentActiveAlarmId) || alarmListStruct[0];
  }, []);

  const saveArguments = async (data) => {
    try {
      setSendingData(true);
      const alarmId = alarmEditorState.activeAlarmId.toString();

      const changeAlarm = await AlarmService.changeAlarmArguments(activeConfigurationId, {
        id: alarmId,
        typeAlarm: data.typeAlarm,
        parameterId: data.parameterId,
        typeSourceAlarm: data.typeSourceAlarm,
        listSourcesAlarm: data.listSourcesAlarm,
        listConstAlarm: data.listConstAlarm,
        timeout: data.timeout,
        alarmConfigId: data.alarmConfigId,
        workingStatus: data.workingStatus,
      });

      // изменяем
      kernel.changeAlarmByParameterId(stateElementConfigurationId, alarmId, changeAlarm);

      const alarmList = kernel.getAlarmListByParameterId(stateElementConfigurationId);

      setAlarmEditorState({
        alarmList,
        activeAlarm:
          data.parameterId === stateElementConfigurationId.toString() ? alarmList.find((alarm) => alarm.id === parseInt(alarmId)) : alarmList[0],
        activeAlarmId: data.parameterId === stateElementConfigurationId.toString() ? parseInt(alarmId) : parseInt(alarmList[0].id),
      });

      setTimeout(() => {
        setDataSentSuccessfully(true);
        setSendingData(false);
      }, 600);

      setTimeout(() => {
        setDataSentSuccessfully(false);
      }, 1400);
    } catch (error) {
      setSendingData(false);
      setPopUpVisible(false);
      setIsError(true);
      setErrorMessage('Ошибка сохранения!');
    }
  };

  const deleteAlarm = async () => {
    try {
      setStatus({
        ...status,
        loading: true,
      });
      const alarmId = deleteAlarmId.current;
      const deletedAlarm = kernel.getAlarmByParameterId(stateElementConfigurationId, alarmId);

      await AlarmService.deleteAlarm(activeConfigurationId, alarmId);

      kernel.removeAlarmByParameterId(stateElementConfigurationId, alarmId);

      setTimeout(() => {
        setStatus({ loading: false, success: true });
      }, 350);

      setTimeout(() => {
        const alarmList = kernel.getAlarmListByParameterId(deletedAlarm.parameterId);

        setAlarmEditorState({
          alarmList: alarmList.length > 0 ? alarmList : [],
          activeAlarm: alarmList.length > 0 ? alarmList[0] : {},
          activeAlarmId: alarmList.length > 0 ? parseInt(alarmList[0].id) : null,
        });

        setStatus({ loading: false, success: false });
        setPopUpDeleteAlarmVisible(false);
      }, 1300);
    } catch (error) {
      setPopUpDeleteAlarmVisible(false);
      setStatus({ loading: false, success: false });
      setIsError(true);
      setErrorMessage('Ошибка! Не удалось удалить тревогу!');
    }
  };

  const popUpOpen = (event) => {
    deleteAlarmId.current = event.currentTarget.dataset.id;

    setPopUpDeleteAlarmVisible(true);
  };

  const alarmListStruct = kernel.getAlarmListByParameterId(stateElementConfigurationId);
  useEffect(() => {
    if (alarmListStruct && alarmListStruct.length > 0) {
      const activeAlarm = getActiveAlarm(alarmListStruct, alarmEditorState?.activeAlarm?.id);
      const activeAlarmId = activeAlarm.id;

      setAlarmEditorState({ alarmList: alarmListStruct, activeAlarm, activeAlarmId });
    } else {
      setAlarmEditorState({ alarmList: [], activeAlarm: {}, activeAlarmId: null });
    }
    return () => {};
  }, [stateElementConfigurationId]);

  return (
    <div className={styles.alarmEditor}>
      {popUpVisible && (
        <ConfirmationPopUp
          message="Создать тревогу?"
          actionConfirmation={createAlarm}
          closePopup={() => setPopUpVisible(false)}
          loading={sendingData}
          success={dataSentSuccessfully}
        />
      )}
      {popUpDeleteAlarmVisible && (
        <ConfirmationPopUp
          actionConfirmation={deleteAlarm}
          closePopup={() => setPopUpDeleteAlarmVisible(false)}
          message="Вы точно хотите удалить тревогу?"
          loading={status.loading}
          success={status.success}
        />
      )}

      {stateElementConfigurationId !== 0n && (
        <>
          <div className={classNames(styles.alarmEditorColumn, styles.alarmEditorColumnControl)}>
            <AlarmList
              state={alarmEditorState}
              handleChangeAlarmList={handleChangeAlarmList}
              setPopUpVisible={setPopUpVisible}
              deleteAlarm={popUpOpen}
            />
          </div>

          <div className={classNames(styles.alarmEditorColumn, styles.alarmEditorColumnEditor)}>
            {alarmEditorState.alarmList.length > 0 ? (
              <div className={styles.alarmEditorWrap}>
                <AlarmForm
                  onSubmit={saveArguments}
                  sendingData={sendingData}
                  dataSentSuccessfully={dataSentSuccessfully}
                  alarmEditorState={alarmEditorState}
                  configuration={configuration}
                />
              </div>
            ) : (
              <div className={styles.createAlarmTextContainer}>
                <span className={styles.createAlarmText}>Для данного параметра еще не настроены тревоги!</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
