import { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { IconTreeConfiguration } from '../../hoc/hoc.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './treeSettlementServer.module.scss';

const PARAMETER_CLASS_VALUE = 2;
const PARAMETER_CLASS_PROPERTY = 3;

export default memo(function TreeSettlementServer(props) {
  const { configuration, activeElementTree, setActiveElementTree, propertyVisible = false, parameterDisabled = false } = props;

  const configurationClassify = configuration?.classify();
  const [showChildren, setShowChildren] = useState();
  const { kernel } = useKernel();

  const setStyleParameterDisabled = () => {
    if (parameterDisabled) {
      return configurationClassify !== PARAMETER_CLASS_VALUE ? false : [0, 11, 12, 13, 14, 15, 16].includes(configuration.typeNode.systemType.type);
    }
    return false;
  };

  const handleClick = useCallback((id) => {
    setActiveElementTree(id);
  }, []);

  const handleDoubleClick = useCallback(() => {
    setShowChildren(!showChildren);
  }, [showChildren, setShowChildren]);

  useEffect(() => {
    if (Number(configuration?.id) === 0) {
      setShowChildren(Number(configuration?.id) === 0);
    }
    return () => {};
  }, [configuration]);

  if (propertyVisible) {
    return (
      <div className={classNames(styles.tree)}>
        <div className={classNames(styles.treeWrapper)} data-id={configuration.id}>
          <div
            className={classNames(styles.parentElem, Number(activeElementTree) === Number(configuration.id) && styles.parentElemActive)}
            onClick={(event) => handleClick(event.currentTarget.dataset.id)}
            onDoubleClick={(event) => handleDoubleClick(event.currentTarget.dataset.id)}
            data-id={configuration.id}
          >
            <IconTreeConfiguration
              showChildren={showChildren}
              typeElementTree={configurationClassify}
              isDeleted={configuration.isDeleted || setStyleParameterDisabled()}
            />
            <span
              className={classNames(
                styles.parentElemText,
                configuration.isDeleted && styles.isDeleted,
                setStyleParameterDisabled() && styles.isDeleted,
              )}
            >
              {kernel.getUser().settings.hasParameterIdInTree && `#${configuration.id.toString()}`}
              {configurationClassify === PARAMETER_CLASS_PROPERTY ? configuration.description : configuration.displayName}
            </span>
          </div>
          <div className={!showChildren ? styles.childrenElem : classNames(styles.childrenElem, styles.childrenElemActive)}>
            {showChildren &&
              configuration.children.map((data) =>
                data.isTypeNode ? (
                  <></>
                ) : (
                  <TreeSettlementServer
                    key={data.id}
                    configuration={data}
                    activeElementTree={activeElementTree}
                    setActiveElementTree={setActiveElementTree}
                    propertyVisible={propertyVisible}
                    parameterDisabled={parameterDisabled}
                  />
                ),
              )}
            {showChildren &&
              configuration.properties.map((data) => (
                <TreeSettlementServer
                  key={data.id}
                  configuration={data}
                  activeElementTree={activeElementTree}
                  setActiveElementTree={setActiveElementTree}
                  propertyVisible={propertyVisible}
                  parameterDisabled={parameterDisabled}
                />
              ))}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classNames(styles.tree)}>
        <div className={classNames(styles.treeWrapper)} data-id={configuration.id}>
          <div
            className={classNames(styles.parentElem, Number(activeElementTree) === Number(configuration.id) && styles.parentElemActive)}
            onClick={(event) => handleClick(event.currentTarget.dataset.id)}
            onDoubleClick={(event) => handleDoubleClick(event.currentTarget.dataset.id)}
            data-id={configuration.id}
          >
            <IconTreeConfiguration
              showChildren={showChildren}
              typeElementTree={configurationClassify}
              isDeleted={configuration.isDeleted || setStyleParameterDisabled()}
            />
            <span
              className={classNames(
                styles.parentElemText,
                configuration.isDeleted && styles.isDeleted,
                setStyleParameterDisabled() && styles.isDeleted,
              )}
            >
              {kernel.getUser().settings.hasParameterIdInTree && `#${configuration.id.toString()}`} {configuration.displayName}
            </span>
          </div>
          <div className={!showChildren ? styles.childrenElem : classNames(styles.childrenElem, styles.childrenElemActive)}>
            {showChildren &&
              configuration.children.map((data) =>
                data.isTypeNode ? (
                  <></>
                ) : (
                  <TreeSettlementServer
                    key={data.id}
                    configuration={data}
                    activeElementTree={activeElementTree}
                    setActiveElementTree={setActiveElementTree}
                    propertyVisible={propertyVisible}
                    parameterDisabled={parameterDisabled}
                  />
                ),
              )}
          </div>
        </div>
      </div>
    );
  }
});
