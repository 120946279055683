import classNames from 'classnames';
import { useEffect } from 'react';
import { AiTwotoneSave } from 'react-icons/ai';

import { useKernel } from '../../context/ContextKernel.mjs';

import { LoadingInTableSave } from '../components.mjs';
import Utils from '../../utils/Utils.mjs';

import styles from './savePropertiesButton.module.scss';

export default function SavePropertiesButton(props) {
  const { id, canEdit, isWritable, isDeleted, sendingData, isValid, handleChangeComponentTableRow } = props;

  const { kernel } = useKernel();

  useEffect(() => {
    kernel.setHandleDataPageKeyState(id, handleChangeComponentTableRow);
    return () => {};
  }, [handleChangeComponentTableRow]);

  return (
    <>
      {sendingData ? (
        <LoadingInTableSave />
      ) : (
        <button
          className={styles.saveControlButton}
          type="submit"
          form={id}
          title={Utils.titleFromButtonSaveProperty(canEdit, isWritable, isDeleted, isValid, handleChangeComponentTableRow)}
          disabled={!isValid || !handleChangeComponentTableRow || !isWritable || canEdit}
        >
          <AiTwotoneSave
            className={
              handleChangeComponentTableRow && isValid && isWritable && !canEdit
                ? classNames(styles.saveControl, styles.saveControlActive)
                : styles.saveControl
            }
          />
        </button>
      )}
    </>
  );
}
