import { ConfigurationsListStruct } from '../quantum-lib/Scada/Parser/Configurations/ConfigurationsListStruct.mjs';
import { ParametersListStruct } from '../quantum-lib/Scada/Parser/Configurations/ParametersListStruct.mjs';

import { setWorkingMode } from './services.mjs';

export class ConfigurationService {
  static async getConfigurationElem() {
    return fetch(`${setWorkingMode()}/get-configuration-element`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const dataView = new DataView(arrayBuffer);
        const offset = 0;

        const configurationsListStruct = new ConfigurationsListStruct();
        configurationsListStruct.readFrom(dataView, offset, isLittleEndian);

        return configurationsListStruct;
      });
  }

  static async getSpecificConfiguration(idConfig) {
    return fetch(`${setWorkingMode()}/get-specific-configuration/${idConfig}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const parametersListStruct = new ParametersListStruct();
        parametersListStruct.readFrom(dataView, offset, isLittleEndian);

        return parametersListStruct;
      });
  }

  static async getIdRemoteConfigurationObjects(idConfig) {
    const response = await fetch(`${setWorkingMode()}/get-remote-configuration-objects/${idConfig}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }

  }

  static async createNewConfiguration(name) {
    const response = await fetch(`${setWorkingMode()}/create-new-configuration`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ name }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }

  static async checkConfigurationName(name) {
    const response = await fetch(`${setWorkingMode()}/check-configuration-name`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ name }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }

  static async deleteObject(parameterId, configId) {
    const response = await fetch(`${setWorkingMode()}/delete-object`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ parameterId, configId }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }

  static async recoveryObject(parameterId, configId) {
    const response = await fetch(`${setWorkingMode()}/recovery-object`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ parameterId, configId }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }

  static async changeParentIdOfParameter(parameterId, parameterParentId, configId) {
    const response = await fetch(`${setWorkingMode()}/change-parent-id-of-parameter`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ parameterId, parameterParentId, configId }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }

  static async deletePropertyById(propertyId, configId) {
    const response = await fetch(`${setWorkingMode()}/delete-property-by-id/${configId}`, {
      method: 'delete',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ propertyId }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    }
  }

  static async recoveryPropertyById(propertyId, configId) {
    const response = await fetch(`${setWorkingMode()}/recovery-property-by-id/${configId}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ propertyId }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    }
  }
}
