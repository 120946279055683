import { IconFolderConfigurationTree, IconObjectConfigurationTree, IconValueConfigurationTree } from '../components/components.mjs';

export function IconTreeConfiguration(props) {
  const { showChildren, typeElementTree, isDeleted } = props;

  const PARAMETER_CLASS_FOLDER = 0;
  const PARAMETER_CLASS_OBJECT = 1;
  const PARAMETER_CLASS_VALUE = 2;
  const PARAMETER_CLASS_PROPERTY = 3;

  const iconParameterClassComponent = {};

  iconParameterClassComponent[PARAMETER_CLASS_FOLDER] = <IconFolderConfigurationTree showChildren={showChildren} isDeleted={isDeleted} />;
  iconParameterClassComponent[PARAMETER_CLASS_OBJECT] = <IconFolderConfigurationTree showChildren={showChildren} isDeleted={isDeleted} />;
  iconParameterClassComponent[PARAMETER_CLASS_VALUE] = <IconObjectConfigurationTree isDeleted={isDeleted} />;
  iconParameterClassComponent[PARAMETER_CLASS_PROPERTY] = <IconValueConfigurationTree isDeleted={isDeleted} />;

  let components;
  const setComponent = () => {
    if (typeElementTree in iconParameterClassComponent) {
      return (components = iconParameterClassComponent[typeElementTree]);
    } else {
      return (components = <p>Произошла ошибка при выборе действия!</p>);
    }
  };
  setComponent();

  return <>{components}</>;
}
