const BITMASK_IS_ADMIN = 0b0001; // 1
const BITMASK_HAS_CREATE_CONFIGURATION = 0b0010; // 2
const BITMASK_HAS_MODULE_USERS = 0b00100; // 4
const BITMASK_HAS_MODULE_LICS = 0b01000; // 8
const BITMASK_HAS_MODULE_CALC = 0b10000; // 16
const BITMASK_HAS_MODULE_TASK = 0b100000; // 32
const BITMASK_HAS_MODULE_SETPOINTS = 0b1000000; // 64
const BITMASK_HAS_MODULE_ALARM = 0b10000000; // 128
const BITMASK_HAS_MODULE_LOGS = 0b100000000; // 256
// 511 root права


export const flagsParser = (flags) => {
  return {
    isAdmin: flags & BITMASK_IS_ADMIN !== 0 ? true : false,
    hasAccessRightsCreatingConfigurations: (flags & BITMASK_HAS_CREATE_CONFIGURATION) !== 0 ? true : false,
    hasAccessRightsEventLogsModule: (flags & BITMASK_HAS_MODULE_LOGS) !== 0 ? true : false,
    hasAccessRightsUsersModule: (flags & BITMASK_HAS_MODULE_USERS) !== 0 ? true : false,
    hasAccessRightsLicensesModule: (flags & BITMASK_HAS_MODULE_LICS) !== 0 ? true : false,
    hasAccessRightsCalculationServerModule: (flags & BITMASK_HAS_MODULE_CALC) !== 0 ? true : false,
    hasAccessRightsTaskServerModule: (flags & BITMASK_HAS_MODULE_TASK) !== 0 ? true : false,
    hasAccessRightsSetpointsModule: (flags & BITMASK_HAS_MODULE_SETPOINTS) !== 0 ? true : false,
    hasAccessRightsAlarmServerModule: (flags & BITMASK_HAS_MODULE_ALARM) !== 0 ? true : false,
  };
};
