import { TaskServerListStruct } from '../quantum-lib/Arm/Parser/TaskServer/TaskServerListStruct.mjs';
import { TaskServerStruct } from '../quantum-lib/Arm/Parser/TaskServer/TaskServerStruct.mjs';

import { setWorkingMode } from './services.mjs';


export class TaskServerService {

  static async getTasksListStruct(idConfiguration) {
    return fetch(`${setWorkingMode()}/get-task-list/${idConfiguration}`, {
      method: 'get',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const taskServerList = new TaskServerListStruct();
        taskServerList.readFrom(dataView, offset, isLittleEndian);

        return taskServerList;
      });
  }

  static async createTask(idConfiguration, typeTask, nameTask) {
    return fetch(`${setWorkingMode()}/create-task/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ typeTask, nameTask }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const taskServerStruct = new TaskServerStruct();
        taskServerStruct.readFrom(dataView, offset, isLittleEndian);

        return taskServerStruct;
      });
  }

  static async changeTaskArguments(configurationId, params) {
    const response = await fetch(`${setWorkingMode()}/change-task-arguments/${configurationId}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ ...params }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    }
  }

  static async changeTaskScript(configurationId, taskId, script) {
    const response = await fetch(`${setWorkingMode()}/change-task-script/${configurationId}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ taskId, script }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    }
  }

  static async changeTaskSettings(configurationId, params) {
    const response = await fetch(`${setWorkingMode()}/change-task-settings/${configurationId}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ ...params }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    }
  }
}