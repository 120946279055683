import { PropertyHistoryListStruct } from '../quantum-lib/Arm/Parser/Configurations/PropertyHistoryListStruct.mjs';

import { setWorkingMode } from './services.mjs';

export class PropertyHistoryService {

  static async propertyHistoryList(parameterId, systemTypeId, systemTypeType, idConfiguration) {
    return fetch(`${setWorkingMode()}/property-history-list/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ parametrId: parameterId, systemTypeId, systemTypeType }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const propertyHistoryListStruct = new PropertyHistoryListStruct();
        propertyHistoryListStruct.readFrom(dataView, offset, isLittleEndian);

        return propertyHistoryListStruct;
      });
  }
}