import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { MdGpsFixed } from 'react-icons/md';

import Utils from '../../utils/Utils.mjs';
import { Tooltip } from '../components.mjs';

import GpsEditor from './ui/GpsEditor';
import styles from './inputCoordinatesPoint.module.scss';

export default function InputCoordinatesPoint(props) {
  //GPS координаты объекта
  const {
    value,
    setValue,
    getValues,
    saveProperty,
    placeholder,
    canEdit,
    isWritable,
    isDeleted,
    register,
    unregister,
    name,
    handleFocus,
    errors,
    showTooltip,
    validationScheme,
    setHandleChangeComponentTableRow,
    sendingData,
    dataSentSuccessfully,
    setIsError,
    setErrorMessage,
  } = props;

  const [inputCoordinatesPointInitialValueValid, setInputCoordinatesPointInitialValueValid] = useState(true); //состояние которое хранит в себе проверку на валидность присланных данных с БД
  const [incorrectValue, setIncorrectValue] = useState(); //некорректное значение которое пришло с базы
  const [autoFocusState, setAutoFocusState] = useState(false); //автофокус на input принимает значение true в случае если с базы пришли некорректные значения
  const [openGpsEditor, setOpenGpsEditor] = useState(false);

  const initialValue = useRef(value);

  const setInputValue = ({ target }) => {
    if (target.value.toString() === initialValue.current.toString()) {
      setHandleChangeComponentTableRow(false);
    } else {
      setHandleChangeComponentTableRow(true);
    }
    setValue(name, target.value);
  };

  useEffect(() => {
    const testInputCoordinatesPointInitialValue = Utils.testInputCoordinatesPointInitialValue(value);
    setInputCoordinatesPointInitialValueValid(testInputCoordinatesPointInitialValue);

    if (testInputCoordinatesPointInitialValue) {
      setValue(name, value);
    } else {
      setIncorrectValue(value);
    }
    return () => {
      unregister([name]);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  return (
    <>
      {openGpsEditor && (
        <GpsEditor
          saveProperty={saveProperty}
          setOpenGpsEditor={setOpenGpsEditor}
          setValue={setValue}
          name={name}
          coordinatesPointValue={getValues(name)}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
          sendingData={sendingData}
          dataSentSuccessfully={dataSentSuccessfully}
        />
      )}

      <div className={styles.inputCoordinatesPointContainer}>
        <div className={styles.inputCoordinatesPointForm}>
          {inputCoordinatesPointInitialValueValid ? (
            <div className={styles.inputContainer}>
              <input
                type="text"
                placeholder={placeholder}
                className={classNames(
                  styles.inputCoordinatesPointValue,
                  errors[name] && styles.inputCoordinatesPointValueError,
                  sendingData && styles.inputCoordinatesPointValueSending,
                  canEdit && styles.inputCoordinatesPointValueSending,
                  !isWritable && styles.inputCoordinatesPointValueSending,
                  isDeleted && styles.inputCoordinatesPointValueSending,
                )}
                onFocus={handleFocus}
                onInput={setInputValue}
                autoFocus={autoFocusState}
                readOnly={sendingData || canEdit || !isWritable || isDeleted}
                {...register(name, validationScheme)}
              />
              <button className={styles.button} disabled={sendingData || canEdit || !isWritable || isDeleted} type="button">
                <MdGpsFixed className={styles.icon} onClick={() => setOpenGpsEditor(true)} />
              </button>
              {showTooltip && errors[name] && <Tooltip message={`${errors[name]?.message || 'Ошибка заполнения!'} `} />}
            </div>
          ) : (
            <div className={styles.inputContainer}>
              <input
                type="text"
                placeholder="Некорректные данные"
                value={incorrectValue}
                className={classNames(styles.inputText, styles.inputTextError)}
                onFocus={(event) => {
                  setValue(name, '');
                  setHandleChangeComponentTableRow(true);
                  setInputCoordinatesPointInitialValueValid(true);
                  setAutoFocusState(true);
                }}
              />
              {<Tooltip message={'Значения, хранящиеся в БД, не соответствуют системному типу!'} />}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
