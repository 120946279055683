import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { HiCheck } from 'react-icons/hi';

import { ConfigurationStruct } from '../../quantum-lib/Scada/Parser/Configurations/ConfigurationStruct.mjs';
import { StringStruct } from '../../quantum-lib/Scada/Parser/StringStruct.mjs';
import { ConfigurationService } from '../../services/services.mjs';
import { LoadingInUserPageForm } from '../components.mjs';

import styles from './createNewConfiguration.module.scss';

export default function CreateNewConfiguration(props) {
  const { setIsAddingConfig, configurationData, setConfigurationData, setIsError, setErrorMessage } = props;

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const [nameConfigurationInputValue, setNameConfigurationInputValue] = useState();
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);

  const handleChangeInputValue = (event) => {
    setNameConfigurationInputValue(event.target.value);
  };

  const onSubmit = async (data) => {
    setSendingData(true);

    try {
      const name = new StringStruct(data.nameConfiguration);
      const createNewConfigurationId = await ConfigurationService.createNewConfiguration(name.value);

      const configurationStruct = new ConfigurationStruct(createNewConfigurationId, name);
      setConfigurationData([...configurationData, configurationStruct]);

      setTimeout(() => {
        setSendingData(false);
        setDataSentSuccessfully(true);
      }, 400);

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setIsAddingConfig(false);
      }, 1500);
    } catch (error) {
      setSendingData(false);
      setIsAddingConfig(false);

      //сообщение об ошибке
      setIsError(true);
      const errorMessage = error.toString().split('Error: ')[1];
      setErrorMessage(errorMessage);
      console.warn('error: ', error);
    }
  };

  return (
    <div className={styles.createNewConfiguration}>
      <div className={styles.form}>
        <div className={styles.formHeader}>
          <h2 className={styles.formTitle}>Новая конфигурация</h2>
          <BsArrowLeftCircle className={styles.buttonBack} onClick={() => setIsAddingConfig(false)} />
        </div>
        <form className={styles.formForm} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.container}>
            <div className={styles.inputWrap}>
              <input
                className={classNames(styles.input, errors.nameConfiguration && styles.inputError)}
                type="text"
                placeholder="Имя новой конфигурации"
                onInput={handleChangeInputValue}
                value={nameConfigurationInputValue}
                {...register('nameConfiguration', {
                  required: 'Поле обязательно к заполнению',
                  minLength: {
                    value: 3,
                    message: 'Поле должно содержать минимум 3 символа',
                  },
                  maxLength: {
                    value: 255,
                    message: 'Поле может содержать максимум 255 символа',
                  },
                  validate: {
                    checkLicensesName: async (event) => {
                      try {
                        const name = getValues('nameConfiguration');
                        const checkConfigurationName = await ConfigurationService.checkConfigurationName(name);

                        if (checkConfigurationName) {
                          return true;
                        } else {
                          return 'Конфигурация с таким именем уже существует!';
                        }
                      } catch (error) {
                        const errorMessage = error.toString().split('Error: ')[1];
                        return errorMessage;
                      }
                    },
                  },
                })}
              />
              {errors?.nameConfiguration && <p className={styles.errorMessage}>{errors?.nameConfiguration?.message || 'Ошибка заполнения!'}</p>}
            </div>
          </div>
          <button
            className={
              sendingData || dataSentSuccessfully
                ? styles.buttonSending
                : isValid
                ? classNames(styles.button, styles.formValid)
                : classNames(styles.button, styles.formNoValid)
            }
            type="submit"
            disabled={!isValid}
          >
            {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Создать'}
          </button>
        </form>
      </div>
    </div>
  );
}
