import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCodeMirror } from '@uiw/react-codemirror';
import { HiCheck } from 'react-icons/hi';
import { StreamLanguage } from '@codemirror/language';
import { shell } from '@codemirror/legacy-modes/mode/shell';

import { LoadingInUserPageForm } from '../../../components.mjs';
import { useKernel } from '../../../../context/ContextKernel.mjs';
import { TaskServerService } from '../../../../services/services.mjs';
import { StringStruct } from '../../../../quantum-lib/Scada/Parser/StringStruct.mjs';
import { useActiveConfigurationId } from '../../../../hooks/hooks.mjs';

import styles from './taskEditorScript.module.scss';

export function TaskEditorScript(props) {
  const {
    codeTaskValue,
    setCodeTaskValue,
    sendingData,
    dataSentSuccessfully,
    activeTask,
    setDataSentSuccessfully,
    setSendingData,
    setIsError,
    setErrorMessage,
    setTask,
    task,
  } = props;

  const editor = useRef();
  const [errors, setErrors] = useState(false);
  const { kernel } = useKernel();
  const { activeConfigurationId } = useActiveConfigurationId();

  const handleEditorChangeLaunchParameters = useCallback(
    (strValue, viewUpdate) => {
      setCodeTaskValue({
        code: strValue,
        codeLength: strValue.length,
      });

      // проверка на ошибки
      setErrors(strValue.length > 5100);
    },
    [setCodeTaskValue],
  );

  const { setContainer } = useCodeMirror({
    container: editor.current,
    onChange: handleEditorChangeLaunchParameters,
    value: codeTaskValue.code,
    theme: 'dark',
    width: '100%',
    height: '300px',
    allowMultipleSelections: true,
    autoFocus: true,
    extensions: [StreamLanguage.define(shell)],
  });

  const saveTaskEditorScript = async (script) => {
    try {
      setSendingData(true);

      // запрос на изменение данных
      await TaskServerService.changeTaskScript(activeConfigurationId, task.activeTaskId, script);

      // изменение состояния
      kernel.changedTask({ script: new StringStruct(script), typeTask: task.activeTask.typeTask.value, taskId: task.activeTaskId }, task.activeTask);

      const activeTask = kernel.getTaskByIdAndTypeTask(task.activeTaskId, task.activeTask.typeTask.value);

      setTask({
        ...task,
        taskList: Object.values(kernel.getTasksListForTaskServer()),
        activeTask,
      });

      setTimeout(() => {
        setDataSentSuccessfully(true);
        setSendingData(false);
      }, 800);

      setTimeout(() => {
        setDataSentSuccessfully(false);
      }, 1600);
    } catch (error) {
      setSendingData(false);
      setIsError(true);
      setErrorMessage('Ошибка сохранения!');
    }
  };

  useEffect(() => {
    const strValue = activeTask?.script ?? '';
    setCodeTaskValue({ code: strValue, codeLength: strValue.length });
    return () => {};
  }, [activeTask?.id]);

  useEffect(() => {
    if (editor.current) {
      setContainer(editor.current);
    }
    return () => {};
  }, [editor.current]);

  return (
    <div className={styles.taskEditorContainer}>
      <div className={styles.serverSettingsContainer}>
        <div className={styles.taskEditorHeader}>
          <span className={styles.taskEditorHeaderText}>
            <strong
              className={classNames(
                styles.taskEditorHeaderTextActive,
                codeTaskValue.codeLength > 4900 && styles.taskEditorHeaderTextWarningBorder,
                codeTaskValue.codeLength >= 5100 && styles.taskEditorHeaderTextErrorBorder,
              )}
            >
              {codeTaskValue.codeLength}
            </strong>
            /5100
          </span>
        </div>
        <div className={styles.editorWrap}>
          <div className={styles.editor} ref={editor}></div>
        </div>
      </div>
      <div className={styles.controlContainer}>
        <button
          className={
            sendingData || dataSentSuccessfully
              ? styles.creatingNewModuleFormSubmitSending
              : classNames(styles.creatingNewModuleFormSubmit, errors ? styles.formNoValid : styles.formValid)
          }
          onClick={() => saveTaskEditorScript(codeTaskValue.code)}
          disabled={sendingData || dataSentSuccessfully || errors}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Сохранить'}
        </button>
      </div>
    </div>
  );
}
