import { ComponentsLogs } from '../../hoc/hoc.mjs';
import { LogsPreload } from '../components.mjs';

import styles from './eventLogsList.module.scss';

const LogsPreloadList = () => {
  return [0, 1, 2].map((row) => {
    return <LogsPreload key={row} />;
  });
};

export default function EventLogsList(props) {
  const {} = props;
  const componentLogsView = true;

  return (
    <div className={styles.eventLogsList}>
      <div className={styles.controlsEventLogsListPanel}>settings</div>

      <div className={styles.eventLogsListContainer}>
        <div className={styles.eventLogsListWrap}>
          {componentLogsView ? (
            <>
              <ComponentsLogs />
              <ComponentsLogs />
              <ComponentsLogs />
              <ComponentsLogs />
            </>
          ) : (
            <LogsPreloadList />
          )}
        </div>
      </div>
    </div>
  );
}
