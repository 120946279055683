import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiCheck } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

import { CreateNewObjectService } from '../../../../services/services.mjs';
import { useKernel } from '../../../../context/ContextKernel.mjs';
import { InputNumber, InputText, LoadingInUserPageForm } from '../../../components.mjs';
import { sampleTypes } from '../../model/dataFromElements.mjs';
import { getSamplesName } from '../../model/helpers.mjs';
import { createValidationSchemeByInputNumber, createValidationSchemeByInputText } from '../../model/validationScheme.mjs';
import { useActiveConfigurationId } from '../../../../hooks/useActiveConfigurationId.mjs';

import styles from './creatingTemplateParametersForm.module.scss';

export function CreatingTemplateParametersForm(props) {
  const { setComponentsTypeId, setConfiguration, setIsError, setErrorMessage, selectedParameter } = props;

  const location = useLocation();
  const [sampleType, setSampleType] = useState(1);
  const [sendingData, setSendingData] = useState(false);
  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [showTooltip, setShowTooltip] = useState({
    nameTag: false,
    nameContainerSystemStructure: false,
    driverAddress: false,
  });
  const { activeConfigurationId } = useActiveConfigurationId();
  const { kernel } = useKernel();

  const {
    register,
    unregister,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const handleFocus = useCallback(
    ({ target }) => {
      const name = target.name;
      setShowTooltip({
        ...showTooltip,
        [name]: true,
      });
    },
    [showTooltip],
  );
  const handleBlur = useCallback(
    ({ target }) => {
      const name = target.name;
      setShowTooltip({
        ...showTooltip,
        [name]: false,
      });
    },
    [showTooltip],
  );

  const handleChangeSampleType = ({ target }) => {
    setSampleType(target.value);
    setValue('sampleType', target.value);
  };

  const onSubmit = async (data) => {
    setSendingData(true);
    try {
      const parentId = selectedParameter.id.toString();
      data.parentId = parentId;
      await CreateNewObjectService.createNewParameterUsingTemplate(activeConfigurationId, data);

      kernel.unloadConfiguration();
      const { tree } = await kernel.buildTree(location);

      setSendingData(false);
      setDataSentSuccessfully(true);

      //строим дерево
      setConfiguration(tree);

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setComponentsTypeId(0);
      }, 1200);
    } catch (error) {
      setIsError(true);
      setSendingData(false);
      const errorMessage = error.toString().split('Error: ')[1];
      setErrorMessage(errorMessage);
      console.warn('error: ', error);
    }
  };

  useEffect(() => {
    setValue('sampleType', 1);
    return () => {};
  }, []);

  useEffect(() => {
    setValue('nameTag', getSamplesName(sampleType));
    setValue('nameContainerSystemStructure', getSamplesName(sampleType));
    trigger(['nameTag', 'nameContainerSystemStructure', 'driverAddress']);

    return () => {};
  }, [sampleType]);

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>
        <span className={styles.titleText}>Добавление нового параметра в :</span>
        <span className={classNames(styles.titleText, styles.titleTextAccent)}>{selectedParameter?.displayName}</span>
      </div>
      <div className={styles.formWrap}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.formItem}>
            <span className={styles.formItemText}>Выберите шаблон параметра:</span>
            <div className={styles.itemContainer}>
              <select
                value={sampleType}
                onInput={handleChangeSampleType}
                className={classNames(styles.select, sendingData && styles.sending)}
                disabled={sendingData}
                {...register('sampleType')}
              >
                {sampleTypes.map((sampleType) => {
                  return (
                    <option value={sampleType.value} className={styles.selectOption}>
                      {sampleType.text}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className={styles.formItem}>
            <span className={styles.formItemText}>Наименование контейнера:</span>
            <div className={styles.itemContainer}>
              <InputText
                value={getSamplesName(sampleType)}
                setValue={setValue}
                placeholder="Наименование контейнера"
                canEdit={false}
                isWritable={true}
                isDeleted={false}
                register={register}
                unregister={unregister}
                name="nameContainerSystemStructure"
                handleFocus={handleFocus}
                showTooltip={showTooltip.nameContainerSystemStructure}
                errors={errors}
                validationScheme={createValidationSchemeByInputText(handleBlur, 255)}
                setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
                sendingData={sendingData}
              />
            </div>
          </div>
          <div className={styles.formItem}>
            <span className={styles.formItemText}>Наименование параметра:</span>
            <div className={styles.itemContainer}>
              <InputText
                value={getSamplesName(sampleType)}
                setValue={setValue}
                placeholder="Наименование параметра"
                canEdit={false}
                isWritable={true}
                isDeleted={false}
                register={register}
                unregister={unregister}
                name="nameTag"
                handleFocus={handleFocus}
                showTooltip={showTooltip.nameTag}
                errors={errors}
                validationScheme={createValidationSchemeByInputText(handleBlur)}
                setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
                sendingData={sendingData}
              />
            </div>
          </div>

          <div className={styles.formItem}>
            <span className={styles.formItemText}>Введите драйвер адрес параметра:</span>
            <div className={styles.itemContainer}>
              <InputNumber
                value={363}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
                placeholder="Драйвер адрес"
                canEdit={false}
                isWritable={true}
                isDeleted={false}
                min={0}
                max={51699}
                step={1}
                register={register}
                unregister={unregister}
                name="driverAddress"
                handleFocus={handleFocus}
                showTooltip={showTooltip.driverAddress}
                errors={errors}
                validationScheme={createValidationSchemeByInputNumber(handleBlur)}
                setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
                sendingData={sendingData}
              />
            </div>
          </div>

          <button
            className={
              sendingData || dataSentSuccessfully
                ? styles.formSubmitSending
                : isValid
                ? classNames(styles.formSubmit, styles.formValid)
                : classNames(styles.formSubmit, styles.formNoValid)
            }
            disabled={!isValid}
          >
            {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Создать'}
          </button>
        </form>
      </div>
    </div>
  );
}
