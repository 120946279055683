import { SystemTypesListStruct } from '../quantum-lib/Scada/Parser/Configurations/SystemTypesListStruct.mjs';
import { BlockTypesListStruct } from '../quantum-lib/Scada/Parser/Configurations/BlockTypesListStruct.mjs';
import { EditorSettingsListStruct } from '../quantum-lib/Arm/Parser/Configurations/EditorSettingsListStruct.mjs';

import { setWorkingMode } from './services.mjs';

export class ReferenceBooksService {
  static async systemTypesList() {
    return fetch(`${setWorkingMode()}/system-types-list`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const systemTypesListStruct = new SystemTypesListStruct();
        systemTypesListStruct.readFrom(dataView, offset, isLittleEndian);

        return systemTypesListStruct;
      });
  }

  static async blockTypesList() {
    return fetch(`${setWorkingMode()}/block-types-list`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const blockTypesListStruct = new BlockTypesListStruct();
        blockTypesListStruct.readFrom(dataView, offset, isLittleEndian);

        return blockTypesListStruct;
      });
  }

  static async editorSettingsList() {
    return fetch(`${setWorkingMode()}/get-editor-settings-list`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const editorSettingsListStruct = new EditorSettingsListStruct();
        editorSettingsListStruct.readFrom(dataView, offset, isLittleEndian);

        return editorSettingsListStruct;
      });
  }
}
