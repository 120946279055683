import { Parameter } from "../../Scada/Parameters/Parameter.mjs";

const ACCESS_DENIED = 1;
const ACCESS_READ = 2;
const ACCESS_ADD = 4;
const ACCESS_WRITE = 8;
const ACCESS_DELETE = 16;
const ACCESS_FULL = 32;
const ACCESS_ACK = 64;


class ParametersExtended extends Parameter {

  constructor(kernel, parameterStruct, disableTransforms = false) {
    super(kernel, parameterStruct, disableTransforms);
    this.canEdit = false;
    this.canViewParameter = true;
    this.isDeletedObject = false;
  }

  get propertyValueRoundUp() {
    if (this.systemType.type === 9) {
      return this.propertyValue.toFixed(2);
    }
    return this.propertyValue;
  }

  // отказ в доступе
  get hasAccess() {
    return this.parameterStruct.accessBits & ACCESS_DENIED
      ? false : true;
  }

  // права на чтение
  get isReadable() {
    return this.parameterStruct.accessBits & ACCESS_READ && this.hasAccess
      ? true : false;
  }

  // права на добавление
  get canAdd() {
    return this.parameterStruct.accessBits & ACCESS_ADD && this.hasAccess
      ? true : false;
  }

  // права на запись (модификации)
  get isWritable() {
    return this.parameterStruct.accessBits & ACCESS_WRITE && this.hasAccess
      ? true : false;
  }

  // права на удаление
  get canDelete() {
    return this.parameterStruct.accessBits & ACCESS_DELETE && this.hasAccess
      ? true : false;
  }

  // root права но не на квитирование
  get canEditAccess() {
    return this.parameterStruct.accessBits & ACCESS_FULL && this.hasAccess
      ? true : false;
  }

  // права на квитирование
  get isAcknowledgeable() {
    return this.parameterStruct.accessBits & ACCESS_ACK && this.hasAccess
      ? true : false;
  }

  get isDeleted() {
    return this.isDeletedObject;
  }

}

export { ParametersExtended };
