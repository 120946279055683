import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { GrTree } from 'react-icons/gr';
import { HiCheck } from 'react-icons/hi';

import { LoadingInUserPageForm, SettlementServerPopUp } from '../../../components.mjs';
import { SystemTypeSelectionPopUp, Terminal, BlockTypeSelectionPopUp } from '../index.mjs';

import styles from './serverOperationSettings.module.scss';

export function ServerOperationSettings(props) {
  const {
    codeEditorStateActiveTask,
    sendingData,
    dataSentSuccessfully,
    saveSetting,
    configuration,
    editorServerOperation,
    setContainer,
    view,
    codeEditorValue,
    setCodeEditorValue,
    errors,
    treeTab,
  } = props;
  const [visible, setVisible] = useState({ configurationTreePopUp: false, systemTypePopUp: false, blockTypePopUp: false });
  const [activeElementTree, setActiveElementTree] = useState(0);
  const [systemTypeId, setSystemTypeId] = useState(1);
  const [blockTypeId, setBlockTypeId] = useState(1);

  const insertParameterIdInEditor = () => {
    const { from, to } = view.state.selection.ranges[0];

    const parameterId = activeElementTree.toString();
    view.dispatch({
      changes: {
        from,
        to,
        insert: `$${parameterId}`,
      },
      selection: { anchor: from + parameterId.length + 1 },
    });
    view.focus();

    setVisible({
      ...visible,
      configurationTreePopUp: false,
    });
    setActiveElementTree(0);
  };

  const insertSystemTypeInEditor = () => {
    const { from, to } = view.state.selection.ranges[0];

    view.dispatch({
      changes: {
        from,
        to,
        insert: `@${systemTypeId}`,
      },
      selection: { anchor: from + systemTypeId.toString().length + 1 },
    });
    view.focus();

    setVisible({
      ...visible,
      systemTypePopUp: false,
    });
    setSystemTypeId(1);
  };

  const insertTypeBlockInEditor = () => {
    const { from, to } = view.state.selection.ranges[0];

    view.dispatch({
      changes: {
        from,
        to,
        insert: `#${blockTypeId}`,
      },
      selection: { anchor: from + blockTypeId.toString().length + 1 },
    });
    view.focus();

    setVisible({
      ...visible,
      blockTypePopUp: false,
    });
    setBlockTypeId(1);
  };

  const closePopUp = () => {
    setVisible({ configurationTreePopUp: false, systemTypePopUp: false, blockTypePopUp: false });
    setActiveElementTree(0);
  };

  useEffect(() => {
    const strValue = codeEditorStateActiveTask?.workParameters ?? '';
    setCodeEditorValue({ code: strValue, codeLength: strValue.length });

    return () => {};
  }, [codeEditorStateActiveTask.id]);

  useEffect(() => {
    if (editorServerOperation.current) {
      setContainer(editorServerOperation.current);
    }
    return () => {};
  }, [editorServerOperation.current]);

  return (
    <div className={styles.codeEditorContainer}>
      {visible.configurationTreePopUp && (
        <SettlementServerPopUp
          configuration={configuration}
          activeElementTree={activeElementTree}
          setActiveElementTree={setActiveElementTree}
          closePopUp={closePopUp}
          callback={insertParameterIdInEditor}
        />
      )}
      {visible.systemTypePopUp && (
        <SystemTypeSelectionPopUp
          visible={visible}
          setVisible={setVisible}
          systemTypeId={systemTypeId}
          setSystemTypeId={setSystemTypeId}
          callback={insertSystemTypeInEditor}
        />
      )}
      {visible.blockTypePopUp && (
        <BlockTypeSelectionPopUp
          visible={visible}
          setVisible={setVisible}
          blockTypeId={blockTypeId}
          setBlockTypeId={setBlockTypeId}
          callback={insertTypeBlockInEditor}
        />
      )}

      <div className={styles.serverSettingsContainer}>
        <div className={styles.codeEditorHeader}>
          <span className={styles.codeEditorHeaderText}>
            <strong
              className={classNames(
                styles.codeEditorHeaderTextActive,
                codeEditorValue.codeLength > 1000 && styles.codeEditorHeaderTextWarningBorder,
                codeEditorValue.codeLength >= 1275 && styles.codeEditorHeaderTextErrorBorder,
              )}
            >
              {codeEditorValue.codeLength}
            </strong>
            /1275
          </span>
        </div>

        <div className={styles.editorWrap}>
          <div className={styles.editor} ref={editorServerOperation}></div>
          {treeTab === 2 && (
            <>
              <div className={styles.showSystemType} onClick={() => setVisible({ ...visible, systemTypePopUp: true })} title="Вставить системный тип">
                <span>@</span>
              </div>
              <div className={styles.showTypeBlock} onClick={() => setVisible({ ...visible, blockTypePopUp: true })} title="Вставить тип блока">
                <span>#</span>
              </div>
            </>
          )}

          <div
            className={styles.showParameterTree}
            onClick={() => setVisible({ ...visible, configurationTreePopUp: true })}
            title="Вставить id параметра"
          >
            <GrTree className={styles.showParameterTreeIcon} />
          </div>
        </div>
        <Terminal errors={errors} />
      </div>

      <div className={styles.controlContainer}>
        <button
          className={
            sendingData || dataSentSuccessfully
              ? styles.creatingNewModuleFormSubmitSending
              : classNames(styles.creatingNewModuleFormSubmit, errors.status ? styles.formNoValid : styles.formValid)
          }
          onClick={() => saveSetting(codeEditorValue.code)}
          disabled={sendingData || dataSentSuccessfully || errors.status}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Сохранить'}
        </button>
      </div>
    </div>
  );
}
