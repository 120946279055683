import { SettlementServerSetupListStruct } from '../quantum-lib/Arm/Parser/Configurations/SettlementServerSetupListStruct.mjs';
import { SettlementServerSetupStruct } from '../quantum-lib/Arm/Parser/Configurations/SettlementServerSetupStruct.mjs';

import { setWorkingMode } from './services.mjs';


export class SettlementServerSetupService {

  static async getSettlementServerSetupList(idConfiguration) {
    return fetch(`${setWorkingMode()}/get-settlement-server-setup-list/${idConfiguration}`, {
      method: 'get',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const settlementServerSetupList = new SettlementServerSetupListStruct();
        settlementServerSetupList.readFrom(dataView, offset, isLittleEndian);

        return settlementServerSetupList;
      });
  }

  static async addTask(idConfiguration, parameterId, nameTask) {
    return fetch(`${setWorkingMode()}/add-task/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ parameterId, nameTask }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const settlementServerSetup = new SettlementServerSetupStruct();
        settlementServerSetup.readFrom(dataView, offset, isLittleEndian);

        return settlementServerSetup;
      });
  }

  static async createTemplatesTask(idConfiguration, data) {
    return fetch(`${setWorkingMode()}/add-templates-task/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const settlementServerSetup = new SettlementServerSetupStruct();
        settlementServerSetup.readFrom(dataView, offset, isLittleEndian);

        return settlementServerSetup;
      });
  }

  static async changeTaskLaunchParameters(idConfiguration, taskId, launchParameters) {
    return fetch(`${setWorkingMode()}/change-task-launch-parameters/${idConfiguration}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ taskId, launchParameters }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const settlementServerSetup = new SettlementServerSetupStruct();
        settlementServerSetup.readFrom(dataView, offset, isLittleEndian);

        return settlementServerSetup;
      });
  }

  static async changeTaskWorkParameters(idConfiguration, taskId, workParameters) {
    return fetch(`${setWorkingMode()}/change-task-work-parameters/${idConfiguration}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ taskId, workParameters }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const settlementServerSetup = new SettlementServerSetupStruct();
        settlementServerSetup.readFrom(dataView, offset, isLittleEndian);

        return settlementServerSetup;
      });
  }

  static async changeTaskGeneralSettings(idConfiguration, params) {
    return fetch(`${setWorkingMode()}/change-task-general-settings/${idConfiguration}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const settlementServerSetup = new SettlementServerSetupStruct();
        settlementServerSetup.readFrom(dataView, offset, isLittleEndian);

        return settlementServerSetup;
      });
  }

  static async getSrvCalcDictionary() {
    const response = await fetch(`${setWorkingMode()}/get-srv-calc-dictionary`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }
}