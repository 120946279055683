import { BsArrowLeftCircle } from 'react-icons/bs';
import classNames from 'classnames';

import TreeSettlementServer from '../tree-settlement-server/TreeSettlementServer';
import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './settlementServerPopUp.module.scss';

const PARAMETER_CLASS_VALUE = 2;

export default function SettlementServerPopUp(props) {
  const { configuration, activeElementTree, setActiveElementTree, closePopUp, callback, propertyVisibleInTree, parameterDisabled = false } = props;
  const { kernel } = useKernel();

  const setStyleParameterDisabled = () => {
    if (parameterDisabled) {
      const parameterStruct = kernel.getParameterById(activeElementTree);
      const classify = parameterStruct.classify();

      return classify === PARAMETER_CLASS_VALUE ? [0, 11, 12, 13, 14, 15, 16].includes(parameterStruct.typeNode.systemType.type) : true;
    }
    return false;
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>Выберете нужный параметр</h2>
          <BsArrowLeftCircle className={styles.buttonBack} onClick={closePopUp} />
        </div>
        <div className={styles.treeContainer}>
          <TreeSettlementServer
            configuration={configuration}
            activeElementTree={activeElementTree}
            setActiveElementTree={setActiveElementTree}
            propertyVisible={propertyVisibleInTree}
            parameterDisabled={parameterDisabled}
          />
        </div>
        <div className={styles.control}>
          <button
            className={classNames(styles.controlButton, setStyleParameterDisabled() && styles.disabled)}
            onClick={callback}
            disabled={setStyleParameterDisabled()}
          >
            Выбрать
          </button>
        </div>
      </div>
    </div>
  );
}
