import { useEffect, useState } from 'react';
import { BsArrowLeftCircle } from 'react-icons/bs';

import { PropertyHistoryTableRow, PropertyHistoryTableLoader } from '../components.mjs';
import { PropertyHistoryService, UsersService } from '../../services/services.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './propertyHistory.module.scss';

const PropertyHistoryTableLoaderList = () => {
  return [1, 2, 3, 4, 5, 6, 7].map((row) => {
    return <PropertyHistoryTableLoader key={row} />;
  });
};

export default function PropertyHistory(props) {
  const { setIsError, setErrorMessage, property, setComponentsTypeId } = props;

  const { kernel } = useKernel();
  const [propertyHistoryList, setPropertyHistoryList] = useState([]);

  const getPropertyHistoryListStruct = async () => {
    const parametrId = parseInt(property?.id);
    const systemTypeId = property?.systemTypeId;
    const systemTypeType = property?.systemType.type;
    const idConfiguration = kernel.getActiveConfiguration()?.id;

    const propertyHistoryListStruct = await PropertyHistoryService.propertyHistoryList(parametrId, systemTypeId, systemTypeType, idConfiguration);

    return propertyHistoryListStruct;
  };

  const getUsersListStruct = async () => {
    return await UsersService.usersListStruct();
  };

  const buildPropertyHistoryTable = async () => {
    Promise.all([getPropertyHistoryListStruct(), getUsersListStruct()])
      .then((values) => {
        const [propertyHistoryListStruct, usersListStruct] = [...values];
        const parametrId = parseInt(property?.id);

        kernel.buildPropertyHistoryTableList(propertyHistoryListStruct.items, usersListStruct.items, parametrId);
        const propertyHistoryList = kernel.getPropertyHistoryList();

        setTimeout(() => {
          setPropertyHistoryList(propertyHistoryList);
        }, 300);
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage(error.message);
      });
  };

  useEffect(() => {
    buildPropertyHistoryTable();
    return () => {};
  }, []);

  const goBack = () => {
    setComponentsTypeId(0);
  };

  return (
    <div className={styles.propertyHistory}>
      <div className={styles.propertyHistoryHeader}>
        <div className={styles.propertyHistoryTitleContainer}>
          <h3 className={styles.propertyHistoryTitle} title={`История изменения параметра: ${property?.description}`}>
            История изменения параметра: {property?.description}
          </h3>
        </div>
        <BsArrowLeftCircle className={styles.propertyHistoryButtonBack} onClick={goBack} />
      </div>
      <div className={styles.propertyWrapTable}>
        <table className={styles.table}>
          <thead className={styles.tableThead}>
            <tr className={styles.tableTr}>
              <th className={styles.tableTh}>Имя пользователя</th>
              <th className={styles.tableTh}>Значения</th>
              <th className={styles.tableTh}>Дата и время</th>
            </tr>
          </thead>
          <tbody className={styles.tableTbody}>
            {propertyHistoryList.length > 0 ? (
              propertyHistoryList.map((propertyHistory) => {
                return <PropertyHistoryTableRow key={propertyHistory.id} propertyHistory={propertyHistory} />;
              })
            ) : (
              <PropertyHistoryTableLoaderList />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
