import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';

import { CustomSelectOptionList, ValueItemsList } from './ui/index.mjs';
import { sortOption } from './model/sortOption.mjs';
import { searchOption } from './model/searchOption.mjs';
import styles from './customSelect.module.scss';

export default function CustomSelect(props) {
  const { value, setValue, register, unregister, name, disabled, options = [], className = '', isMulti = false, sortKey = 'label' } = props;

  const sortedOptions = sortOption(options, sortKey);
  const [isOpen, setIsOpen] = useState(false);
  const [inFocus, setInFocus] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [optionsList, setOptionsList] = useState(sortedOptions);
  const [inputState, setInputState] = useState({ value: '', size: '16px' });
  const initialValue = useRef(value);
  const inputRef = useRef();
  const optionsListRef = useRef();

  const handleBlur = () => {
    const input = inputRef.current;
    input.blur();
  };

  const handleFocus = () => {
    const input = inputRef.current;
    input.focus();
  };

  const clearItemsList = () => {
    setOptionsList(options);
    setItemsList([]);
    setInputState({ value: '', size: '16px' });
  };

  const handleChange = ({ target: { value } }) => {
    const clientWidth = window.innerWidth;

    const size = clientWidth > 1400 ? `${(value.length + 1) * 9}px` : `${(value.length + 1) * 8}px`;
    setInputState({
      value,
      size,
    });
    if (isMulti) {
      // доработать
    } else {
      const newOptionsList = searchOption(options, value);
      setOptionsList(newOptionsList);
    }
  };

  useEffect(() => {
    optionsListRef.current = sortedOptions;
    setValue(name, value);
    return () => {
      unregister([name]);
    };
  }, []);

  useEffect(() => {
    initialValue.current = value;
    return () => {};
  }, [value]);

  // useEffect(() => {
  //   console.log('inFocus: ', inFocus);
  //   if (inFocus) {
  //     handleFocus();
  //   }else{
  //     handleBlur();
  //   }
  //   return () => {};
  // }, [inFocus]);

  useEffect(() => {
    // console.log('isOpen', isOpen);

    return () => {};
  }, [isOpen]);

  return (
    <div className={styles.container}>
      <div
        className={styles.valueContainer}
        onClick={() => {
          setInFocus(true);
          handleFocus();
          setIsOpen(true);
        }}
      >
        <ValueItemsList
          optionsList={optionsList}
          itemsList={itemsList}
          isOpen={isOpen}
          isMulti={isMulti}
          setItemsList={setItemsList}
          setOptionsList={setOptionsList}
          setIsOpen={setIsOpen}
          sortKey={sortKey}
          inputState={inputState}
          optionsListRef={optionsListRef}
        />

        <input
          type="text"
          autocapitalize="none"
          autocomplete="off"
          autocorrect="off"
          className={styles.textField}
          style={{ width: inputState.size }}
          ref={inputRef}
          onChange={handleChange}
          value={inputState.value}
        />
      </div>
      <div className={styles.controls}>
        <div className={styles.clear}>
          <RxCross2 className={classNames(styles.icon, styles.clearIcon)} onClick={clearItemsList} />
        </div>
        <div
          className={styles.dropDown}
          onClick={() => {
            // setInFocus(true);
            setIsOpen((prev) => !prev);
          }}
        >
          <IoIosArrowDown className={classNames(styles.icon, styles.dropDownIcon)} />
        </div>
      </div>
      <CustomSelectOptionList
        optionsList={optionsList}
        itemsList={itemsList}
        isOpen={isOpen}
        isMulti={isMulti}
        setItemsList={setItemsList}
        setOptionsList={setOptionsList}
        setIsOpen={setIsOpen}
        optionsListRef={optionsListRef}
        setInputState={setInputState}
      />
    </div>
  );
}

CustomSelect.propTypes = {};
