import { useEffect, useRef } from 'react';
import classNames from 'classnames';

import styles from './contextMenu.module.scss';

export default function ContextMenu(props) {
  const { contextMenuState } = props;
  const contextData = contextMenuState.contextData;
  const ref = useRef();

  useEffect(() => {
    const contextMenuHeight = ref.current.clientHeight;
    const windowHeight = window.innerHeight;
    const [x, y] = contextMenuState.position;

    if (windowHeight - (y + contextMenuHeight) < 50) {
      ref.current.style.left = `${x}px`;
      ref.current.style.top = `${y - contextMenuHeight}px`;
    } else {
      ref.current.style.left = `${x}px`;
      ref.current.style.top = `${y}px`;
    }

    return () => {};
  }, [contextMenuState]);

  return (
    <div ref={ref} className={styles.contextMenu}>
      <ul className={styles.contextMenuCommandList}>
        {contextMenuState.contextMenuItems.map((item) => {
          return item.renderElement ? (
            <li
              key={item.id}
              id={item.id}
              className={classNames(styles.contextMenuCommandListItem, item.isActive && styles.contextMenuCommandListItemActive)}
              onClick={item.onClick}
            >
              {item.text}
            </li>
          ) : (
            <></>
          );
        })}
      </ul>
    </div>
  );
}
