import { Struct } from '../Struct.mjs'
import { StringStruct } from '../StringStruct.mjs'

class RequestSetParameterValueDataStruct extends Struct {

    constructor(parameterId = 0n, timestamp = 0, rawValue = 0n, strValue = new StringStruct('')) {
        super()
        this.parameterId = parameterId
        this.timestamp = timestamp
        this.rawValue = rawValue
        this.strValue = strValue
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        this.parameterId = dataView.getBigUint64(endOffset, isLittleEndian)
        endOffset += 8
        this.timestamp = dataView.getUint32(endOffset, isLittleEndian)
        endOffset += 4
        this.rawValue = dataView.getBigUint64(endOffset, isLittleEndian)
        endOffset += 8
        endOffset = this.strValue.readFrom(dataView, endOffset, isLittleEndian)
        return endOffset
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        dataView.setBigUint64(endOffset, this.parameterId, isLittleEndian)
        endOffset += 8
        dataView.setUint32(endOffset, this.timestamp, isLittleEndian)
        endOffset += 4
        dataView.setBigUint64(endOffset, this.rawValue, isLittleEndian)
        endOffset += 8
        endOffset = this.strValue.writeTo(dataView, endOffset, isLittleEndian)
        return endOffset
    }

    getCurrentSize() {
        return 0
            + 8 // parameterId
            + 4 // timestamp
            + 8 // rawValue
            + this.strValue.getCurrentSize() // strValue
    }

    clone() {
        let clone = new RequestSetParameterValueDataStruct(
            this.parameterId,
            this.timestamp,
            this.rawValue,
            this.strValue.clone()
        )
        clone.setParserOptions(this.getParserOptions())
        return clone
    }
}

export {
    
    RequestSetParameterValueDataStruct
}
