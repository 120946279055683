import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { HiCheck } from 'react-icons/hi';
import { FaCopy } from 'react-icons/fa';

import { useKernel } from '../../context/ContextKernel.mjs';
import { LicensesService } from '../../services/services.mjs';
import { LoadingInUserPageForm } from '../components.mjs';
import Utils from '../../utils/Utils.mjs';
import { Base64 } from '../../quantum-lib/Scada/Parser/Base64.mjs';

import styles from './editingLicenses.module.scss';

export default function EditingLicenses(props) {
  const { setIsError, setErrorMessage, licensesTree, setLicensesTree, setComponentsTypeId, idTreeElement } = props;

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const { kernel } = useKernel();
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [openLicenseFieldValue, setOpenLicenseFieldValue] = useState('');
  const [privateLicenseFieldValue, setPrivateLicenseFieldValue] = useState('');
  const [nameLicenseInputValue, setNameLicenseInputValue] = useState('');
  const [workingStatus, setWorkingStatus] = useState();
  const [copyButton, setCopyButton] = useState(false);
  const [downloadButton, setDownloadButton] = useState(false);

  const changedOpenLicense = useRef(false);
  const changedNameLicense = useRef(false);
  const changedWorkingStatusLicense = useRef(false);

  useEffect(() => {
    const licenses = kernel.getLicenseById(idTreeElement);
    setPrivateLicenseFieldValue(licenses.privateContent);

    setValue('openLicenseField', licenses.publicContent);
    trigger('openLicenseField');
    setOpenLicenseFieldValue(licenses.publicContent);

    setValue('nameLicense', licenses.name);
    trigger('nameLicense');
    setNameLicenseInputValue(licenses.name);

    setValue('workingStatus', licenses.statusState);
    setWorkingStatus(licenses.statusState);

    return () => {};
  }, [idTreeElement]);

  const handleChangeLicenseFieldValue = (event) => {
    setOpenLicenseFieldValue(event.target.value);
    if (event.target.value !== kernel.getLicenseById(idTreeElement).publicContent) {
      changedOpenLicense.current = true;
    } else {
      changedOpenLicense.current = false;
    }
  };

  const handleChangeNameLicenseInputValue = (event) => {
    setNameLicenseInputValue(event.target.value);
    if (event.target.value !== kernel.getLicenseById(idTreeElement).name) {
      changedNameLicense.current = true;
    } else {
      changedNameLicense.current = false;
    }
  };

  const handleChangeWorkingStatus = (event) => {
    setWorkingStatus(event.target.value);
    if (event.target.value !== kernel.getLicenseById(idTreeElement).statusState) {
      changedWorkingStatusLicense.current = true;
    } else {
      changedWorkingStatusLicense.current = false;
    }
  };

  const buttonBackClick = () => {
    setComponentsTypeId(0);
  };

  const copyUuid = () => {
    navigator.clipboard
      .writeText(kernel.getLicenseById(idTreeElement)?.uuid)
      .then(() => {
        setCopyButton(true);
        setTimeout(() => {
          setCopyButton(false);
        }, 650);
      })
      .catch((error) => {
        setCopyButton(false);
        console.error('Ошибка при копировании!', error);
      });
  };

  const createFileLog = (content, name) => {
    const blob = new Blob([content], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    const fileName = `${name}.lic`;
    link.download = fileName;
    link.click();
  };

  const downloadLicense = () => {
    setDownloadButton(true);
    try {
      const licenseStruct = kernel.getLicenseById(idTreeElement);
      const { id: licenseUuid, name, dataBlockType, closeSign, licType, privateContent, publicContent } = licenseStruct.licenseStruct;

      const resultStr = `${licenseUuid}\n${licType}\n${dataBlockType}\n${publicContent.value}\n${privateContent.value}\n${closeSign.value}\n${name.value}`;

      createFileLog(resultStr, name.value);

      setTimeout(() => {
        setDownloadButton(false);
      }, 650);
    } catch (error) {}
  };

  const onSubmit = async (data) => {
    try {
      setSendingData(true);

      const license = kernel.getLicenseById(idTreeElement);

      //шифрование
      const base64Str = Base64.utf8StringToBase64String(data.openLicenseField);
      const buffer = new Uint8Array(Base64.base64StringToArrayBuffer(base64Str));
      const bufferReverse = buffer.reverse();
      const newBase64Str = Base64.arrayBufferToBase64String(bufferReverse);
      const result = Utils.reverseStr(newBase64Str);

      const response = await LicensesService.updatingExistingLicense({
        licType: license.licType,
        dataBlockType: license.dataBlockType,
        closeSign: license.licenseStruct.closeSign.value,
        publicContent: result,
        privateContent: license.licenseStruct.privateContent.value,
        uuid: license.uuid,
        name: data.nameLicense,
        statusState: data.workingStatus,
      });
      const newLicense = kernel.changingLicensesInLicensesTree(response); //обновление данных в kernel

      setTimeout(() => {
        //обновление состояния
        setSendingData(false);
        setDataSentSuccessfully(true);
        setLicensesTree(
          licensesTree.map((licenseType) => {
            if (licenseType.id === newLicense.id) {
              licenseType.children.map((license) => {
                if (license.id === newLicense.id) {
                  return {
                    ...newLicense,
                  };
                }
                return license;
              });
            }
            return licenseType;
          }),
        );
      }, 350);

      setTimeout(() => {
        //демонстрация пользователю что сохранение прошло успешно
        setDataSentSuccessfully(false);
        changedOpenLicense.current = false;
        changedNameLicense.current = false;
        changedWorkingStatusLicense.current = false;
      }, 1300);
    } catch (error) {
      setSendingData(false);
      setIsError(true);
      const errorMessage = error.toString().split('Error: ')[1];
      setErrorMessage(errorMessage);
      console.warn('error: ', error);
    }
  };

  return (
    <div className={styles.editingLicenses}>
      <BsArrowLeftCircle className={styles.buttonBack} onClick={buttonBackClick} />
      <div className={styles.editingLicensesContainer}>
        <div className={styles.row}>
          <h2 className={styles.title}>Имя экземпляра приложения</h2>
          <div className={styles.fieldContainer}>
            <input
              type="text"
              className={classNames(styles.inputText, errors.nameLicense && styles.inputTextError, sendingData && styles.sending)}
              placeholder="Название"
              name="nameLicense"
              onInput={handleChangeNameLicenseInputValue}
              readOnly={sendingData}
              value={nameLicenseInputValue}
              form={kernel.getLicenseById(idTreeElement)?.uuid}
              {...register('nameLicense', {
                required: 'Поле обязательно к заполнению',
                minLength: {
                  value: 3,
                  message: 'Поле должно содержать минимум 3 символа',
                },
                maxLength: {
                  value: 255,
                  message: 'Поле может содержать максимум 255 символа',
                },
                validate: {
                  checkLicensesName: async (event) => {
                    try {
                      const name = getValues('nameLicense');
                      const licenses = kernel.getLicenseById(idTreeElement);
                      const uuid = licenses.uuid;
                      const checkLicensesName = await LicensesService.checkLicensesName(name, uuid);

                      if (checkLicensesName) {
                        return true;
                      } else {
                        return 'Лицензия с таким именем уже существует!';
                      }
                    } catch (error) {
                      const errorMessage = error.toString().split('Error: ')[1];
                      return errorMessage;
                    }
                  },
                },
              })}
            />
            {errors?.nameLicense && <p className={styles.errorMessage}>{errors?.nameLicense?.message || 'Ошибка заполнения!'}</p>}
          </div>
        </div>

        <div className={styles.row}>
          <h2 className={styles.title}>Идентификатор лицензии</h2>
          <div className={styles.fieldContainer}>
            <input
              type="text"
              className={classNames(styles.inputText, styles.sending)}
              placeholder="Название"
              name="uuidLicense"
              readOnly={true}
              value={kernel.getLicenseById(idTreeElement)?.uuid}
            />
            <div className={styles.iconCopyContainer}>
              {!copyButton ? (
                <FaCopy className={styles.copyButton} onClick={copyUuid} title="Копировать Uuid" />
              ) : (
                <HiCheck className={styles.checkControl} />
              )}
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <h2 className={styles.title}>Статус</h2>
          <select
            className={classNames(styles.workingStatusSelect, sendingData && styles.sending)}
            value={workingStatus}
            onInput={handleChangeWorkingStatus}
            {...register('workingStatus')}
            readOnly={sendingData}
            form={kernel.getLicenseById(idTreeElement)?.uuid}
          >
            <option className={styles.workingStatusOption} value="0">
              Выключен
            </option>
            <option className={styles.workingStatusOption} value="1">
              Включен
            </option>
          </select>
        </div>

        <div className={styles.row}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)} id={kernel.getLicenseById(idTreeElement)?.uuid}>
            <div className={styles.fieldContainer}>
              <h2 className={styles.title}>Открытая часть лицензии</h2>
              <textarea
                className={classNames(
                  styles.openLicenseField,
                  errors.openLicenseField && styles.openLicenseFieldError,
                  sendingData && styles.sending,
                )}
                placeholder="Открытая часть лицензии"
                name="openLicenseField"
                onInput={handleChangeLicenseFieldValue}
                readOnly={sendingData}
                value={openLicenseFieldValue}
                style={{ resize: 'vertical' }}
                {...register('openLicenseField', {
                  required: 'Поле обязательно к заполнению',
                  minLength: {
                    value: 3,
                    message: 'Поле должно содержать минимум 3 символа',
                  },
                  maxLength: {
                    value: 65025,
                    message: 'Поле может содержать максимум 65025 символа',
                  },
                })}
              />
              {errors?.openLicenseField && <p className={styles.errorMessage}>{errors?.openLicenseField?.message || 'Ошибка заполнения!'}</p>}
            </div>
          </form>
        </div>

        <div className={styles.row}>
          <h2 className={styles.title}>Закрытая часть лицензии</h2>
          <textarea
            className={styles.closedLicenseField}
            placeholder="Закрытая часть лицензии"
            readOnly={true}
            value={privateLicenseFieldValue}
            style={{ resize: 'vertical' }}
          />
        </div>
      </div>

      <div className={styles.buttonSendingContainer}>
        <button 
          className={classNames(styles.downloadLicenseButton, styles.button, styles.formValid, downloadButton && styles.buttonSending)} 
          onClick={downloadLicense}>
          {!downloadButton ? 'Скачать лицензию' : <HiCheck className={styles.checkControl} />}
        </button>

        <button
          className={
            sendingData || dataSentSuccessfully
              ? styles.buttonSending
              : isValid && (changedOpenLicense.current || changedNameLicense.current || changedWorkingStatusLicense.current)
                ? classNames(styles.button, styles.formValid)
                : classNames(styles.button, styles.formNoValid)
          }
          type="submit"
          form={kernel.getLicenseById(idTreeElement)?.uuid}
          disabled={!isValid || (!changedOpenLicense.current && !changedNameLicense.current && !changedWorkingStatusLicense.current)}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Сохранить'}
        </button>
      </div>
    </div>
  );
}
