import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { HiCheck } from 'react-icons/hi';

import Utils from '../../utils/Utils.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { SavePropertiesButtonModuleRs } from '../components.mjs';

import styles from './tableRowInterfaceSpeedModuleRs.module.scss';

export default function TableRowInterfaceSpeedModuleRs(props) {
  const { stateElementConfigurationId, selectedParameter, setIsError, setErrorMessage } = props;

  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [errorParse, setErrorParse] = useState(false);
  const [changesDate, setChangesDate] = useState({ day: '', hour: '' }); // вывод даты и времени последнего изменения свойства (выводится время драйвер адреса)
  const [state, setState] = useState(); // состояние компонента

  const initialValue = useRef();
  const dataUpdateInputModeStatus = useRef(false); // изменилось ли значение компонента состояние компонента или нет
  const { kernel } = useKernel();

  const saveProperty = async () => {
    setHandleChangeComponentTableRow(false);
    setSendingData(true);

    try {
      const newChildrenDefaultValues = Utils.setNewDefaultValue(selectedParameter, state, 'interfaceSpeed');

      await kernel.setValues([...newChildrenDefaultValues]);
      initialValue.current = state;

      setDataSentSuccessfully(true);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setSendingData(false);
      }, 1500);
    } catch (error) {
      setSendingData(false);
      setIsError(true);
      setErrorMessage('Ошибка сохранения!');
      setHandleChangeComponentTableRow(true);
    }
  };

  const handleChange = (event) => {
    setState(event.target.value);

    if (parseInt(event.target.value) === initialValue.current && !errorParse) {
      dataUpdateInputModeStatus.current = false;
    } else {
      dataUpdateInputModeStatus.current = true;
    }

    if (dataUpdateInputModeStatus.current) {
      setHandleChangeComponentTableRow(true);
      setErrorParse(false);
    } else {
      setHandleChangeComponentTableRow(false);
    }
  };

  useEffect(() => {
    // получаем значения дочерних свойств (default value)
    const defaultValueParameters = Utils.getPropertiesDefaultValueInModuleRs(selectedParameter);

    const day = Utils.convertTimestamp(defaultValueParameters[0].propertyValueTimestamp);
    const hour = Utils.convertTimestampGetHour(defaultValueParameters[0].propertyValueTimestamp);
    setChangesDate({ day: day, hour: hour });

    const defaultValues = defaultValueParameters.map((defaultValueParameter) => {
      const valueUint32 = defaultValueParameter.propertyValueRoundUp;
      return Utils.splitDefaultValue(valueUint32);
    });

    const interfaceSpeed = defaultValues[0].interfaceSpeed;
    const interfaceSpeedCheck = defaultValues.every((defaultValue) => defaultValues[0].interfaceSpeed === defaultValue.interfaceSpeed);

    if (interfaceSpeedCheck) {
      setState(interfaceSpeed);
      initialValue.current = interfaceSpeed;
    } else {
      setErrorParse(true);
      setState(10);
      initialValue.current = 10;
    }

    return () => {};
  }, [stateElementConfigurationId]);

  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableRowTd}>
        <span className={styles.tableRowText} title={'Скорость интерфейса'}>
          Скорость интерфейса
        </span>
      </td>
      <td className={styles.tableRowTd}>
        {/* компонент */}
        <div className={styles.componentContainer}>
          <select
            className={classNames(styles.selectInputMode, sendingData && styles.sending)}
            value={state}
            onInput={handleChange}
            disabled={sendingData}
          >
            {Utils.interfaceSpeed.map((interfaceSpeedItem) => {
              return (
                <option key={interfaceSpeedItem.valueOption} value={interfaceSpeedItem.valueOption} className={styles.selectInputModeOption}>
                  {interfaceSpeedItem.textOption}
                </option>
              );
            })}
            {errorParse && (
              <option key={10} value={10} className={styles.selectInputModeOption}>
                Значение не определено
              </option>
            )}
          </select>
        </div>
        {/* компонент */}
      </td>
      <td className={styles.tableRowTd}>
        <span className={classNames(styles.tableRowText, styles.tableDate)}>{changesDate.day}</span>
        <span className={styles.tableRowText}>{changesDate.hour}</span>
      </td>
      <td className={styles.tableRowTd}>
        {dataSentSuccessfully ? (
          <HiCheck className={styles.checkControl} />
        ) : (
          <SavePropertiesButtonModuleRs
            sendingData={sendingData}
            handleChangeComponentTableRow={handleChangeComponentTableRow}
            saveProperty={saveProperty}
          />
        )}
      </td>
    </tr>
  );
}
