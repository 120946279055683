import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiCheck } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

import { useKernel } from '../../context/ContextKernel.mjs';
import { CreateNewObjectService } from '../../services/services.mjs';
import { LoadingInUserPageForm } from '../components.mjs';
import Utils from '../../utils/Utils.mjs';

import styles from './creatingNewDeviceInModule.module.scss';

const getBoardAddress = (selectedParameter) => {
  const properties = selectedParameter.properties;
  const boardAddress = properties.find((property) => property.systemTypeId === 39);
  return boardAddress;
};

const min = 0; // минимальное значение адрес устройства на шине
const max = 254; // максимальное значение адрес устройства на шине
const step = 1; // шаг изменения значения адреса устройства на шине

export default function CreatingNewDeviceInModule(props) {
  const { setComponentsTypeId, selectedParameter, setIsError, setErrorMessage, setConfiguration } = props;

  const location = useLocation();
  const [controllerType, setControllerType] = useState(45);
  const [controllerName, setControllerName] = useState('');
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [inputNumberValue, setInputNumberValue] = useState(0);
  const { kernel } = useKernel();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });

  const updateValue = (event) => {
    if (Utils.testIsNumber(event.target.value)) {
      setInputNumberValue(event.target.value);
    }
  };

  const onSubmit = async (data) => {
    setSendingData(true);
    try {
      const boardAddressValue = getBoardAddress(selectedParameter);

      const deviceType = Utils.createdDeviceInModule.find((deviceInModule) => deviceInModule.valueOption == data.controllerType)?.deviceType || 0;

      const partsDefaultValue = {
        deviceAddressOnBus: data.deviceAddressBus, // адрес устройства на шине
        interfaceSpeed: 0,
        deviceType: deviceType,
        pipelineNumber: 0,
        reportingHour: 0,
      };

      // сформировать default value
      const defaultValue = Utils.compilationDefaultValue(partsDefaultValue);
      data.defaultValue = defaultValue;

      if (boardAddressValue.propertyValueRoundUp.toString().length === 1) {
        data.boardAddress = `0${boardAddressValue.propertyValueRoundUp}`;
      } else {
        data.boardAddress = boardAddressValue.propertyValueRoundUp;
      }

      await CreateNewObjectService.creatingNewDeviceInModule(
        kernel.getActiveConfiguration().id,
        data.controllerType, //элемент из списка
        selectedParameter.id.toString(), //id параметра в котором создают модуль
        data.controllerName, // имя устройства создаваемого внутри модуля RS
        data,
      );

      kernel.unloadConfiguration();
      const { tree } = await kernel.buildTree(location);

      setSendingData(false);
      setDataSentSuccessfully(true);

      //строим дерево
      setConfiguration(tree);

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setComponentsTypeId(0);
      }, 1200);
    } catch (error) {
      setIsError(true);
      setSendingData(false);
      const errorMessage = error.toString().split('Error: ')[1];
      setErrorMessage(errorMessage);
      console.warn('error: ', error);
    }
  };

  return (
    <div className={styles.creatingNewDevice}>
      <div className={styles.creatingNewDeviceTitle}>
        <span className={styles.creatingNewDeviceTitleText}>Добавление нового устройства в модуль RS:</span>
        <span className={classNames(styles.creatingNewDeviceTitleText, styles.creatingNewDeviceTitleTextAccent)}>
          "{selectedParameter?.displayName}"
        </span>
      </div>
      <div className={styles.creatingNewDeviceFormWrap}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.creatingNewDeviceForm}>
          <div className={styles.creatingNewDeviceFormContainer}>
            <div className={styles.creatingNewDeviceFormItem}>
              <span className={styles.creatingNewDeviceFormItemText}>Выберите тип устройства:</span>
              <select
                value={controllerType}
                onInput={(event) => setControllerType(event.target.value)}
                className={classNames(styles.creatingNewDeviceFormItemSelect, sendingData && styles.sending)}
                disabled={sendingData}
                {...register('controllerType')}
              >
                {Utils.createdDeviceInModule.map((deviceInModule, index) => {
                  return (
                    <option key={index} value={deviceInModule.valueOption} className={styles.creatingNewDeviceFormItemSelectOption}>
                      {deviceInModule.textOption}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className={styles.creatingNewDeviceFormItem}>
              <span className={styles.creatingNewDeviceFormItemText}>Укажите наименование прибора:</span>
              <div className={styles.creatingNewDeviceFormItemInputWrap}>
                <input
                  type="text"
                  className={classNames(styles.creatingNewDeviceFormItemInput, sendingData && styles.sending)}
                  placeholder="Укажите наименование прибора"
                  value={controllerName}
                  onInput={(event) => setControllerName(event.target.value)}
                  disabled={sendingData}
                  {...register('controllerName', {
                    required: 'Поле обязательно к заполнению',
                    minLength: {
                      value: 3,
                      message: 'Поле должно содержать минимум 3 символа',
                    },
                    maxLength: {
                      value: 64,
                      message: 'Поле может содержать максимум 64 символа',
                    },
                  })}
                />
                {errors?.controllerName && <p className={styles.errorMessage}>{errors?.controllerName?.message || 'Ошибка заполнения!'}</p>}
              </div>
            </div>

            <div className={styles.creatingNewDeviceFormItem}>
              <span className={styles.creatingNewDeviceFormItemText}>Адрес устройства на шине</span>
              <div className={styles.creatingNewDeviceFormItemInputWrap}>
                <div className={styles.componentContainer}>
                  <input
                    type="text"
                    placeholder="Адрес устройства на шине"
                    min={Number(min)}
                    max={Number(max)}
                    step={Number(step)}
                    value={inputNumberValue}
                    disabled={sendingData}
                    className={classNames(
                      styles.creatingNewDeviceFormItemInput,
                      errors?.deviceAddressBus && styles.inputNumberError,
                      sendingData && styles.sending,
                    )}
                    onInput={updateValue}
                    {...register('deviceAddressBus', {
                      required: 'Поле обязательно к заполнению',
                      min: {
                        value: 0,
                        message: 'Минимальное допустимое значение 0',
                      },
                      max: {
                        value: 254,
                        message: 'Максимальное допустимое значение 254',
                      },
                    })}
                  />
                </div>
                {errors?.deviceAddressBus && <p className={styles.errorMessage}>{errors?.deviceAddressBus?.message || 'Ошибка заполнения!'}</p>}
              </div>
            </div>
          </div>
          <button
            className={
              sendingData || dataSentSuccessfully
                ? styles.creatingNewDeviceFormSubmitSending
                : isValid
                  ? classNames(styles.creatingNewDeviceFormSubmit, styles.formValid)
                  : classNames(styles.creatingNewDeviceFormSubmit, styles.formNoValid)
            }
            disabled={!isValid}
          >
            {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Создать'}
          </button>
        </form>
      </div>
    </div>
  );
}
