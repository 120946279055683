import classNames from 'classnames';
import { BsPlusSquareDotted } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import { useState } from 'react';

import { useKernel } from '../../../../context/ContextKernel.mjs';

import styles from './setpointsList.module.scss';

const SetpointListItem = (props) => {
  const { setpointsStruct, callback, setpointsEditorState, deleteSetpoint } = props;

  const [hasHover, setHasHover] = useState(false);
  const { kernel } = useKernel();
  
  const parameterStruct = kernel.getParameterById(setpointsStruct.parameterId);

  return (
    <>
      <div
        key={setpointsStruct.id}
        data-id={setpointsStruct.id}
        title={setpointsStruct.name}
        className={classNames(
          styles.setpointListItem,
          setpointsEditorState.activeSetpointId === setpointsStruct.id && styles.setpointListItemActive,
          hasHover && styles.setpointListItemDelete,
        )}
        onClick={callback}
      >
        <span className={styles.setpointListItemText}>{setpointsStruct.name}</span>
        {!parameterStruct.isDeleted && (
          <MdDelete
            className={styles.deleteIcon}
            data-id={setpointsStruct.id}
            onMouseEnter={() => setHasHover(true)}
            onMouseLeave={() => setHasHover(false)}
            onClick={deleteSetpoint}
          />
        )}
      </div>
    </>
  );
};

const createText = (treeTab, isDeleted) => {
  if (treeTab === 2) return 'Нет шаблонных уставок, создайте их!';

  if (treeTab === 1 && !isDeleted) return 'Для данного параметра еще нет уставок, создайте их!';

  return 'Для удаленных объектов нельзя создавать уставки!';
};

export function SetpointsList(props) {
  const { stateElementConfigurationId, setpointsEditorState, callback, setVisiblePopup, treeTab, deleteSetpoint } = props;
  const { kernel } = useKernel();

  const parameterStruct = kernel.getParameterById(stateElementConfigurationId);

  return (
    <div className={styles.setpointList}>
      <div className={styles.setpointListContainer}>
        <div className={styles.setpointListWrap}>
          {setpointsEditorState.setpointsList.length ? (
            setpointsEditorState.setpointsList.map((setpointsStruct) => {
              return (
                <SetpointListItem
                  setpointsStruct={setpointsStruct}
                  callback={callback}
                  setpointsEditorState={setpointsEditorState}
                  deleteSetpoint={deleteSetpoint}
                />
              );
            })
          ) : (
            <span className={styles.setpointListEmptyText}>{createText(treeTab, parameterStruct.isDeleted)}</span>
          )}
        </div>
      </div>
      {!parameterStruct.isDeleted && (
        <button className={styles.setpointListButtonContainer} onClick={() => setVisiblePopup(true)} disabled={parameterStruct.isDeleted}>
          <BsPlusSquareDotted className={styles.setpointListButtonIcon} />
          <span className={styles.setpointListButtonText}>Добавить уставку</span>
        </button>
      )}
    </div>
  );
}
