// системные типы для которых реализованны уникальные компоненты 
const SYSTEM_TYPE_TIME_ZONE_CLIENT = 58;
const SYSTEM_TYPE_OBJECT_TYPE = 3;
const SYSTEM_TYPE_IPV4 = 60;
const SYSTEM_TYPE_PHONE = 37;
const SYSTEM_TYPE_REPORTING_HOUR = 40;
const SYSTEM_TYPE_REPORTING_DAY = 59;
const SYSTEM_TYPE_CHANNEL_SETTING_TYPE = 64;
const SYSTEM_TYPE_CHANNEL_SETTING_SUBTYPE = 65;
const SYSTEM_TYPE_PARAMETER_TABLE_FOOTER = 77;
const SYSTEM_TYPE_TIME_WAIT_CALL = 109;
const SYSTEM_TYPE_TIME_BETWEEN_CALL = 110;
const SYSTEM_TYPE_GPS_COORDINATES_POINT = 123;
const SYSTEM_TYPE_GPS_COORDINATES_LINE = 124;
const SYSTEM_TYPE_OPCUA_EXTERNAL_TAG = 279;

// базовые типы
const TYPE_BOOLEAN = 0;
const TYPE_BYTE = 1;
const TYPE_CHAR = 2;
const TYPE_INT16 = 3;
const TYPE_UINT16 = 4;
const TYPE_INT32 = 5;
const TYPE_UINT32 = 6;
const TYPE_INT64 = 7;
const TYPE_UINT64 = 8;
const TYPE_FLOAT = 9;
const TYPE_DOUBLE = 10;
const TYPE_TIMESTAMP = 11;
const TYPE_STRING_16 = 12;
const TYPE_STRING_64 = 13;
const TYPE_STRING_255 = 14;

const componentsBySystemTypeId = {
  [SYSTEM_TYPE_TIME_ZONE_CLIENT]: 10800,
  [SYSTEM_TYPE_OBJECT_TYPE]: 1,
  [SYSTEM_TYPE_IPV4]: "127.0.0.1",
  [SYSTEM_TYPE_PHONE]: "",
  [SYSTEM_TYPE_REPORTING_HOUR]: 24,
  [SYSTEM_TYPE_REPORTING_DAY]: 0,
  [SYSTEM_TYPE_CHANNEL_SETTING_TYPE]: 0,
  [SYSTEM_TYPE_CHANNEL_SETTING_SUBTYPE]: 0,
  [SYSTEM_TYPE_PARAMETER_TABLE_FOOTER]: 0,
  [SYSTEM_TYPE_TIME_WAIT_CALL]: 0,
  [SYSTEM_TYPE_TIME_BETWEEN_CALL]: 0,
  [SYSTEM_TYPE_GPS_COORDINATES_POINT]: "",
  [SYSTEM_TYPE_GPS_COORDINATES_LINE]: "",
  [SYSTEM_TYPE_OPCUA_EXTERNAL_TAG]: "",
}; //системные типы

const componentsBySystemTypeType = {
  [TYPE_BOOLEAN]: "false",
  [TYPE_BYTE]: 0,
  [TYPE_CHAR]: "",
  [TYPE_INT16]: 0,
  [TYPE_UINT16]: 0,
  [TYPE_INT32]: 0,
  [TYPE_UINT32]: 0,
  [TYPE_INT64]: 0,
  [TYPE_UINT64]: 0,
  [TYPE_FLOAT]: 0,
  [TYPE_DOUBLE]: 0,
  [TYPE_TIMESTAMP]: 0,
  [TYPE_STRING_16]: "",
  [TYPE_STRING_64]: "",
  [TYPE_STRING_255]: "",
}; //базовые типы

export const createPropertyValue = (systemTypeId, type) => componentsBySystemTypeId[systemTypeId] ? componentsBySystemTypeId[systemTypeId] : componentsBySystemTypeType[type];