import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsClockHistory } from 'react-icons/bs';
import { HiCheck } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import { FaArrowRotateRight } from 'react-icons/fa6';

import Utils from '../../utils/Utils.mjs';
import { ConfigurationService } from '../../services/services.mjs';
import { ComponentTableRow } from '../../hoc/hoc.mjs';
import { ConfirmationPopUpWithTimer, SavePropertiesButton } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';

import styles from './tableRow.module.scss';

const PARAMETER_CLASS_FOLDER = 0;
const PARAMETER_CLASS_OBJECT = 1;
const PARAMETER_CLASS_VALUE = 2;

export default function TableRow(props) {
  const {
    id,
    canEdit,
    isWritable,
    isDeleted,
    description,
    systemType,
    setComponentsTypeId,
    propertyValueTimestamp,
    propertyValueRoundUp,
    setIsError,
    setErrorMessage,
    setProperty,
    buildConfigurationTree,
  } = props;

  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [popupStateDeleteProperty, setPopupStateDeleteProperty] = useState({ isVisible: false, message: '', canSave: false, sending: false });
  const [popupStateRecoveryProperty, setPopupStateRecoveryProperty] = useState({ isVisible: false, message: '', canSave: false, sending: false });
  const { activeConfigurationId } = useActiveConfigurationId();
  const { kernel, currentLicense } = useKernel();
  const property = kernel.getParameterById(id);
  const { hasAccessDeleteProperties = undefined, hasAccessRecoveryProperties = undefined } = currentLicense;

  const {
    register,
    unregister,
    clearErrors,
    setValue,
    getValues,
    setFocus,
    trigger,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: 'onChange' });

  const setPropertyHistoryComponents = () => {
    setProperty(property);
    setComponentsTypeId(1);
  };

  const saveProperty = async (data) => {
    let value = data.value;

    if (value === 'true') value = true;

    if (value === 'false') value = false;

    setHandleChangeComponentTableRow(false);
    setSendingData(true);
    try {
      await kernel.setValues([{ parameterStruct: property, newValue: value }]);

      setDataSentSuccessfully(true);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setSendingData(false);
      }, 1000);
    } catch (error) {
      setSendingData(false);
      //показать сообщение об ошибке
      setIsError(true);
      setErrorMessage('Ошибка сохранения!');
      setHandleChangeComponentTableRow(true);
    }
  };

  const deleteProperty = async () => {
    try {
      setPopupStateDeleteProperty({ ...popupStateDeleteProperty, sending: true });
      const propertyId = id.toString();

      await ConfigurationService.deletePropertyById(propertyId, activeConfigurationId);
      await buildConfigurationTree();

      setPopupStateDeleteProperty({ ...popupStateDeleteProperty, sending: false, isVisible: false, canSave: false });
    } catch (error) {
      setPopupStateDeleteProperty({ ...popupStateDeleteProperty, sending: false, isVisible: false, canSave: false });
      //показать сообщение об ошибке
      setIsError(true);
      setErrorMessage('Ошибка удаления!');
      setHandleChangeComponentTableRow(true);
    }
  };

  const recoveryProperty = async () => {
    try {
      setPopupStateRecoveryProperty({ ...popupStateRecoveryProperty, sending: true });
      const propertyId = id.toString();

      await ConfigurationService.recoveryPropertyById(propertyId, activeConfigurationId);
      await buildConfigurationTree();

      setPopupStateRecoveryProperty({ ...popupStateRecoveryProperty, sending: false, isVisible: false, canSave: false });
    } catch (error) {
      setPopupStateRecoveryProperty({ ...popupStateRecoveryProperty, sending: false, isVisible: false, canSave: false });
      //показать сообщение об ошибке
      setIsError(true);
      setErrorMessage('Ошибка восстановления!');
      setHandleChangeComponentTableRow(true);
    }
  };

  const parameterStruct = property.parent.parent; // сам объект
  const coordObjectParameters = parameterStruct.properties.find((property) => property.systemTypeId === 123);
  const coordObject = coordObjectParameters ? coordObjectParameters.propertyValueRoundUp : '';

  return (
    <tr className={styles.tableRow}>
      {/* подтверждение удаления свойства*/}
      {popupStateDeleteProperty.isVisible && (
        <ConfirmationPopUpWithTimer callBack={deleteProperty} setState={setPopupStateDeleteProperty} state={popupStateDeleteProperty} />
      )}

      {/* подтверждение восстановления свойства*/}
      {popupStateRecoveryProperty.isVisible && (
        <ConfirmationPopUpWithTimer callBack={recoveryProperty} setState={setPopupStateRecoveryProperty} state={popupStateRecoveryProperty} />
      )}

      <td className={styles.tableRowTd}>
        <span className={styles.tableRowText} title={description}>
          {description}
        </span>
      </td>
      <td className={styles.tableRowTd}>
        <form id={id} onSubmit={(event) => handleSubmit(saveProperty)(event)} style={{ width: '100%' }}>
          <ComponentTableRow
            canEdit={canEdit}
            isWritable={isWritable}
            isDeleted={isDeleted}
            systemType={systemType}
            propertyValueRoundUp={propertyValueRoundUp}
            setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
            register={register}
            unregister={unregister}
            errors={errors}
            clearErrors={clearErrors}
            setValue={setValue}
            getValues={getValues}
            trigger={trigger}
            saveProperty={saveProperty}
            setFocus={setFocus}
            sendingData={sendingData}
            dataSentSuccessfully={dataSentSuccessfully}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
            coordObject={coordObject}
          />
        </form>
      </td>
      <td className={styles.tableRowTd}>
        <span className={classNames(styles.tableRowText, styles.tableDate)}>{Utils.convertTimestamp(propertyValueTimestamp)}</span>
        <span className={styles.tableRowText}>{Utils.convertTimestampGetHour(propertyValueTimestamp)}</span>
      </td>
      <td className={styles.tableRowTd}>
        <BsClockHistory className={styles.historyControl} onClick={() => setPropertyHistoryComponents()} title="История изменения" />

        {dataSentSuccessfully ? (
          <HiCheck className={styles.checkControl} />
        ) : (
          <SavePropertiesButton
            id={id}
            sendingData={sendingData}
            canEdit={canEdit}
            isWritable={isWritable}
            isDeleted={isDeleted}
            isValid={isValid}
            handleChangeComponentTableRow={handleChangeComponentTableRow}
          />
        )}

        {!property.parent.isDeleted && // если родитель не удален
          (property.isDeleted
            ? hasAccessRecoveryProperties === 'true' && (
              <FaArrowRotateRight
                className={styles.recoveryProperty}
                title="Восстановить свойство"
                onClick={() => {
                  const parameterStruct = property;
                  const parameterStructParent = property.parent;
                  const parentType = parameterStructParent.classify();

                  let parentTypeValue;
                  if (parentType === PARAMETER_CLASS_FOLDER) parentTypeValue = 'папки';
                  if (parentType === PARAMETER_CLASS_OBJECT) parentTypeValue = 'объекта';
                  if (parentType === PARAMETER_CLASS_VALUE) parentTypeValue = 'параметра';

                  setPopupStateRecoveryProperty({
                    ...popupStateRecoveryProperty,
                    message: `Вы точно хотите восстановить свойство "${parameterStruct.description}" у ${parentTypeValue} "${parameterStructParent.displayName}". Данное действие может привести к сбою работы устройства или программного обеспечения!`,
                    isVisible: true,
                  });
                }}
              />
            )
            : hasAccessDeleteProperties === 'true' && (
              <MdDelete
                className={styles.deleteIcon}
                title="Пометить на удаление свойство"
                onClick={() => {
                  const parameterStruct = property;
                  const parameterStructParent = property.parent;
                  const parentType = parameterStructParent.classify();

                  let parentTypeValue;
                  if (parentType === PARAMETER_CLASS_FOLDER) parentTypeValue = 'папки';
                  if (parentType === PARAMETER_CLASS_OBJECT) parentTypeValue = 'объекта';
                  if (parentType === PARAMETER_CLASS_VALUE) parentTypeValue = 'параметра';

                  setPopupStateDeleteProperty({
                    ...popupStateDeleteProperty,
                    message: `Вы точно хотите пометить на удаление свойство "${parameterStruct.description}" у ${parentTypeValue} "${parameterStructParent.displayName}". Данное действие может привести к сбою работы устройства или программного обеспечения!`,
                    isVisible: true,
                  });
                }}
              />
            ))}
      </td>
    </tr>
  );
}
