import { SetPointsListStruct } from '../quantum-lib/Arm/Parser/SetPoints/SetPointsListStruct.mjs';
import { SetPointsStruct } from '../quantum-lib/Arm/Parser/SetPoints/SetPointsStruct.mjs';

import { setWorkingMode } from './services.mjs';

export class SetpointsService {
  static async getSetpointsListStruct(idConfiguration) {
    return fetch(`${setWorkingMode()}/get-setpoints-list-struct/${idConfiguration}`, {
      method: 'get',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const setPointsListStruct = new SetPointsListStruct();
        setPointsListStruct.readFrom(dataView, offset, isLittleEndian);

        return setPointsListStruct;
      });
  }

  static async addSetpoint(idConfiguration, params) {
    return fetch(`${setWorkingMode()}/add-setpoint/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const setPointsStruct = new SetPointsStruct();
        setPointsStruct.readFrom(dataView, offset, isLittleEndian);

        return setPointsStruct;
      });
  }

  static async addTemplatesSetpoint(idConfiguration, params) {
    return fetch(`${setWorkingMode()}/add-templates-setpoint/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const setPointsStruct = new SetPointsStruct();
        setPointsStruct.readFrom(dataView, offset, isLittleEndian);

        return setPointsStruct;
      });
  }

  static async changeSetpointParameters(idConfiguration, params) {
    return fetch(`${setWorkingMode()}/change-setpoint-parameters/${idConfiguration}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const setPointsStruct = new SetPointsStruct();
        setPointsStruct.readFrom(dataView, offset, isLittleEndian);

        return setPointsStruct;
      });
  }

  static async changeSetpointOptions(idConfiguration, params) {
    return fetch(`${setWorkingMode()}/change-setpoint-options/${idConfiguration}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const setPointsStruct = new SetPointsStruct();
        setPointsStruct.readFrom(dataView, offset, isLittleEndian);

        return setPointsStruct;
      });
  }

  static async deleteSetpoint(idConfiguration, setpointId){
    return fetch(`${setWorkingMode()}/delete-setpoint/${idConfiguration}`, {
      method: 'delete',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({setpointId}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
      });
  }
}

