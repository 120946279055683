import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Loading } from '../components.mjs';

import styles from './confirmationPopUpWithTimer.module.scss';

export default function ConfirmationPopUpWithTimer(props) {
  const { callBack, state, setState } = props;

  const [timerValue, setTimerValue] = useState(5);
  const timerID = useRef();

  const closePopup = () => {
    setState({
      ...state,
      isVisible: false,
      canSave: false,
    });
  };

  useEffect(() => {
    timerID.current = setInterval(() => {
      if (timerValue <= 1 && state.isVisible && !state.canSave) {
        setState({
          ...state,
          canSave: true,
        });
        clearInterval(timerID.current);
      }

      if (timerValue >= 1) setTimerValue((prev) => prev - 1);
      
    }, 1000);
    return () => clearInterval(timerID.current);
  }, [timerValue]);

  return (
    <div className={styles.wrap}>
      {state.sending ? (
        <Loading />
      ) : (
        <div className={styles.container}>
          <AiOutlineCloseCircle className={styles.messageClose} onClick={closePopup} />
          <span className={styles.messageText} title={state.message}>
            {state.message}
          </span>
          <div className={styles.containerTimerText}>
            {timerValue > 0 && (
              <span className={styles.messageText}>
                Операция будет доступна через
                <span className={styles.messageTextActive}>{timerValue}</span>секунд.
              </span>
            )}
          </div>

          <div className={styles.controlBlock}>
            <button className={styles.buttonNo} onClick={closePopup}>
              Нет
            </button>
            <button className={!state.canSave ? styles.buttonYesUnActive : styles.buttonYes} onClick={callBack} disabled={!state.canSave}>
              Да
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

ConfirmationPopUpWithTimer.propTypes = {
  callBack: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
};
