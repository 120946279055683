import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUserTokenDecode } from '../../hooks/useUserTokenDecode.mjs';
import { ReactComponent as BsArrowRightCircleFill } from '../../assets/icon/BsArrowLeftCircle.svg';
import { AlarmEditor, ErrorMessagePopup, TreeConfiguration } from '../../components/components.mjs';
import { ContentApp, HeaderApp, NavApp } from '../../layout/layout.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { flagsParser } from '../../utils/flags-parser/flagsParser.mjs';

import styles from './alarm.module.scss';

export default function Alarm() {
  const location = useLocation();
  const navigate = useNavigate();
  const [configuration, setConfiguration] = useState(null);
  const [configurationName, setConfigurationName] = useState(null);
  const [stateElementConfigurationId, setStateElementConfigurationId] = useState(0n);
  const [userName, setUserName] = useState('');
  const [isError, setIsError] = useState(false);
  const [isErrorLoadingConfiguration, setIsErrorLoadingConfiguration] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Ошибка!');
  const [alarmEditorState, setAlarmEditorState] = useState({ alarmList: [], activeAlarm: {}, activeAlarmId: null });
  const [componentsTypeId, setComponentsTypeId] = useState(0);
  const [selectedParameter, setSelectedParameter] = useState({});
  const [rotate, setRotate] = useState(false);
  const { kernel, currentLicense } = useKernel();
  const { flags } = useUserTokenDecode();
  const { hasAccessRightsAlarmServerModule } = flagsParser(flags);

  const buildConfigurationTree = async () => {
    try {
      const { tree } = await kernel.buildTree(location);

      setConfiguration(tree);
      setConfigurationName(kernel.configuration.name);
      setUserName(kernel.getUser().name);
    } catch (error) {
      setIsErrorLoadingConfiguration(true);
      setErrorMessage('Ошибка загрузки конфигурации!');
    }
  };

  useEffect(() => {
    buildConfigurationTree();
    kernel.downloadingAlarm();
    kernel.timerRefreshToken(); //запуск обновления токена
    kernel.setNavigatePath('/alarm');
    return () => {};
  }, []);

  useEffect(() => {
    const { hasAccessAlarmServerModule = undefined } = currentLicense;
    if (hasAccessAlarmServerModule !== 'true' || hasAccessAlarmServerModule === undefined || hasAccessRightsAlarmServerModule === false) {
      navigate('/section-selection');
    }
    return () => {};
  }, [currentLicense]);

  return (
    <div className={styles.page}>
      {isError && <ErrorMessagePopup errorMessage={errorMessage} actionPerform={() => setIsError(false)} />}
      {isErrorLoadingConfiguration && (
        <ErrorMessagePopup
          errorMessage={errorMessage}
          actionPerform={() => {
            setIsErrorLoadingConfiguration(false);
            kernel.setNavigatePath('/alarm');
            navigate('/configuration-selection');
          }}
        />
      )}
      <NavApp />
      <ContentApp>
        <div className={styles.headerContainer}>
          <HeaderApp configurationName={configurationName} userName={userName} />
        </div>
        <div className={styles.contentContainer}>
          <div className={classNames(styles.treeContainer, rotate && styles.collapsedTree)}>
            <div className={classNames(styles.treeWrap, rotate && styles.collapsedTreeWrap)}>
              <TreeConfiguration
                configuration={configuration}
                stateElementConfigurationId={stateElementConfigurationId}
                setStateElementConfigurationId={setStateElementConfigurationId}
                setComponentsTypeId={setComponentsTypeId}
                setSelectedParameter={setSelectedParameter}
              />
            </div>
            <BsArrowRightCircleFill
              className={classNames(styles.treeControl, rotate && styles.treeControlActive)}
              onClick={() => setRotate(!rotate)}
            />
          </div>
          <div className={styles.codeEditorContainer}>
            <AlarmEditor
              stateElementConfigurationId={stateElementConfigurationId}
              alarmEditorState={alarmEditorState}
              setAlarmEditorState={setAlarmEditorState}
              configuration={configuration}
              setIsError={setIsError}
              setErrorMessage={setErrorMessage}
            />
          </div>
        </div>
      </ContentApp>
    </div>
  );
}
