import { setWorkingMode } from './services.mjs';

export class CreateNewObjectService {
  static async checkControllerId(configId, controllerId) {
    const response = await fetch(`${setWorkingMode()}/controller-id-checked/${configId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ controllerId }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }

  static async createNewDevice(configId, controllerId, typeBlockId, parameterId, deviceName) {
    const response = await fetch(`${setWorkingMode()}/create-new-device/${configId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        controllerId,
        typeBlockId,
        parameterId,
        deviceName,
      }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }

  static async createNewFolder(configId, typeBlockId, parameterId, folderName) {
    const response = await fetch(`${setWorkingMode()}/create-new-folder/${configId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        typeBlockId,
        parameterId,
        folderName,
      }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }

  static async createNewModule(configId, typeBlockId, parameterId, moduleName, allValues) {
    const response = await fetch(`${setWorkingMode()}/create-new-module/${configId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        typeBlockId,
        parameterId,
        moduleName,
        allValues,
      }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }

  }

  static async creatingNewDeviceInModule(configId, typeBlockId, parameterId, controllerName, allValues) {
    const response = await fetch(`${setWorkingMode()}/create-new-device-in-module/${configId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        typeBlockId,
        parameterId,
        controllerName,
        allValues,
      }),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    } else {
      return await response.json();
    }
  }

  static async createNewProperty(configId, params) {
    const response = await fetch(`${setWorkingMode()}/create-new-property/${configId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    }
  }

  static async createNewParameterUsingTemplate(configId, params) {
    const response = await fetch(`${setWorkingMode()}/create-new-parameter-using-template/${configId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    }
  }

  static async createNewCustomParameter(configId, params){
    const response = await fetch(`${setWorkingMode()}/create-new-custom-parameter/${configId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    }
  }

  static async createNewCustomObject(configId, params){
    const response = await fetch(`${setWorkingMode()}/create-new-custom-object/${configId}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      const errorMessage = (await response.json())?.message || "Ошибка!";
      throw new Error(errorMessage);
    }
  }
}
