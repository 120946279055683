import { ImFolder, ImFolderOpen } from 'react-icons/im';
import classNames from 'classnames';

import styles from './iconFolderConfigurationTree.module.scss';

export default function IconFolderConfigurationTree(props) {
  const { showChildren, isDeleted } = props;

  return <>{!showChildren ? <ImFolder className={classNames(styles.iconIsClose, isDeleted && styles.isDeleted)} /> : <ImFolderOpen className={classNames(styles.iconIsOpen, isDeleted && styles.isDeleted)} />}</>;
}
