import { useCallback, useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { BsArrowLeftCircle } from 'react-icons/bs';
import classNames from 'classnames';

import TreeSettlementServer from '../../../tree-settlement-server/TreeSettlementServer.jsx';
import { useKernel } from '../../../../context/ContextKernel.mjs';

import styles from './alarmListIdPopUp.module.scss';

const AlarmListItem = (props) => {
  const { alarmStruct, state, handleChangeAlarmList, deleteAlarm, canDeleteAlarm } = props;
  const [hasHover, setHasHover] = useState(false);

  return (
    <div
      key={alarmStruct.id}
      data-id={alarmStruct.id}
      title={`ID тревоги ${alarmStruct.id}`}
      className={classNames(
        styles.alarmListItem,
        state.activeAlarmId === alarmStruct.id && styles.alarmListItemActive,
        hasHover && styles.alarmListItemDelete,
      )}
      onClick={handleChangeAlarmList}
    >
      <span className={styles.alarmListItemText}>ID тревоги {alarmStruct.id}</span>
      {canDeleteAlarm && (
        <MdDelete
          className={styles.deleteIcon}
          data-id={alarmStruct.id}
          onMouseEnter={() => setHasHover(true)}
          onMouseLeave={() => setHasHover(false)}
          onClick={deleteAlarm}
        />
      )}
    </div>
  );
};

export function AlarmListIdPopUp(props) {
  const { configuration, activeElementTree, setActiveElementTree, closePopUp, callback } = props;
  const [alarmEditorState, setAlarmEditorState] = useState({ alarmList: [], activeAlarm: {}, activeAlarmId: null });
  const { kernel } = useKernel();

  const handleChangeAlarmList = (event) => {
    const alarmId = parseInt(event.currentTarget.dataset.id);
    const alarmListStruct = kernel.getAlarmListByParameterId(activeElementTree);

    setAlarmEditorState({
      ...alarmEditorState,
      activeAlarm: alarmListStruct.find((alarm) => alarm.id === alarmId),
      activeAlarmId: alarmId,
    });
  };

  const getActiveAlarm = useCallback((alarmListStruct, currentActiveAlarmId) => {
    return alarmListStruct.find((alarmStruct) => alarmStruct.id === currentActiveAlarmId) || alarmListStruct[0];
  }, []);

  const alarmListStruct = kernel.getAlarmListByParameterId(activeElementTree);
  useEffect(() => {
    if (alarmListStruct && alarmListStruct.length > 0) {
      const activeAlarm = getActiveAlarm(alarmListStruct, alarmEditorState?.activeAlarm?.id);
      const activeAlarmId = activeAlarm.id;

      setAlarmEditorState({ alarmList: alarmListStruct, activeAlarm, activeAlarmId });
    } else {
      setAlarmEditorState({ alarmList: [], activeAlarm: {}, activeAlarmId: null });
    }
    return () => {};
  }, [activeElementTree]);

  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>Выберете нужную тревогу</h2>
          <BsArrowLeftCircle className={styles.buttonBack} onClick={closePopUp} />
        </div>

        <div className={styles.block}>
          <div className={styles.treeContainer}>
            <TreeSettlementServer
              configuration={configuration}
              activeElementTree={activeElementTree}
              setActiveElementTree={setActiveElementTree}
              propertyVisible={false}
              parameterDisabled={false}
            />
          </div>
          <div className={styles.alarmContainer}>
            <div className={styles.alarmList}>
              <div className={styles.alarmListContainer}>
                <div className={styles.alarmListWrap}>
                  {alarmEditorState.alarmList.length > 0 ? (
                    alarmEditorState.alarmList.map((alarmStruct) => {
                      return (
                        <AlarmListItem
                          alarmStruct={alarmStruct}
                          state={alarmEditorState}
                          handleChangeAlarmList={handleChangeAlarmList}
                          deleteAlarm={() => {}}
                          canDeleteAlarm={false}
                        />
                      );
                    })
                  ) : (
                    <span className={styles.alarmListEmptyText}>Для данного параметра еще нет тревог, создайте их!</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.control}>
          <button className={classNames(styles.controlButton)} onClick={() => callback(alarmEditorState.activeAlarmId)}>
            Выбрать
          </button>
        </div>
      </div>
    </div>
  );
}
