import { AiOutlineCloseCircle } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { HiCheck } from 'react-icons/hi';
import classNames from 'classnames';

import { LoadingInUserPageForm } from '../components.mjs';

import styles from './confirmationPopUp.module.scss';

export default function ConfirmationPopUp(props) {
  const { actionConfirmation, closePopup, message, loading = false, success = false } = props;

  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <AiOutlineCloseCircle className={styles.messageClose} onClick={closePopup} />
        <span className={styles.messageText} title={message}>
          {message}
        </span>
        <div className={styles.controlBlock}>
          <button className={styles.buttonNo} onClick={closePopup}>
            Нет
          </button>
          <button className={classNames(styles.buttonYes, (success || loading) && styles.buttonSending)} onClick={actionConfirmation}>
            {success ? <HiCheck className={styles.checkControl} /> : loading ? <LoadingInUserPageForm /> : 'Да'}
          </button>
        </div>
      </div>
    </div>
  );
}

ConfirmationPopUp.propTypes = {
  actionConfirmation: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
};
