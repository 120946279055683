import styles from './tooltip.module.scss';

function Tooltip(props) {
  const { message } = props;

  return (
    <div className={styles.tooltipWrap}>
      <div className={styles.tooltipContainer}>
        <div className={styles.tooltipArrow}></div>
        <span title={message} className={styles.errorMessage}>
          {message}
        </span>
      </div>
    </div>
  );
}

export default Tooltip;
