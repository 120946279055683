import { LogsChangeProperties } from '../components/components.mjs';

export function ComponentsLogs(props) {
  const { setComponentsTypeId, logsTypeId = 0 } = props;

  const componentsByLogs = {};

  // будет формироваться исходя из типа лога
  componentsByLogs[logsTypeId] = <LogsChangeProperties />;

  let components;
  const setComponent = () => {
    if (logsTypeId in componentsByLogs) {
      components = componentsByLogs[logsTypeId];
    } else {
      components = <p>Произошла ошибка при выборе действия! Обновите страницу!</p>;
    }
  };
  setComponent();

  return <>{components}</>;
}
