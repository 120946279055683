import classNames from 'classnames';

import { getItemValue } from '../../model/getItemValue.mjs';

import styles from './customSelectOptionList.module.scss';

export function CustomSelectOptionList(props) {
  const {
    optionsList = [],
    itemsList = [],
    isOpen = false,
    isMulti = false,
    setItemsList,
    setOptionsList,
    setIsOpen,
    optionsListRef,
    inputState,
    setInputState,
  } = props;

  const chooseItem = ({ currentTarget }) => {
    const valueId = currentTarget.dataset.value;
    const { newOptionsList, valueItems } = getItemValue(optionsList, itemsList, valueId, isMulti);
    optionsListRef.current = newOptionsList;
    setOptionsList(newOptionsList);
    setItemsList(valueItems);
    setIsOpen(false);

    if (isMulti) {
      setInputState({ value: '', size: '16px' });
    } else {
      const clientWidth = window.innerWidth;
      const size = clientWidth > 1400 ? `${(valueItems[0].label.length + 1) * 9}px` : `${(valueItems[0].label.length + 1) * 8}px`;
      setInputState({ value: '', size });
    }
  };

  if (isMulti) {
    return (
      <div className={classNames(styles.optionList, isOpen && styles.optionListOpen)}>
        {optionsList.length > 0 ? (
          optionsList.map(({ value, label, isDisabled }) => {
            return (
              <div key={value} className={classNames(styles.option, isDisabled && styles.disabled)} data-value={value} onClick={chooseItem}>
                <span className={styles.optionText}>{label}</span>
              </div>
            );
          })
        ) : (
          <div className={classNames(styles.option, styles.noOptions)}>
            <span className={styles.optionText}>Ничего не найдено</span>
          </div>
        )}
      </div>
    );
  }

  if (!isMulti) {
    const [activeItemValue] = itemsList;

    return (
      <div className={classNames(styles.optionList, isOpen && styles.optionListOpen)}>
        {optionsList.map(({ value, label, isDisabled }) => {
          return (
            <div
              key={value}
              className={classNames(styles.option, isDisabled && styles.disabled, activeItemValue?.value === value && styles.optionActive)}
              data-value={value}
              onClick={chooseItem}
            >
              <span className={styles.optionText}>{label}</span>
            </div>
          );
        })}
      </div>
    );
  }
}
