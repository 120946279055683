import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCodeMirror } from '@uiw/react-codemirror';
import { LuSettings2 } from 'react-icons/lu';
import { AiFillCode } from 'react-icons/ai';
import { FaFileCode } from 'react-icons/fa6';

import * as funcParser from '../../utils/qs-srv-calc/FuncParser.mjs';
import { SetNamePopUp, SetTemplateTaskPopUp, Tabs } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { SettlementServerSetupService } from '../../services/services.mjs';
import { InvalidSyntax } from '../../utils/qs-srv-calc/InvalidSyntax.mjs';
import { useActiveConfigurationId } from '../../hooks/hooks.mjs';

import { wrapperQsSrvCalcFuncLanguage } from './model/qs-srv-calc-func/index.mjs';
import { DictionarySettlementServer, GeneralSettings, ServerOperationSettings, ServerStartupOptions, TaskListSettlementServer } from './ui/index.mjs';
import styles from './codeEditor.module.scss';

const tabsList = [
  {
    id: 1,
    icon: <LuSettings2 className="tabListIcon" />,
    text: 'Общие',
    renderingConditions: true,
  },
  {
    id: 2,
    icon: <AiFillCode className="tabListIcon" />,
    text: 'Параметры',
    renderingConditions: true,
  },
  {
    id: 3,
    icon: <FaFileCode className="tabListIcon" />,
    text: 'Код',
    renderingConditions: true,
  },
];

export default function CodeEditor(props) {
  const {
    stateElementConfigurationId,
    setIsError,
    setErrorMessage,
    configuration,
    codeEditorState,
    setCodeEditorState,
    treeTab,
    setTreeTemplateState,
    activeElementTree,
    setActiveElementTree,
  } = props;

  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [tabVisible, setTabVisible] = useState(1);
  const { kernel } = useKernel();
  const { activeConfigurationId } = useActiveConfigurationId();

  const classify = kernel.getParameterById(stateElementConfigurationId)?.classify();

  const nameTask = codeEditorState?.activeTask?.nameTask ?? '';
  const workingStatus = codeEditorState?.activeTask?.workingStatus ?? 0;
  const systemTypeId = codeEditorState?.activeTask?.systemTypeId ?? 1;
  const blockTypeIdParent = codeEditorState?.activeTask?.blockTypeParentStruct?.id ?? 1;
  const blockTypeId = codeEditorState?.activeTask?.blockTypeStruct?.id ?? 1;

  const [namePopUpVisible, setNamePopUpVisible] = useState(false);

  const [generalSettings, setGeneralSettings] = useState({ nameTask, workingStatus, systemTypeId, blockTypeIdParent, blockTypeId });
  const [codeEditorValue, setCodeEditorValue] = useState({ code: '', codeLength: 0 });
  const [errors, setErrors] = useState({ status: false, errorMessage: '' });
  const editorServerOperation = useRef();

  const handleChangeEditorWorkParameters = useCallback((strValue, viewUpdate) => {
    setCodeEditorValue({ code: strValue, codeLength: strValue.length });
    setErrors({
      status: strValue.length > 1275,
      errorMessage: strValue.length > 1275 ? 'Превышена допустимое количество символов!' : '',
    });
  }, []);

  const { setContainer, view } = useCodeMirror({
    container: editorServerOperation.current,
    onChange: handleChangeEditorWorkParameters,
    value: codeEditorValue.code,
    theme: 'dark',
    width: '100%',
    height: '300px',
    allowMultipleSelections: true,
    autoFocus: true,
    foldGutter: true,
    extensions: [wrapperQsSrvCalcFuncLanguage()],
  });

  const saveGeneralSetting = async (data) => {
    try {
      setSendingData(true);
      const taskId = codeEditorState.activeTaskId.toString();

      let newSettlementServerSetupStruct;

      if (treeTab === 2) {
        newSettlementServerSetupStruct = await SettlementServerSetupService.changeTaskGeneralSettings(activeConfigurationId, {
          taskId,
          nameTask: data.nameTask,
          workingStatus: data.workingStatus,
          systemTypeId: data.systemTypeId,
          blockTypeIdParent: data.blockTypeIdParent,
          blockTypeId: data.blockTypeId,
        });
      } else {
        newSettlementServerSetupStruct = await SettlementServerSetupService.changeTaskGeneralSettings(activeConfigurationId, {
          taskId,
          nameTask: data.nameTask,
          workingStatus: data.workingStatus,
          systemTypeId: 0,
          blockTypeIdParent: 0,
          blockTypeId: 0,
        });
      }

      kernel.setChangeTaskByTaskId(stateElementConfigurationId, taskId, newSettlementServerSetupStruct);

      // обновление состояния
      const settlementServerSetupStructList = kernel.getSettlementServerSetupByParameterId(stateElementConfigurationId);

      if (treeTab === 1) {
        setCodeEditorState({
          taskList: kernel.getSettlementServerSetupByParameterId(stateElementConfigurationId),
          activeTask: settlementServerSetupStructList.find((task) => task.id === BigInt(taskId)),
          activeTaskId: BigInt(taskId),
        });
      } else {
        const taskList = kernel.templateTasks[newSettlementServerSetupStruct.systemType].templateTasksList;
        const newTask = kernel.getTemplateTasksByParameterId(newSettlementServerSetupStruct.systemType, newSettlementServerSetupStruct.id);

        // если изменился systemTypeId то перемещаем элемент
        const treeTemplateList = Object.values(kernel.templateTasks);
        setTreeTemplateState(treeTemplateList);
        setActiveElementTree(newSettlementServerSetupStruct.systemType);

        setCodeEditorState({
          activeTask: newTask,
          activeTaskId: newTask.id,
          taskList,
        });
      }

      setTimeout(() => {
        setDataSentSuccessfully(true);
        setSendingData(false);
      }, 600);

      setTimeout(() => {
        setDataSentSuccessfully(false);
      }, 1400);
    } catch (error) {
      setSendingData(false);
      setIsError(true);
      setErrorMessage('Ошибка сохранения!');
    }
  };

  const checkSyntax = (code) => {
    let testFunction = true;

    try {
      funcParser.parse(code); // проверка кода
      setErrors({
        status: false,
        errorMessage: '',
      });
    } catch (error) {
      if (error instanceof InvalidSyntax) {
        testFunction = false;
        const errorStr = `${error.message}`;
        setErrors({
          status: true,
          errorMessage: errorStr,
        });

        // выставляем курсор в начало позиции ошибки
        view.dispatch({
          selection: { anchor: error.position },
        });
        view.focus();
      } else {
        testFunction = false;
        setIsError(true);
        setErrorMessage('Ошибка сохранения!');
      }
    }

    return testFunction;
  };

  const saveWorkParameters = async (value) => {
    const qsSrvCalcFuncTest = checkSyntax(codeEditorValue.code);

    if (qsSrvCalcFuncTest) {
      try {
        setSendingData(true);
        const taskId = codeEditorState.activeTaskId.toString();

        const newSettlementServerSetupStruct = await SettlementServerSetupService.changeTaskWorkParameters(activeConfigurationId, taskId, value);

        kernel.setChangeTaskByTaskId(stateElementConfigurationId, taskId, newSettlementServerSetupStruct);

        // обновить состояния
        const settlementServerSetupStructList = kernel.getSettlementServerSetupByParameterId(stateElementConfigurationId);

        if (treeTab === 1) {
          setCodeEditorState({
            taskList: kernel.getSettlementServerSetupByParameterId(stateElementConfigurationId),
            activeTask: settlementServerSetupStructList.find((task) => task.id === BigInt(taskId)),
            activeTaskId: BigInt(taskId),
          });
        } else {
          const taskList = kernel.templateTasks[newSettlementServerSetupStruct.systemType].templateTasksList;
          const newTask = kernel.getTemplateTasksByParameterId(newSettlementServerSetupStruct.systemType, newSettlementServerSetupStruct.id);

          setCodeEditorState({
            activeTask: newTask,
            activeTaskId: newTask.id,
            taskList,
          });
        }

        setTimeout(() => {
          setDataSentSuccessfully(true);
          setSendingData(false);
        }, 800);

        setTimeout(() => {
          setDataSentSuccessfully(false);
        }, 1600);
      } catch (error) {
        setSendingData(false);
        setIsError(true);
        setErrorMessage('Ошибка сохранения!');
      }
    }
  };

  const handleTabVisible = (event) => {
    const tabId = parseInt(event.currentTarget.dataset.tab);
    setTabVisible(tabId);
    setErrors({ status: false, errorMessage: '' });
  };

  const handleChangeTaskList = (event) => {
    const taskId = BigInt(event.currentTarget.dataset.id);
    const settlementServerSetupStructList = kernel.getSettlementServerSetupByParameterId(stateElementConfigurationId);

    setCodeEditorState({
      ...codeEditorState,
      activeTask: settlementServerSetupStructList.find((task) => task.id === taskId),
      activeTaskId: taskId,
    });
  };

  const setSnippetCodeSrvCalc = (dictionaryItem) => {
    if (Object.values(dictionaryItem).length > 0) {
      const { from, to } = view.state.selection.ranges[0];

      const snippet = dictionaryItem.snippet;
      view.dispatch({
        changes: {
          from,
          to,
          insert: snippet.code,
        },
        selection: { anchor: from + snippet.offset },
      });
      view.focus();
    }
  };

  const getActiveTask = useCallback((taskListStruct, currentActiveTaskId) => {
    return taskListStruct.find((taskStruct) => taskStruct.id === currentActiveTaskId) || taskListStruct[0];
  }, []);

  const createSpecialTasks = async (data) => {
    try {
      setSendingData(true);
      const idConfiguration = kernel.getActiveConfiguration().id;
      const parameterId = stateElementConfigurationId.toString();

      const settlementServerSetupStruct = await SettlementServerSetupService.addTask(idConfiguration, parameterId, data.name);
      kernel.setNewTask(settlementServerSetupStruct);
      const newTask = kernel
        .getSettlementServerSetupByParameterId(parameterId)
        .find((settlementServerSetup) => settlementServerSetup.id === settlementServerSetupStruct.id);

      setTimeout(() => {
        setSendingData(false);
        setDataSentSuccessfully(true);
      }, 350);

      setTimeout(() => {
        setCodeEditorState({
          taskList: kernel.getSettlementServerSetupByParameterId(parameterId),
          activeTaskId: newTask.id,
          activeTask: newTask,
        });
        setDataSentSuccessfully(false);
        setNamePopUpVisible(false);
      }, 1300);
    } catch (error) {
      setSendingData(false);
      setNamePopUpVisible(false);
      setIsError(true);
      setErrorMessage('Ошибка! Не удалось создать новую задачу!');
    }
  };

  const createTemplatesTask = async (data) => {
    try {
      setSendingData(true);

      const settlementServerSetupStruct = await SettlementServerSetupService.createTemplatesTask(activeConfigurationId, data);

      kernel.setNewTemplateTask(settlementServerSetupStruct);

      const taskList = kernel.templateTasks[settlementServerSetupStruct.systemType].templateTasksList;
      const newTask = kernel.getTemplateTasksByParameterId(settlementServerSetupStruct.systemType, settlementServerSetupStruct.id);

      setTimeout(() => {
        setSendingData(false);
        setDataSentSuccessfully(true);
      }, 350);

      setTimeout(() => {
        setCodeEditorState({
          activeTask: newTask,
          activeTaskId: newTask.id,
          taskList,
        });

        const treeTemplateList = Object.values(kernel.templateTasks);
        setTreeTemplateState(treeTemplateList);
        setActiveElementTree(settlementServerSetupStruct.systemType);
        setDataSentSuccessfully(false);
        setNamePopUpVisible(false);
      }, 1300);
    } catch (error) {
      setSendingData(false);
      setNamePopUpVisible(false);
      setIsError(true);
      setErrorMessage('Ошибка! Не удалось создать новую задачу!');
    }
  };

  const taskListStruct = kernel.getSettlementServerSetupByParameterId(stateElementConfigurationId);
  useEffect(() => {
    if (taskListStruct) {
      if (treeTab === 1) {
        const activeTask = getActiveTask(taskListStruct, codeEditorState?.activeTask?.id);
        const activeTaskId = activeTask.id;

        setCodeEditorState({ taskList: taskListStruct, activeTask, activeTaskId });
        setErrors({ status: false, errorMessage: '' });
      }
    } else {
      setCodeEditorState({ taskList: [], activeTask: {}, activeTaskId: null });
    }
    return () => {};
  }, [stateElementConfigurationId, taskListStruct]);

  return (
    <div className={styles.codeEditor}>
      {namePopUpVisible && treeTab === 1 && (
        <SetNamePopUp
          onSubmit={createSpecialTasks}
          setNamePopUpVisible={setNamePopUpVisible}
          sendingData={sendingData}
          dataSentSuccessfully={dataSentSuccessfully}
          title="Создание задачи"
          placeholder="Название задачи"
        />
      )}
      {namePopUpVisible && treeTab === 2 && (
        <SetTemplateTaskPopUp
          onSubmit={createTemplatesTask}
          initialSystemType={activeElementTree}
          setNamePopUpVisible={setNamePopUpVisible}
          sendingData={sendingData}
          dataSentSuccessfully={dataSentSuccessfully}
          title="Создание задачи"
          placeholder="Название задачи"
        />
      )}

      {classify === 2 || treeTab === 2 ? (
        <>
          <div className={classNames(styles.codeEditorColumn, styles.codeEditorColumnControl)}>
            {(stateElementConfigurationId.toString() !== '0' || treeTab === 2) && (
              <>
                <TaskListSettlementServer
                  codeEditorState={codeEditorState}
                  handleChangeTaskList={handleChangeTaskList}
                  setNamePopUpVisible={setNamePopUpVisible}
                  treeTab={treeTab}
                />

                <DictionarySettlementServer setSnippetCodeSrvCalc={setSnippetCodeSrvCalc} tabVisible={tabVisible} />
              </>
            )}
          </div>

          <div className={classNames(styles.codeEditorColumn, styles.codeEditorColumnEditor)}>
            {stateElementConfigurationId.toString() !== '0' || treeTab === 2 ? (
              Object.keys(codeEditorState.activeTask).length ? (
                <>
                  <Tabs tabsList={tabsList} tabVisible={tabVisible} handleTabVisible={handleTabVisible} />

                  <div className={styles.codeEditorWrap}>
                    {tabVisible === 1 && (
                      <GeneralSettings
                        codeEditorStateActiveTask={codeEditorState.activeTask}
                        sendingData={sendingData}
                        dataSentSuccessfully={dataSentSuccessfully}
                        saveSetting={saveGeneralSetting}
                        generalSettings={generalSettings}
                        setGeneralSettings={setGeneralSettings}
                        treeTab={treeTab}
                      />
                    )}
                    {tabVisible === 2 && (
                      <ServerStartupOptions
                        codeEditorStateActiveTask={codeEditorState.activeTask}
                        codeEditorValue={codeEditorValue}
                        setCodeEditorValue={setCodeEditorValue}
                        setIsError={setIsError}
                        setErrorMessage={setErrorMessage}
                        stateElementConfigurationId={stateElementConfigurationId}
                        codeEditorState={codeEditorState}
                        setCodeEditorState={setCodeEditorState}
                        sendingData={sendingData}
                        setSendingData={setSendingData}
                        dataSentSuccessfully={dataSentSuccessfully}
                        setDataSentSuccessfully={setDataSentSuccessfully}
                        kernel={kernel}
                        treeTab={treeTab}
                      />
                    )}
                    {tabVisible === 3 && (
                      <ServerOperationSettings
                        codeEditorStateActiveTask={codeEditorState.activeTask}
                        sendingData={sendingData}
                        dataSentSuccessfully={dataSentSuccessfully}
                        saveSetting={saveWorkParameters}
                        configuration={configuration}
                        editorServerOperation={editorServerOperation}
                        setContainer={setContainer}
                        view={view}
                        codeEditorValue={codeEditorValue}
                        setCodeEditorValue={setCodeEditorValue}
                        errors={errors}
                        treeTab={treeTab}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className={styles.createTaskTextContainer}>
                  <p className={styles.createTaskText}>{treeTab === 1 ? 'Для данного параметра нет задач!' : 'Нет шаблонных задач!'}</p>
                </div>
              )
            ) : null}
          </div>
        </>
      ) : (
        <div className={styles.createTaskTextContainer}>
          <p className={styles.createTaskText}>Расчетные задачи можно создавать только для параметров!</p>
        </div>
      )}
    </div>
  );
}
