import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { HiCheck } from 'react-icons/hi';

import { useKernel } from '../../context/ContextKernel.mjs';
import { StringStruct } from '../../quantum-lib/Scada/Parser/StringStruct.mjs';
import { UsersService } from '../../services/services.mjs';
import { LoadingInUserPageForm } from '../components.mjs';

import styles from './groupFormChangeCurrentGroup.module.scss';

export default function GroupFormChangeCurrentGroup(props) {
  const { setComponentsTypeId, dataForm, setIsError, setErrorMessage, usersTree, initialStateUserTree, setUsersTree, setInitialStateUserTree } = {
    ...props,
  };

  const groupName = dataForm?.name.length !== 0 ? dataForm?.name : '';
  const groupId = dataForm?.id;

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const [nameGroupInputValue, setNameGroupInputValue] = useState();
  const [handleChangeInput, setHandleChangeInput] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const { kernel } = useKernel();

  const handleChangeInputValue = (event) => {
    if (event.target.value === groupName) {
      setHandleChangeInput(false);
    } else {
      setHandleChangeInput(true);
    }
    setNameGroupInputValue(event.target.value);
  };

  const groupFormChangeCurrentGroupButtonBackClick = () => {
    setComponentsTypeId(0);
  };

  const onSubmit = async (data) => {
    setSendingData(true);
    setHandleChangeInput(false);
    try {
      await UsersService.usersChangingExistingGroup(data, groupId);

      //запись в kernel и добавление в state
      kernel.changingUserGroupInParametersUserTree(groupId, data.nameGroup);
      setUsersTree(
        usersTree.map((group) => {
          if (group.id === groupId) {
            group.usersGroupStruct.name = new StringStruct(data.nameGroup);
          }
          return group;
        }),
      );
      setInitialStateUserTree(
        initialStateUserTree.map((group) => {
          if (group.id === groupId) {
            group.usersGroupStruct.name = new StringStruct(data.nameGroup);
          }
          return group;
        }),
      );

      setTimeout(() => {
        setSendingData(false);
        setDataSentSuccessfully(true);
      }, 800);

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setComponentsTypeId(0);
      }, 1500);
    } catch (error) {
      setSendingData(false);
      setHandleChangeInput(true);
      //сообщение об ошибке
      setIsError(true);
      const errorMessage = error.toString().split('Error: ')[1];
      setErrorMessage(errorMessage);
      console.warn('error: ', error);
    }
  };

  useEffect(() => {
    if (dataForm?.id) {
      setValue('nameGroup', groupName);
      trigger('nameGroup');
      setNameGroupInputValue(groupName);
    } else {
      setNameGroupInputValue(groupName);
    }
    return () => {};
  }, [dataForm?.id]);

  return (
    <div className={styles.groupFormChangeCurrentGroup}>
      <div className={styles.groupFormChangeCurrentGroupHeader}>
        <h2 className={styles.groupFormChangeCurrentGroupTitle}>Группа</h2>
        <BsArrowLeftCircle className={styles.groupFormChangeCurrentGroupButtonBack} onClick={groupFormChangeCurrentGroupButtonBackClick} />
      </div>
      <form className={styles.groupFormChangeCurrentGroupForm} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.container}>
          <div className={styles.inputWrap}>
            <input
              className={classNames(styles.input, errors.nameGroup && styles.inputError)}
              type="text"
              placeholder="Имя группы"
              onInput={handleChangeInputValue}
              value={nameGroupInputValue}
              {...register('nameGroup', {
                required: 'Поле обязательно к заполнению',
                minLength: {
                  value: 3,
                  message: 'Поле должно содержать минимум 3 символа',
                },
                maxLength: {
                  value: 255,
                  message: 'Поле может содержать максимум 255 символа',
                },
              })}
            />
            {errors?.nameGroup && <p className={styles.errorMessage}>{errors?.nameGroup?.message || 'Ошибка заполнения!'}</p>}
          </div>
        </div>
        <button
          className={
            sendingData || dataSentSuccessfully
              ? styles.buttonSending
              : handleChangeInput && isValid
                ? classNames(styles.button, styles.formValid)
                : classNames(styles.button, styles.formNoValid)
          }
          type="submit"
          disabled={!isValid || !handleChangeInput}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Изменить'}
        </button>
      </form>
    </div>
  );
}
