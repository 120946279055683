import { useCallback, useRef, useState } from 'react';

import {
  ChannelSettingSubtype,
  ChannelSettingType,
  InputCoordinatesLine,
  InputCoordinatesPoint,
  InputIp,
  InputNumber,
  InputPhone,
  InputText,
  ParameterTableFooter,
  ReportingDay,
  ReportingHour,
  SelectBool,
  InputNumberFractional,
  ChoiceTypeBlockProperties,
  OpcuaExternalTag,
  TimeZoneClient,
} from '../components/components.mjs';

import { SystemType } from '../quantum-lib/Scada/Parameters/SystemType.mjs';

import { createValidationScheme } from './model/validationScheme.mjs';

export function ComponentTableRow(props) {
  const {
    canEdit,
    isWritable,
    systemType,
    isDeleted,
    propertyValueRoundUp,
    setHandleChangeComponentTableRow,
    register,
    unregister,
    errors,
    clearErrors,
    setValue,
    getValues,
    trigger,
    saveProperty,
    sendingData,
    dataSentSuccessfully,
    setIsError,
    setErrorMessage,
    coordObject,
    parentBlockTypeId,
  } = props;

  const [showTooltip, setShowTooltip] = useState(true);

  const ref = useRef(null);
  const inputRef = useRef(null);

  const handleFocus = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handleBlur = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const componentsBySystemTypeId = {}; //системные типы
  const componentsBySystemTypeType = {}; //базовые типы

  //имя валидационной схемы формируется следующим образом - название компонента, тип, валидационная схема
  const validationScheme = createValidationScheme(handleBlur, inputRef);

  //если canEdit false то редактировать можно
  //если isWritable false, то редактировать нельзя
  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_TIME_ZONE_CLIENT] = (
    <TimeZoneClient
      value={propertyValueRoundUp}
      setValue={setValue}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_OBJECT_TYPE] = (
    <ChoiceTypeBlockProperties
      value={propertyValueRoundUp}
      setValue={setValue}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_IPV4] = (
    <InputIp
      value={propertyValueRoundUp}
      setValue={setValue}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputIpIpValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_PHONE] = (
    <InputPhone
      value={propertyValueRoundUp}
      setValue={setValue}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputPhonePhoneValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      ref={ref}
      inputRef={inputRef}
      clearErrors={clearErrors}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_REPORTING_HOUR] = (
    <ReportingHour
      value={propertyValueRoundUp}
      setValue={setValue}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
      parentBlockTypeId={parentBlockTypeId}
    />
  );

  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_REPORTING_DAY] = (
    <ReportingDay
      value={propertyValueRoundUp}
      setValue={setValue}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_CHANNEL_SETTING_TYPE] = (
    <ChannelSettingType
      value={propertyValueRoundUp}
      setValue={setValue}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_CHANNEL_SETTING_SUBTYPE] = (
    <ChannelSettingSubtype
      value={propertyValueRoundUp}
      setValue={setValue}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_PARAMETER_TABLE_FOOTER] = (
    <ParameterTableFooter
      value={propertyValueRoundUp}
      setValue={setValue}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_TIME_WAIT_CALL] = (
    <InputNumber
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      min="0"
      max="255"
      step="1"
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputNumberTimeWaitCallValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_TIME_BETWEEN_CALL] = (
    <InputNumber
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      min="0"
      max="255"
      step="1"
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputNumberTimeBetweenCallValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_GPS_COORDINATES_POINT] = (
    <InputCoordinatesPoint
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      saveProperty={saveProperty}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      errors={errors}
      showTooltip={showTooltip}
      validationScheme={validationScheme.inputCoordinatesPointGpsCoordinatesPointValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
      dataSentSuccessfully={dataSentSuccessfully}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
    />
  );

  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_GPS_COORDINATES_LINE] = (
    <InputCoordinatesLine
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      saveProperty={saveProperty}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      errors={errors}
      showTooltip={showTooltip}
      validationScheme={validationScheme.inputCoordinatesPointLineCoordinatesLineValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      coordObject={coordObject}
    />
  );

  componentsBySystemTypeId[SystemType.SYSTEM_TYPE_OPCUA_EXTERNAL_TAG] = (
    <OpcuaExternalTag
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      errors={errors}
      showTooltip={showTooltip}
      validationScheme={validationScheme.opcuaExternalTagString255ValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_BOOLEAN] = (
    <SelectBool
      value={propertyValueRoundUp}
      setValue={setValue}
      canEdit={canEdit}
      register={register}
      unregister={unregister}
      name="value"
      isWritable={isWritable}
      isDeleted={isDeleted}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_BYTE] = (
    <InputNumber
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      min="0"
      max="255"
      step="1"
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputNumberByteValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_CHAR] = (
    <InputText
      value={propertyValueRoundUp}
      setValue={setValue}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputTextCharValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_INT16] = (
    <InputNumber
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      min="-32768"
      max="32768"
      step="1"
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputNumberInt16ValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_INT32] = (
    <InputNumber
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      min="-2147483648"
      max="2147483648"
      step="1"
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputNumberInt32ValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_INT64] = (
    <InputNumber
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      min="-9223372036854775808"
      max="9223372036854775808"
      step="1"
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputNumberInt64ValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_UINT16] = (
    <InputNumber
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      min="0"
      max="65535"
      step="1"
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputNumberUint16ValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_UINT32] = (
    <InputNumber
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      min="0"
      max="4294967295"
      step="1"
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputNumberUint32ValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_UINT64] = (
    <InputNumber
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      min="0"
      max="18446744073709551615"
      step="1"
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputNumberUint64ValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_FLOAT] = (
    <InputNumberFractional
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      step="1"
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputNumberFractionalFloatValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_DOUBLE] = (
    <InputNumberFractional
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      trigger={trigger}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      step="1"
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputNumberFractionalDoubleValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_TIMESTAMP] = 'TYPE_TIMESTAMP'; //сделать

  componentsBySystemTypeType[SystemType.TYPE_STRING_16] = (
    <InputText
      value={propertyValueRoundUp}
      setValue={setValue}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      showTooltip={showTooltip}
      errors={errors}
      validationScheme={validationScheme.inputTextString16ValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_STRING_64] = (
    <InputText
      value={propertyValueRoundUp}
      setValue={setValue}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      handleFocus={handleFocus}
      register={register}
      unregister={unregister}
      name="value"
      errors={errors}
      showTooltip={showTooltip}
      validationScheme={validationScheme.inputTextString64ValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  componentsBySystemTypeType[SystemType.TYPE_STRING_255] = (
    <InputText
      value={propertyValueRoundUp}
      setValue={setValue}
      getValues={getValues}
      placeholder={systemType.systemTypeStruct.description.value}
      canEdit={canEdit}
      isWritable={isWritable}
      isDeleted={isDeleted}
      register={register}
      unregister={unregister}
      name="value"
      handleFocus={handleFocus}
      errors={errors}
      showTooltip={showTooltip}
      validationScheme={validationScheme.inputTextString255ValidationScheme}
      setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
      sendingData={sendingData}
    />
  );

  let components;
  const setComponent = () => {
    if (systemType.id in componentsBySystemTypeId) {
      components = componentsBySystemTypeId[systemType.id];
    } else if (systemType.type in componentsBySystemTypeType) {
      components = componentsBySystemTypeType[systemType.type];
    } else {
      components = <p>Неизвестный системный тип!</p>;
    }
  };
  setComponent();

  return <>{components}</>;
}
