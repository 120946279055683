import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { HiCheck } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import { SavePropertiesButtonModuleRs, Tooltip } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import Utils from '../../utils/Utils.mjs';

import styles from './tableRowDeviceAddressModuleRs.module.scss';

const min = 0; // минимальное значение адрес устройства на шине
const max = 254; // максимальное значение адрес устройства на шине
const step = 1; // шаг изменения значения адреса устройства на шине

export default function TableRowDeviceAddressModuleRs(props) {
  const { stateElementConfigurationId, selectedParameter, setIsError, setErrorMessage } = props;

  const [showTooltip, setShowTooltip] = useState(true);

  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [changesDate, setChangesDate] = useState({ day: '', hour: '' }); // вывод даты и времени последнего изменения свойства (выводится время драйвер адреса)
  const [state, setState] = useState(); // состояние компонента

  const initialValue = useRef();
  const { kernel } = useKernel();

  const {
    register,
    formState: { errors, isValid },
    setValue,
    handleSubmit,
    setError,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const saveProperty = async () => {
    setHandleChangeComponentTableRow(false);
    setSendingData(true);

    try {
      const newChildrenDefaultValues = Utils.setNewDefaultValue(selectedParameter, state, 'deviceAddressOnBus');

      await kernel.setValues([...newChildrenDefaultValues]);
      initialValue.current = state;

      setDataSentSuccessfully(true);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setSendingData(false);
      }, 1500);
    } catch (error) {
      setSendingData(false);
      setIsError(true);
      setErrorMessage('Ошибка сохранения!');
      setHandleChangeComponentTableRow(true);
    }
  };

  useEffect(() => {
    // получаем значения дочерних свойств (default value)
    const defaultValueParameters = Utils.getPropertiesDefaultValueInModuleRs(selectedParameter);

    const day = Utils.convertTimestamp(defaultValueParameters[0].propertyValueTimestamp);
    const hour = Utils.convertTimestampGetHour(defaultValueParameters[0].propertyValueTimestamp);
    setChangesDate({ day: day, hour: hour });

    const defaultValues = defaultValueParameters.map((defaultValueParameter) => {
      const valueUint32 = defaultValueParameter.propertyValueRoundUp;
      return Utils.splitDefaultValue(valueUint32);
    });

    const deviceAddressOnBus = defaultValues[0].deviceAddressOnBus;
    const deviceAddressOnBusCheck = defaultValues.every((defaultValue) => defaultValues[0].deviceAddressOnBus === defaultValue.deviceAddressOnBus);

    if (deviceAddressOnBusCheck) {
      setState(deviceAddressOnBus);
      initialValue.current = deviceAddressOnBus;
    } else {
      setState(255);
      initialValue.current = 255;
      setError('deviceAddressBus', { type: 'custom', message: 'Невалидное значение!' });
    }

    return () => {};
  }, [stateElementConfigurationId]);

  const incrementValue = (event) => {
    if (parseInt(state) < parseInt(max) && !sendingData) {
      const newNumber = parseInt(state) + parseInt(step);
      setState(newNumber);
      setValue('deviceAddressBus', newNumber.toString());
      trigger('deviceAddressBus');

      if (newNumber.toString() === initialValue.current.toString()) {
        setHandleChangeComponentTableRow(false);
      } else {
        setHandleChangeComponentTableRow(true);
      }
    }
  };

  const decrementValue = (event) => {
    if (parseInt(state) > parseInt(min) && !sendingData) {
      const newNumber = parseInt(state) - parseInt(step);
      setValue('deviceAddressBus', newNumber.toString());
      setState(newNumber);
      trigger('deviceAddressBus');

      if (newNumber.toString() === initialValue.current.toString()) {
        setHandleChangeComponentTableRow(false);
      } else {
        setHandleChangeComponentTableRow(true);
      }
    }
  };

  const updateValue = (event) => {
    if (Utils.testIsNumber(event.target.value)) {
      if (event.target.value === initialValue.current.toString()) {
        setHandleChangeComponentTableRow(false);
      } else {
        setHandleChangeComponentTableRow(true);
      }
      setValue('deviceAddressBus', event.target.value);
      setState(event.target.value);
    }
  };

  const handleFocus = useCallback(() => {
    setShowTooltip(true);
  }, [showTooltip, setShowTooltip]);

  const handleBlur = useCallback(() => {
    setShowTooltip(false);
  }, [showTooltip, setShowTooltip]);

  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableRowTd}>
        <span className={styles.tableRowText} title={'Адрес устройства на шине'}>
          Адрес устройства на шине
        </span>
      </td>
      <td className={styles.tableRowTd}>
        {/* компонент */}
        <form className={styles.form} onSubmit={handleSubmit(saveProperty)}>
          <div className={styles.componentContainer}>
            <input
              type="text"
              placeholder="Адрес устройства на шине"
              min={Number(min)}
              max={Number(max)}
              step={Number(step)}
              value={state}
              className={classNames(
                styles.inputNumber,
                errors?.deviceAddressBus && styles.inputNumberError,
                sendingData && styles.inputNumberSending,
              )}
              onFocus={handleFocus}
              onInput={updateValue}
              {...register('deviceAddressBus', {
                required: 'Поле обязательно к заполнению',
                min: {
                  value: 0,
                  message: 'Минимальное значение 0',
                },
                max: {
                  value: 254,
                  message: 'Максимальное значение 254',
                },
                minLength: {
                  value: 1,
                  message: 'Минимальная длинна значения 1',
                },
                maxLength: {
                  value: 3,
                  message: 'Максимальная длинна значения 3',
                },
                onBlur: handleBlur,
              })}
            />
            <div className={styles.inputNumberControls}>
              <MdKeyboardArrowUp className={classNames(styles.arrow, sendingData && styles.inputNumberSending)} onClick={incrementValue} />
              <MdKeyboardArrowDown className={classNames(styles.arrow, sendingData && styles.inputNumberSending)} onClick={decrementValue} />
            </div>
            {showTooltip && errors?.deviceAddressBus && <Tooltip message={`${errors?.deviceAddressBus?.message || 'Ошибка заполнения!'} `} />}
          </div>
        </form>
        {/* компонент */}
      </td>
      <td className={styles.tableRowTd}>
        <span className={classNames(styles.tableRowText, styles.tableDate)}>{changesDate.day}</span>
        <span className={styles.tableRowText}>{changesDate.hour}</span>
      </td>
      <td className={styles.tableRowTd}>
        {dataSentSuccessfully ? (
          <HiCheck className={styles.checkControl} />
        ) : (
          <SavePropertiesButtonModuleRs
            sendingData={sendingData}
            handleChangeComponentTableRow={handleChangeComponentTableRow}
            saveProperty={saveProperty}
            isValid={isValid}
          />
        )}
      </td>
    </tr>
  );
}
