import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiCheck } from 'react-icons/hi';

import { useKernel } from '../../../../context/ContextKernel.mjs';

import { CreateNewObjectService } from '../../../../services/services.mjs';
import { InputNumber, InputText, LoadingInUserPageForm } from '../../../components.mjs';
import { createValidationSchemeByInputText, createValidationSchemeByObjectId } from '../../model/validationScheme.mjs';
import { createOptionsList } from '../../model/createOptionsList.mjs';

import styles from './templateObjectForm.module.scss';

export function TemplateObjectForm(props) {
  const { setComponentsTypeId, selectedParameter, setIsError, setErrorMessage, setConfiguration } = props;

  const createFolder = !selectedParameter?.isFolder; // папки можно создавать только в папках

  const location = useLocation();
  const [controllerType, setControllerType] = useState(22);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [showTooltip, setShowTooltip] = useState({
    controllerId: false,
    controllerName: false,
  });
  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const { kernel } = useKernel();

  const {
    register,
    setValue,
    getValues,
    trigger,
    unregister,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });

  const handleFocus = useCallback(
    ({ target }) => {
      const name = target.name;
      setShowTooltip({
        ...showTooltip,
        [name]: true,
      });
    },
    [showTooltip],
  );
  const handleBlur = useCallback(
    ({ target }) => {
      const name = target.name;
      setShowTooltip({
        ...showTooltip,
        [name]: false,
      });
    },
    [showTooltip],
  );

  const onSubmit = async (data) => {
    setSendingData(true);
    try {
      if (parseInt(controllerType) !== 12) {
        await CreateNewObjectService.createNewDevice(
          kernel.getActiveConfiguration().id,
          data.controllerId,
          data.controllerType,
          selectedParameter.id.toString(),
          data.controllerName,
        );
      } else {
        await CreateNewObjectService.createNewFolder(
          kernel.getActiveConfiguration().id,
          data.controllerType,
          selectedParameter.id.toString(),
          data.controllerName,
        );
      }

      kernel.unloadConfiguration();
      const { tree } = await kernel.buildTree(location);

      setSendingData(false);
      setDataSentSuccessfully(true);

      //строим дерево
      setConfiguration(tree);

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setComponentsTypeId(0);
      }, 1200);
    } catch (error) {
      setIsError(true);
      setSendingData(false);
      const errorMessage = error.toString().split('Error: ')[1];
      setErrorMessage(errorMessage);
      console.warn('error: ', error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles.titleText}>Добавление нового объекта в: </span>
        <span className={classNames(styles.titleText, styles.titleTextAccent)}>{selectedParameter?.displayName}</span>
      </div>
      <div className={styles.formWrap}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.formContainer}>
            <div className={styles.formItem}>
              <span className={styles.formItemText}>Выберите шаблон объекта:</span>
              <select
                value={controllerType}
                onInput={(event) => setControllerType(event.target.value)}
                className={classNames(styles.formItemSelect, sendingData && styles.sending)}
                disabled={sendingData}
                {...register('controllerType')}
              >
                {createOptionsList(createFolder).map((option) => {
                  return (
                    <option value={option.value} disabled={option.disabled} className={styles.formItemSelectOption}>
                      {option.text}
                    </option>
                  );
                })}
              </select>
            </div>
            {parseInt(controllerType) !== 12 && (
              <div className={styles.formItem}>
                <span className={styles.formItemText}>Укажите ID прибора:</span>
                <div className={styles.itemContainer}>
                  <InputNumber
                    value=""
                    setValue={setValue}
                    getValues={getValues}
                    trigger={trigger}
                    placeholder="Укажите ID прибора"
                    canEdit={false}
                    isWritable={true}
                    isDeleted={false}
                    min={0}
                    max={Number.MAX_SAFE_INTEGER}
                    step={1}
                    register={register}
                    unregister={unregister}
                    name="controllerId"
                    handleFocus={handleFocus}
                    showTooltip={showTooltip.controllerId}
                    errors={errors}
                    validationScheme={createValidationSchemeByObjectId(handleBlur)}
                    setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
                    sendingData={sendingData}
                  />
                </div>
              </div>
            )}

            <div className={styles.formItem}>
              <span className={styles.formItemText}>Укажите наименование объекта:</span>
              <div className={styles.itemContainer}>
                <InputText
                  value=""
                  setValue={setValue}
                  placeholder="Укажите наименование прибора"
                  canEdit={false}
                  isWritable={true}
                  isDeleted={false}
                  register={register}
                  unregister={unregister}
                  name="controllerName"
                  handleFocus={handleFocus}
                  showTooltip={showTooltip.controllerName}
                  errors={errors}
                  validationScheme={createValidationSchemeByInputText(handleBlur)}
                  setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
                  sendingData={sendingData}
                />
              </div>
            </div>
          </div>
          <button
            className={
              sendingData || dataSentSuccessfully
                ? styles.formSubmitSending
                : isValid
                ? classNames(styles.formSubmit, styles.formValid)
                : classNames(styles.formSubmit, styles.formNoValid)
            }
            disabled={!isValid}
          >
            {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Создать'}
          </button>
        </form>
      </div>
    </div>
  );
}
