import { AlarmListStruct } from '../quantum-lib/Arm/Parser/Alarm/AlarmListStruct.mjs';
import { AlarmStruct } from '../quantum-lib/Arm/Parser/Alarm/AlarmStruct.mjs';

import { setWorkingMode } from './services.mjs';


export class AlarmService {

  static async downloadingAlarmListStruct(idConfiguration) {
    return fetch(`${setWorkingMode()}/get-alarm-list-struct/${idConfiguration}`, {
      method: 'get',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const alarmListStruct = new AlarmListStruct();
        alarmListStruct.readFrom(dataView, offset, isLittleEndian);

        return alarmListStruct;
      });
  }

  static async createAlarm(idConfiguration, parameterId) {
    return fetch(`${setWorkingMode()}/create-alarm/${idConfiguration}`, {
      method: 'post',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ parameterId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const alarmStruct = new AlarmStruct();
        alarmStruct.readFrom(dataView, offset, isLittleEndian);

        return alarmStruct;
      });
  }

  static async changeAlarmArguments(idConfiguration, params) {
    return fetch(`${setWorkingMode()}/change-alarm-arguments/${idConfiguration}`, {
      method: 'put',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        const isLittleEndian = true;
        const offset = 0;
        const dataView = new DataView(arrayBuffer);

        const alarmStruct = new AlarmStruct();
        alarmStruct.readFrom(dataView, offset, isLittleEndian);

        return alarmStruct;
      });
  }

  static async deleteAlarm(idConfiguration, alarmId) {
    return fetch(`${setWorkingMode()}/delete-alarm/${idConfiguration}`, {
      method: 'delete',
      cache: 'reload',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ alarmId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка запроса, статус ${response.status}`);
        }
      });
  }
}