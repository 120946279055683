import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { HiCheck } from 'react-icons/hi';

import Utils from '../../utils/Utils.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { SavePropertiesButtonModuleRs } from '../components.mjs';

import styles from './tableRowSensorTypeModuleTcp.module.scss';

const sensorTypeOptionValue = [
  { valueOption: '1', textOption: 'ПТ100', entry: 1 },
  { valueOption: '2', textOption: 'ПТ100', entry: 2 },
  { valueOption: '3', textOption: 'ПТ500', entry: 1 },
  { valueOption: '4', textOption: 'ПТ500', entry: 2 },
];

export default function TableRowSensorTypeModuleTcp(props) {
  const { stateElementConfigurationId, selectedParameter, setIsError, setErrorMessage } = props;

  // вывод по входам (1 вход значение 1 и 3)
  let entry;
  selectedParameter?.parent?.children.forEach((parameterStruct, index) => {
    if (parameterStruct.id === stateElementConfigurationId) {
      entry = index + 1;
    }
  });

  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [errorParse, setErrorParse] = useState(false);
  const [changesDate, setChangesDate] = useState({ day: '', hour: '' }); // вывод даты и времени последнего изменения свойств (выводится время драйвер адреса)
  const [sensorTypeValue, setSensorTypeValue] = useState(); // состояние компонента

  const dataUpdateInputModeStatus = useRef(false); // изменилось ли значение компонента состояние компонента или нет
  const { kernel } = useKernel();

  const saveProperty = async () => {
    setHandleChangeComponentTableRow(false);
    setSendingData(true);
    try {
      const { driverAddressParameter } = createParametersSensorTypeObj();
      const newDriverAddressValue = createNewDriverAddress(sensorTypeValue, driverAddressParameter);

      const driverAddressParameterStruct = kernel.getParameterById(driverAddressParameter?.id); // драйвер адрес

      await kernel.setValues([{ parameterStruct: driverAddressParameterStruct, newValue: newDriverAddressValue }]);

      setDataSentSuccessfully(true);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setSendingData(false);
      }, 1500);
    } catch (error) {
      setSendingData(false);
      setIsError(true);
      setErrorMessage('Ошибка сохранения!');
      setHandleChangeComponentTableRow(true);
    }
  };

  const createNewDriverAddress = (sensorTypeValue, driverAddressParameter) => {
    const propertyValueRoundUp = driverAddressParameter.propertyValueRoundUp;
    let newValue = propertyValueRoundUp.toString().split('');
    newValue[3] = sensorTypeValue;
    newValue = newValue.join('');

    return newValue;
  };

  const handleChangeSelectInputMode = (event) => {
    setSensorTypeValue(event.target.value);

    const { driverAddressParameter } = createParametersSensorTypeObj();
    const driverAddressParameterParse = Utils.parseWorkingMode(driverAddressParameter);
    const initialInputMode = driverAddressParameterParse?.inputMode; //начальное значение компонента

    if (event.target.value.toString() === initialInputMode.toString() && !errorParse) {
      dataUpdateInputModeStatus.current = false;
    } else {
      dataUpdateInputModeStatus.current = true;
    }

    if (dataUpdateInputModeStatus.current) {
      setHandleChangeComponentTableRow(true);
    } else {
      setHandleChangeComponentTableRow(false);
    }
  };

  const createParametersSensorTypeObj = () => {
    const parametersSensorType = selectedParameter.properties.filter((property) => property.systemTypeId === 66);

    const parametersSensorTypeObj = {};
    parametersSensorType.forEach((parameterSensorType) => {
      parametersSensorTypeObj[parameterSensorType.systemTypeId] = parameterSensorType;
    });

    const driverAddressParameter = parametersSensorTypeObj[66];

    return { driverAddressParameter };
  };

  useEffect(() => {
    const { driverAddressParameter } = createParametersSensorTypeObj();

    if (driverAddressParameter) {
      const day = Utils.convertTimestamp(driverAddressParameter.propertyValueTimestamp);
      const hour = Utils.convertTimestampGetHour(driverAddressParameter.propertyValueTimestamp);
      setChangesDate({ day: day, hour: hour });
      const { multiplexerChannel } = Utils.parseWorkingMode(driverAddressParameter);

      if (entry === 1) {
        if ([1, 3].includes(parseInt(multiplexerChannel))) {
          setErrorParse(false);
          setSensorTypeValue(multiplexerChannel);
          setHandleChangeComponentTableRow(false);
        } else {
          setErrorParse(true);
          setSensorTypeValue(0);
          setHandleChangeComponentTableRow(false);
        }
      } else if (entry === 2) {
        if ([2, 4].includes(parseInt(multiplexerChannel))) {
          setErrorParse(false);
          setSensorTypeValue(multiplexerChannel);
          setHandleChangeComponentTableRow(false);
        } else {
          setErrorParse(true);
          setSensorTypeValue(0);
          setHandleChangeComponentTableRow(false);
        }
      }
    }

    return () => {};
  }, [stateElementConfigurationId]);

  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableRowTd}>
        <span className={styles.tableRowText} title={'Тип датчика'}>
          Тип датчика
        </span>
      </td>
      <td className={styles.tableRowTd}>
        {/* компонент */}
        <div className={styles.sensorType}>
          <select
            className={classNames(styles.selectInputMode, sendingData && styles.sending)}
            value={sensorTypeValue}
            onInput={handleChangeSelectInputMode}
            disabled={sendingData}
          >
            {sensorTypeOptionValue.map((sensorTypeOption) => {
              return (
                sensorTypeOption.entry === entry && (
                  <option key={sensorTypeOption.valueOption} value={sensorTypeOption.valueOption} className={styles.selectInputModeOption}>
                    {sensorTypeOption.textOption}
                  </option>
                )
              );
            })}
            {errorParse && (
              <option key={0} value={0} className={styles.selectInputModeOption}>
                Значение не определено
              </option>
            )}
          </select>
        </div>
        {/* компонент */}
      </td>
      <td className={styles.tableRowTd}>
        <span className={classNames(styles.tableRowText, styles.tableDate)}>{changesDate.day}</span>
        <span className={styles.tableRowText}>{changesDate.hour}</span>
      </td>
      <td className={styles.tableRowTd}>
        {dataSentSuccessfully ? (
          <HiCheck className={styles.checkControl} />
        ) : (
          <SavePropertiesButtonModuleRs
            sendingData={sendingData}
            handleChangeComponentTableRow={handleChangeComponentTableRow}
            saveProperty={saveProperty}
          />
        )}
      </td>
    </tr>
  );
}
