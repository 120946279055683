import { useEffect } from 'react';
import { HiCheck } from 'react-icons/hi';

import styles from './licenseAdditionStatus.module.scss';

export default function LicenseAdditionStatus(props) {
  const { setComponentsTypeId } = props;

  useEffect(() => {
    setTimeout(() => {
      setComponentsTypeId(0);
    }, 1500);
    return () => {};
  }, []);

  return (
    <div className={styles.licenseAdditionStatus}>
      <HiCheck className={styles.checkIcon} />
      <h2 className={styles.title}>Лицензия успешно сохранена</h2>
    </div>
  );
}
