import { useState } from 'react';
import { LuSettings2 } from 'react-icons/lu';
import { AiFillCode } from 'react-icons/ai';

import { Tabs } from '../tabs/Tabs';
import { useKernel } from '../../context/ContextKernel.mjs';

import { TemplateObjectForm, CustomObjectForm } from './ui/index.mjs';
import styles from './creatingNewObject.module.scss';


const createTabsItems = (currentLicense) => {
  return [
    {
      id: 1,
      icon: <LuSettings2 className="tabListIcon" />,
      text: 'Шаблонные',
      renderingConditions: currentLicense.hasAccessAddTemplateObjects === 'true',
    },
    {
      id: 2,
      icon: <AiFillCode className="tabListIcon" />,
      text: 'Произвольные',
      renderingConditions: currentLicense.hasAccessAddCustomObjects === 'true',
    },
  ];
};

export default function CreatingNewObject(props) {
  const { setComponentsTypeId, selectedParameter, setIsError, setErrorMessage, setConfiguration } = props;

  const [tabVisible, setTabVisible] = useState(1);
  const { currentLicense } = useKernel();

  const handleTabVisible = (event) => {
    const tabId = parseInt(event.currentTarget.dataset.tab);
    setTabVisible(tabId);
  };

  return (
    <div className={styles.container}>
      <Tabs tabsList={createTabsItems(currentLicense)} tabVisible={tabVisible} handleTabVisible={handleTabVisible} />
      <div className={styles.formsContainer}>
        {tabVisible === 1 && (
          <TemplateObjectForm
            setComponentsTypeId={setComponentsTypeId}
            selectedParameter={selectedParameter}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
            setConfiguration={setConfiguration}
          />
        )}
        {tabVisible === 2 && (
          <CustomObjectForm
            setComponentsTypeId={setComponentsTypeId}
            selectedParameter={selectedParameter}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
            setConfiguration={setConfiguration}
          />
        )}
      </div>
    </div>
  );
}
