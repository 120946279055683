import { useCodeMirror } from '@uiw/react-codemirror';
import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { HiCheck } from 'react-icons/hi';

import * as runParser from '../../../../utils/qs-srv-calc/RunParser.mjs';
import { InvalidSyntax } from '../../../../utils/qs-srv-calc/InvalidSyntax.mjs';
import { LoadingInUserPageForm } from '../../../components.mjs';
import { Terminal } from '../index.mjs';
import { SettlementServerSetupService } from '../../../../services/services.mjs';
import { useActiveConfigurationId } from '../../../../hooks/hooks.mjs';

import styles from './serverStartupOptions.module.scss';

export function ServerStartupOptions(props) {
  const {
    codeEditorStateActiveTask,
    codeEditorValue,
    setCodeEditorValue,
    setIsError,
    setErrorMessage,
    stateElementConfigurationId,
    codeEditorState,
    setCodeEditorState,
    sendingData,
    setSendingData,
    dataSentSuccessfully,
    setDataSentSuccessfully,
    kernel,
    treeTab,
  } = props;

  const editor = useRef();
  const [errors, setErrors] = useState({ status: false, errorMessage: '' });
  const { activeConfigurationId } = useActiveConfigurationId();

  const handleEditorChangeLaunchParameters = useCallback(
    (strValue, viewUpdate) => {
      setCodeEditorValue({
        code: strValue,
        codeLength: strValue.length,
      });

      // проверка на ошибки
      setErrors({
        status: strValue.length > 1275,
        errorMessage: strValue.length > 1275 ? 'Превышена допустимое количество символов!' : '',
      });
    },
    [setCodeEditorValue],
  );

  const { setContainer, view } = useCodeMirror({
    container: editor.current,
    onChange: handleEditorChangeLaunchParameters,
    value: codeEditorValue.code,
    theme: 'dark',
    width: '100%',
    height: '300px',
    allowMultipleSelections: true,
    autoFocus: true,
    // extensions: [wrapperQsSrvCalcRunLanguage()],
  });

  const checkSyntax = (code) => {
    let testFunction = true;

    try {
      runParser.parse(code); // проверка кода
      setErrors({
        status: false,
        errorMessage: '',
      });
    } catch (error) {
      if (error instanceof InvalidSyntax) {
        testFunction = false;
        const errorStr = `${error.message}`;
        setErrors({
          status: true,
          errorMessage: errorStr,
        });

        // выставляем курсор в начало позиции ошибки
        view.dispatch({
          selection: { anchor: error.position },
        });
        view.focus();
      } else {
        testFunction = false;
        setIsError(true);
        setErrorMessage('Ошибка сохранения!');
      }
    }

    return testFunction;
  };

  const saveLaunchParameters = async (value) => {
    const qsSrvCalcFuncTest = checkSyntax(codeEditorValue.code);

    if (qsSrvCalcFuncTest) {
      try {
        setSendingData(true);
        const taskId = codeEditorState.activeTaskId.toString();

        const newSettlementServerSetupStruct = await SettlementServerSetupService.changeTaskLaunchParameters(activeConfigurationId, taskId, value);

        kernel.setChangeTaskByTaskId(stateElementConfigurationId, taskId, newSettlementServerSetupStruct);

        // обновить состояния
        const settlementServerSetupStructList = kernel.getSettlementServerSetupByParameterId(stateElementConfigurationId);

        if (treeTab === 1) {
          setCodeEditorState({
            taskList: kernel.getSettlementServerSetupByParameterId(stateElementConfigurationId),
            activeTask: settlementServerSetupStructList.find((task) => task.id === BigInt(taskId)),
            activeTaskId: BigInt(taskId),
          });
        } else {
          const taskList = kernel.templateTasks[newSettlementServerSetupStruct.systemType].templateTasksList;
          const newTask = kernel.getTemplateTasksByParameterId(newSettlementServerSetupStruct.systemType, newSettlementServerSetupStruct.id);

          setCodeEditorState({
            activeTask: newTask,
            activeTaskId: newTask.id,
            taskList,
          });
        }

        setTimeout(() => {
          setDataSentSuccessfully(true);
          setSendingData(false);
        }, 800);

        setTimeout(() => {
          setDataSentSuccessfully(false);
        }, 1600);
      } catch (error) {
        setSendingData(false);
        setIsError(true);
        setErrorMessage('Ошибка сохранения!');
      }
    }
  };

  useEffect(() => {
    const strValue = codeEditorStateActiveTask?.launchParameters ?? '';
    setCodeEditorValue({ code: strValue, codeLength: strValue.length });
    return () => {};
  }, [codeEditorStateActiveTask.id]);

  useEffect(() => {
    if (editor.current) {
      setContainer(editor.current);
    }
    return () => {};
  }, [editor.current]);

  return (
    <div className={styles.codeEditorContainer}>
      <div className={styles.serverSettingsContainer}>
        <div className={styles.codeEditorHeader}>
          <span className={styles.codeEditorHeaderText}>
            <strong
              className={classNames(
                styles.codeEditorHeaderTextActive,
                codeEditorValue.codeLength > 1000 && styles.codeEditorHeaderTextWarningBorder,
                codeEditorValue.codeLength >= 1275 && styles.codeEditorHeaderTextErrorBorder,
              )}
            >
              {codeEditorValue.codeLength}
            </strong>
            /1275
          </span>
        </div>
        <div className={styles.editorWrap}>
          <div className={styles.editor} ref={editor}></div>
        </div>
        <Terminal errors={errors} />
      </div>
      <div className={styles.controlContainer}>
        <button
          className={
            sendingData || dataSentSuccessfully
              ? styles.creatingNewModuleFormSubmitSending
              : classNames(styles.creatingNewModuleFormSubmit, errors.status ? styles.formNoValid : styles.formValid)
          }
          onClick={() => saveLaunchParameters(codeEditorValue.code)}
          disabled={sendingData || dataSentSuccessfully || errors.status}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Сохранить'}
        </button>
      </div>
    </div>
  );
}
