import classNames from 'classnames';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Utils from '../../utils/Utils.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { PreloadList } from '../components.mjs';
import { IconTreeConfiguration } from '../../hoc/hoc.mjs';

import { preloadList } from './model/preloadList.mjs';
import styles from './treeConfiguration.module.scss';

const PARAMETER_CLASS_FOLDER = 0;
const PARAMETER_CLASS_OBJECT = 1;
const PARAMETER_CLASS_VALUE = 2;

export default memo(function TreeConfiguration(props) {
  const {
    configuration,
    stateElementConfigurationId,
    setStateElementConfigurationId,
    componentsTypeId,
    setComponentsTypeId,
    setSelectedParameter,
    removeObject,
    setRemoveObject,
    restoreObject,
    setRestoreObject,
    moveNodeInConfigurationTree,
    setIsError,
    setErrorMessage,
    updateConfiguration,
    parameterDisabled = false,
  } = props;

  const configurationClassify = configuration?.classify();
  const location = useLocation();
  const pathName = location?.pathname;

  const [showChildren, setShowChildren] = useState();
  const [dragZone, setDragZone] = useState({ canDrop: false, movementCheck: false });
  const { kernel, setContextMenu, currentLicense } = useKernel(); //работа с global state
  const ref = useRef();

  const {
    hasAccessAddTemplateObjects = undefined,
    hasAccessAddCustomObjects = undefined,
    hasAccessAddingDevices = undefined,
    hasAccessAddModules = undefined,
    hasAccessDeleteObjects = undefined,
    hasAccessRecoveryObject = undefined,
    hasAccessMovingObjects = undefined,
    hasAccessAssignRightsObjects = undefined,
    hasAccessAddTemplateParameters = undefined,
    hasAccessAddCustomParameters = undefined,
    hasAccessAddProperty = undefined,
    hasAccessDeleteParameters = undefined,
    hasAccessRecoveryParameters = undefined,
  } = currentLicense;

  const setStyleParameterDisabled = () => {
    if (parameterDisabled) {
      return configurationClassify !== PARAMETER_CLASS_VALUE ? false : [0, 11, 12, 13, 14, 15, 16].includes(configuration.typeNode.systemType.type);
    }
    return false;
  };

  const handleClick = useCallback(
    (id) => {
      setComponentsTypeId(0);
      kernel.removeHandleDataPageAllState();

      kernel.setElementConfigurationId(BigInt(id));
      setStateElementConfigurationId(kernel.elementConfigurationId);
    },
    [kernel, setComponentsTypeId, setStateElementConfigurationId],
  );

  const handleDoubleClick = useCallback(() => {
    setShowChildren(!showChildren);
  }, [showChildren]);

  const handleContextMenu = useCallback(
    (event) => {
      const id = event.currentTarget.dataset.id;
      if (pathName === '/property-editor' || pathName === '/users') {
        event.preventDefault();
        const { clientX, clientY } = event;

        kernel.setElementConfigurationId(BigInt(id));
        const parameter = kernel.getParameterById(id); //получение параметра на котором было вызвано контекстное меню
        const isDeleted = parameter?.isDeleted;
        const isModularController = parameter?.blockType?.id === 40;
        const isModuleRs = parameter?.blockType?.id === 44;

        let contextMenuSettingsTreeEditor = [
          {
            id: 0,
            text: 'Добавить объект',
            renderElement:
              ((![40, 44, 3, 4, 41, 42, 43, 44, 45, 46, 47, 48, 32, 31, 49, 33, 36, 50, 51, 34, 52].includes(parameter?.blockType?.id) &&
                configurationClassify !== PARAMETER_CLASS_VALUE &&
                !isDeleted) ||
                parameter.id === 0n) &&
              (hasAccessAddTemplateObjects === 'true' || hasAccessAddCustomObjects === 'true'),
            isActive: componentsTypeId === 2,
            onClick: () => {
              setSelectedParameter(parameter);
              setComponentsTypeId(2);
            },
          },
          {
            id: 1,
            text: 'Добавить модуль',
            renderElement: isModularController && !isDeleted && hasAccessAddModules === 'true',
            isActive: componentsTypeId === 3,
            onClick: () => {
              setComponentsTypeId(3);
              setSelectedParameter(parameter);
            },
          },
          {
            id: 2,
            text: 'Добавить устройство',
            renderElement: isModuleRs && !isDeleted && hasAccessAddingDevices === 'true',
            isActive: componentsTypeId === 4,
            onClick: () => {
              setComponentsTypeId(4);
              setSelectedParameter(parameter);
            },
          },
          {
            id: 8,
            text: 'Добавить параметр',
            renderElement:
              configurationClassify !== PARAMETER_CLASS_VALUE &&
              (hasAccessAddTemplateParameters === 'true' || hasAccessAddCustomParameters === 'true'),
            isActive: componentsTypeId === 6,
            onClick: () => {
              setComponentsTypeId(6);
              setSelectedParameter(parameter);
            },
          },
          {
            id: 9,
            text: 'Добавить свойство',
            renderElement: hasAccessAddProperty === 'true',
            isActive: componentsTypeId === 7,
            onClick: () => {
              setComponentsTypeId(7);
              setSelectedParameter(parameter);
            },
          },
          {
            id: 3,
            text: 'Редактировать параметры',
            renderElement: true,
            isActive: componentsTypeId === 0,
            onClick: () => {
              setComponentsTypeId(0);
              setStateElementConfigurationId(kernel.elementConfigurationId);
            },
          },
          {
            id: 4,
            text: 'Пометить на удаление объект',
            renderElement: configurationClassify !== PARAMETER_CLASS_VALUE && parameter.id !== 0n && !isDeleted && hasAccessDeleteObjects === 'true',
            isActive: false,
            onClick: () => {
              setRemoveObject({
                ...removeObject,
                objectId: parameter.id,
                isVisible: true,
                message: `Вы точно хотите пометить на удаление объект "${parameter.displayName}"?`,
              });
            },
          },
          {
            id: 5,
            text: 'Восстановить объект',
            renderElement: configurationClassify !== PARAMETER_CLASS_VALUE && parameter.id !== 0n && isDeleted && hasAccessRecoveryObject === 'true',
            isActive: false,
            onClick: () => {
              setRestoreObject({
                ...restoreObject,
                objectId: parameter.id,
                isVisible: true,
                message: `Вы точно хотите восстановить объект "${parameter.displayName}"?`,
              });
            },
          },
          {
            id: 6,
            text: 'Обновить дерево конфигурации',
            renderElement: true,
            isActive: false,
            onClick: () => {
              updateConfiguration();
            },
          },
          {
            id: 7,
            text: 'Установить права на объект',
            renderElement: hasAccessAssignRightsObjects === 'true',
            isActive: componentsTypeId === 5,
            onClick: () => {
              setComponentsTypeId(5);
              setStateElementConfigurationId(kernel.elementConfigurationId);
            },
          },
          {
            id: 8,
            text: 'Пометить на удаление параметр',
            renderElement:
              configurationClassify === PARAMETER_CLASS_VALUE && parameter.id !== 0n && !isDeleted && hasAccessDeleteParameters === 'true',
            isActive: false,
            onClick: () => {
              setRemoveObject({
                ...removeObject,
                objectId: parameter.id,
                isVisible: true,
                message: `Вы точно хотите пометить на удаление параметр "${parameter.displayName}"?`,
              });
            },
          },
          {
            id: 9,
            text: 'Восстановить параметр',
            renderElement:
              configurationClassify === PARAMETER_CLASS_VALUE && parameter.id !== 0n && isDeleted && hasAccessRecoveryParameters === 'true',
            isActive: false,
            onClick: () => {
              setRestoreObject({
                ...restoreObject,
                objectId: parameter.id,
                isVisible: true,
                message: `Вы точно хотите восстановить параметр "${parameter.displayName}"?`,
              });
            },
          },
        ];

        const contextMenuSettingsActiveItemState = contextMenuSettingsTreeEditor.find((item) => item.isActive);

        if (contextMenuSettingsActiveItemState.renderElement) {
          contextMenuSettingsActiveItemState.onClick();
        } else {
          setComponentsTypeId(0);
          setStateElementConfigurationId(kernel.elementConfigurationId);

          contextMenuSettingsTreeEditor = contextMenuSettingsTreeEditor.map((itemState) => {
            if (itemState.id === 3) {
              return {
                ...itemState,
                isActive: true,
              };
            }
            return itemState;
          });
        }

        setContextMenu(contextMenuSettingsTreeEditor, [clientX, clientY], parameter);
      }
    },
    [setContextMenu, componentsTypeId],
  );

  const dragStartHandler = (event) => {
    event.stopPropagation();
    const nodeId = +event.currentTarget.dataset.id;

    kernel.setDragStartNodeId(nodeId);
  };

  const dragOverHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const nodeId = +event.currentTarget.dataset.id;
    const dragStartNodeId = kernel.getDragStartNodeId();
    const treeNodeDrag = kernel.getParameterById(dragStartNodeId); // элемент который перетаскивают
    const treeNodeDragOver = kernel.getParameterById(nodeId);

    const dragZoneCheck = Utils.dragZoneCheck(treeNodeDrag, treeNodeDragOver, kernel);
    setDragZone(dragZoneCheck);
  };

  const dragLeaveHandler = (event) => {
    event.stopPropagation();
    setDragZone({ canDrop: false, movementCheck: false });
  };

  const dragDropHandler = (event) => {
    const dragOverNodeId = event.currentTarget.dataset.id;

    event.preventDefault();
    event.stopPropagation();
    setDragZone({ canDrop: false, movementCheck: false });
    const dragStartNodeId = kernel.getDragStartNodeId();
    const treeNodeDrag = kernel.getParameterById(dragStartNodeId); // элемент который перетаскивают
    const treeNodeDragOver = kernel.getParameterById(dragOverNodeId);

    if (treeNodeDrag.id !== treeNodeDragOver.id) {
      const moveNodeCheck = Utils.moveNodeCheck(treeNodeDrag, treeNodeDragOver, kernel);

      if (moveNodeCheck.status === true) {
        moveNodeInConfigurationTree(treeNodeDrag, treeNodeDragOver);
      } else {
        // установить ошибку и вывести текст ошибки
        setIsError(true);
        setErrorMessage(moveNodeCheck.message);
      }
    }
  };

  useEffect(() => {
    if (configuration) {
      const parameterStruct = kernel.getParameterById(stateElementConfigurationId);
      const path = parameterStruct.path;
      const configurationId = BigInt(configuration?.id);
      const isParent = path.find((item) => item.id === configurationId);

      isParent ? setShowChildren(true) : setShowChildren(false);
    }

    return () => {};
  }, [configuration]);

  // выделение элемента и фокус на нем
  useEffect(() => {
    if (configuration) {
      if (configuration.id === BigInt(stateElementConfigurationId)) {
        const element = ref.current;
        element.scrollIntoView();
      }
    }

    return () => {};
  }, []);

  return (
    <div className={classNames(styles.tree)}>
      {configuration ? (
        configuration.isTypeNode ? (
          <></>
        ) : (
          <div className={classNames(styles.treeWrapper)} data-id={configuration.id}>
            <div
              ref={ref}
              className={classNames(
                styles.parentElem,
                Number(stateElementConfigurationId) === Number(configuration.id) && styles.parentElemActive,
                dragZone.canDrop ? (dragZone.movementCheck ? styles.dragZone : styles.dragZoneError) : null,
              )}
              onContextMenu={handleContextMenu}
              onClick={(event) => handleClick(event.currentTarget.dataset.id)}
              onDoubleClick={(event) => handleDoubleClick(event.currentTarget.dataset.id)}
              data-id={configuration.id}
              draggable={
                configurationClassify !== PARAMETER_CLASS_VALUE &&
                ![3, 4, 41, 42, 43, 44, 45, 46, 47, 48, 32, 31, 49, 33, 36, 50, 51, 34, 52].includes(configuration?.blockType?.id) &&
                pathName === '/property-editor' &&
                hasAccessMovingObjects === 'true'
              }
              onDragStart={dragStartHandler}
              onDragLeave={dragLeaveHandler}
              onDragOver={dragOverHandler}
              onDrop={dragDropHandler}
            >
              <IconTreeConfiguration
                showChildren={showChildren}
                typeElementTree={configurationClassify}
                isDeleted={configuration.isDeleted || setStyleParameterDisabled()}
              />

              <span
                className={classNames(
                  styles.parentElemText,
                  configuration.isDeleted && styles.isDeleted,
                  setStyleParameterDisabled() && styles.isDeleted,
                )}
              >
                {kernel.getUser().settings.hasParameterIdInTree && `#${configuration.id.toString()}`} {configuration.displayName}
              </span>
            </div>
            <div
              className={
                configuration.children.length > 0
                  ? !showChildren
                    ? styles.childrenElem
                    : classNames(styles.childrenElem, styles.childrenElemActive)
                  : styles.childrenElem
              }
            >
              {configurationClassify !== PARAMETER_CLASS_VALUE &&
                showChildren &&
                configuration.children.map((data) =>
                  data.isTypeNode ? (
                    <></>
                  ) : (
                    <TreeConfiguration
                      key={data.id}
                      configuration={data}
                      stateElementConfigurationId={stateElementConfigurationId}
                      setStateElementConfigurationId={setStateElementConfigurationId}
                      componentsTypeId={componentsTypeId}
                      setComponentsTypeId={setComponentsTypeId}
                      setSelectedParameter={setSelectedParameter}
                      removeObject={removeObject}
                      setRemoveObject={setRemoveObject}
                      restoreObject={restoreObject}
                      setRestoreObject={setRestoreObject}
                      moveNodeInConfigurationTree={moveNodeInConfigurationTree}
                      setIsError={setIsError}
                      setErrorMessage={setErrorMessage}
                      updateConfiguration={updateConfiguration}
                      parameterDisabled={parameterDisabled}
                    />
                  ),
                )}
            </div>
          </div>
        )
      ) : (
        <PreloadList preloadSizeElement={preloadList()} />
      )}
    </div>
  );
});
