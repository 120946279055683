import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './tabs.module.scss';

export function Tabs(props) {
  const { tabsList, tabVisible, handleTabVisible } = props;

  return (
    <div className={styles.tabList}>
      {tabsList.map((tabsListItem) => {
        return (
          tabsListItem.renderingConditions && (
            <div
              key={tabsListItem.id}
              data-tab={tabsListItem.id}
              className={classNames(styles.tabListItem, tabVisible === tabsListItem.id && styles.tabListItemActive)}
              onClick={handleTabVisible}
            >
              {tabsListItem.icon}
              <span className={styles.tabListItemText}>{tabsListItem.text}</span>
            </div>
          )
        );
      })}
    </div>
  );
}

Tabs.propTypes = {
  tabsList: PropTypes.array.isRequired,
  tabVisible: PropTypes.number.isRequired,
  handleTabVisible: PropTypes.func.isRequired,
};
