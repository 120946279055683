import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { HiCheck } from 'react-icons/hi';
import { TiEye } from 'react-icons/ti';

import { useKernel } from '../../context/ContextKernel.mjs';
import { UsersService } from '../../services/services.mjs';
import { LoadingInUserPageForm } from '../components.mjs';

import styles from './userForm.module.scss';

export default function UserForm(props) {
  const { idTreeElement, setIsError, setErrorMessage, usersTree, initialStateUserTree, setUsersTree, setInitialStateUserTree, setComponentsTypeId } =
    props;

  const { kernel } = useKernel();

  const groupId = idTreeElement;
  const groups = kernel.usersGroups;
  const groupsList = Object.values(groups);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const [tiEyeButtonState, setTiEyeButtonState] = useState(false);
  const [nameUserType, setNameUserType] = useState('password');

  const [nameUserInputValue, setNameUserInputValue] = useState();
  const [passwordUserInputValue, setPasswordUserInputValue] = useState();
  const [userFormGroupSelectValue, setUserFormGroupSelectValue] = useState();
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);

  const handleChangePasswordUserInput = (event) => {
    setNameUserInputValue(event.target.value);
  };

  const handleChangepassworUserInput = (event) => {
    setPasswordUserInputValue(event.target.value);
  };

  const handleChangeUserFormGroupSelect = (event) => {
    setUserFormGroupSelectValue(event.target.value);
  };

  const onSubmit = async (data) => {
    setSendingData(true);
    try {
      const response = await UsersService.usersCreatingNewUser(data);

      //запись в kernel и добавление в state
      const newUser = kernel.addNewUserInParametersUserTree(response);
      setUsersTree(
        usersTree.map((group) => {
          if (group.id === newUser.groupId) {
            group.children = [...group.children];
          }
          return group;
        }),
      );
      setInitialStateUserTree(
        initialStateUserTree.map((group) => {
          if (group.id === newUser.groupId) {
            group.children = [...group.children];
          }
          return group;
        }),
      );

      setTimeout(() => {
        setSendingData(false);
        setDataSentSuccessfully(true);
      }, 800);

      setTimeout(() => {
        setDataSentSuccessfully(false);
        setComponentsTypeId(0);
      }, 1600);
    } catch (error) {
      setSendingData(false);
      //сообщение об ошибке
      setIsError(true);
      const errorMessage = error.toString().split('Error: ')[1];
      setErrorMessage(errorMessage);
      console.warn('error: ', error);
    }
  };

  const userFormButtonBackClick = () => {
    setComponentsTypeId(0);
  };

  const showPasswordFieldValues = (event) => {
    setTiEyeButtonState(!tiEyeButtonState);
    if (tiEyeButtonState) {
      setNameUserType('password');
    } else {
      setNameUserType('text');
    }
  };

  const initialData = () => {
    if (groupId) {
      setNameUserInputValue('');
      setPasswordUserInputValue('');
      setUserFormGroupSelectValue(groupId);
      setValue('groupUser', groupId);
    } else {
      setNameUserInputValue('');
      setPasswordUserInputValue('');
      setUserFormGroupSelectValue(2);
    }
  };

  useEffect(() => {
    initialData();
    return () => {};
  }, [idTreeElement, groupId]);

  return (
    <div className={styles.userForm}>
      <div className={styles.userFormHeader}>
        <h2 className={styles.userFormTitle}>Новый пользователь</h2>
        <BsArrowLeftCircle className={styles.userFormButtonBack} onClick={userFormButtonBackClick} />
      </div>
      <form className={styles.userFormForm} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.container}>
          <div className={styles.inputWrap}>
            <input
              className={classNames(styles.input, errors.nameUser && styles.inputError)}
              type="text"
              placeholder="Имя пользователя"
              value={nameUserInputValue}
              onInput={handleChangePasswordUserInput}
              readOnly={sendingData || dataSentSuccessfully}
              {...register('nameUser', {
                required: 'Поле обязательно к заполнению',
                minLength: {
                  value: 3,
                  message: 'Поле должно содержать минимум 3 символа',
                },
                maxLength: {
                  value: 255,
                  message: 'Поле может содержать максимум 255 символа',
                },
              })}
            />
            {errors?.nameUser && <p className={styles.errorMessage}>{errors?.nameUser?.message || 'Ошибка заполнения!'}</p>}
          </div>
          <div className={styles.inputWrap}>
            <input
              className={classNames(styles.input, styles.inputPassword, errors.passwordUser && styles.inputError)}
              type={nameUserType}
              placeholder="Пароль"
              autoComplete="new-password"
              value={passwordUserInputValue}
              onInput={handleChangepassworUserInput}
              readOnly={sendingData || dataSentSuccessfully}
              {...register('passwordUser', {
                required: 'Поле обязательно к заполнению',
                minLength: {
                  value: 5,
                  message: 'Поле должно содержать минимум 5 символов',
                },
                maxLength: {
                  value: 255,
                  message: 'Поле может содержать максимум 255 символа',
                },
              })}
            />
            <TiEye className={classNames(styles.tiEyeButton, tiEyeButtonState && styles.tiEyeButtonActive)} onClick={showPasswordFieldValues} />
            {errors?.passwordUser && <p className={styles.errorMessage}>{errors?.passwordUser?.message || 'Ошибка заполнения!'}</p>}
          </div>

          <select
            className={styles.userFormGroupSelect}
            value={userFormGroupSelectValue}
            onInput={handleChangeUserFormGroupSelect}
            disabled={dataSentSuccessfully}
            {...register('groupUser')}
          >
            {groupsList.map((group) => (
              <option className={styles.userFormGroupSelectOption} key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </select>
        </div>
        <button
          className={
            sendingData || dataSentSuccessfully
              ? styles.buttonSending
              : isValid
                ? classNames(styles.button, styles.formValid)
                : classNames(styles.button, styles.formNoValid)
          }
          type="submit"
          disabled={!isValid}
        >
          {dataSentSuccessfully ? <HiCheck className={styles.checkControl} /> : sendingData ? <LoadingInUserPageForm /> : 'Создать'}
        </button>
      </form>
    </div>
  );
}
