import { Observer } from '../quantum-lib/Scada/Observer/Observer.mjs';
import { SystemType } from '../quantum-lib/Scada/Parameters/SystemType.mjs';
import { BlockType } from '../quantum-lib/Scada/Parameters/BlockType.mjs';
import { ParameterStruct } from '../quantum-lib/Scada/Parser/Configurations/ParameterStruct.mjs';
import { Configuration } from '../quantum-lib/Scada/Parameters/Configuration.mjs';
import { AuthService, ConfigurationService, LicensesService, ReferenceBooksService, AlarmService } from '../services/services.mjs';
import { User } from '../quantum-lib/Arm/Parameters/User.mjs';
import { UsersGroup } from '../quantum-lib/Arm/Parameters/UsersGroup.mjs';
import { ParametersExtended } from '../quantum-lib/Arm/Parameters/ParametersExtended.mjs';
import { StringStruct } from '../quantum-lib/Scada/Parser/StringStruct.mjs';
import { UserStructExtended } from '../quantum-lib/Arm/Parser/Users/UserStructExtended.mjs';
import { EditorSettings } from '../quantum-lib/Arm/Parameters/EditorSettings.mjs';
import { PropertyHistory } from '../quantum-lib/Arm/Parameters/PropertyHistory.mjs';
import { LicenseType } from '../quantum-lib/Arm/Parameters/LicenseType.mjs';
import { License } from '../quantum-lib/Arm/Parameters/License.mjs';
import { LicensesTypeStruct } from '../quantum-lib/Arm/Parser/Licenses/LicensesTypeStruct.mjs';
import { LicensesStruct } from '../quantum-lib/Arm/Parser/Licenses/LicensesStruct.mjs';
import { SettlementServerSetup } from '../quantum-lib/Arm/Parameters/SettlementServerSetup.mjs';
import { Rules } from '../quantum-lib/Arm/Parameters/Rules.mjs';
import { RulesStruct } from '../quantum-lib/Arm/Parser/Configurations/RulesStruct.mjs';
import { TaskServer } from '../quantum-lib/Arm/Parameters/TaskServer.mjs';
import { EnumTypeTask } from '../quantum-lib/Enum/TaskServerEnum.mjs';
import { TemplateTaskSrvCalc } from '../quantum-lib/Arm/Parameters/TemplateTaskSrvCalc.mjs';
import { Setpoints } from '../quantum-lib/Arm/Parameters/Setpoints.mjs';
import { TemplateSetpoints } from '../quantum-lib/Arm/Parameters/TemplateSetpoints.mjs';
import { Alarm } from '../quantum-lib/Arm/Parameters/Alarm.mjs';

import { RemoteServersPool } from './RemoteServersPool.mjs';



class Kernel extends Observer {
  constructor(parametersListStruct, systemTypesListStruct, blockTypesListStruct, disableTransforms = true) {
    super();

    this.parameters = {};

    this.users = {};
    this.usersGroups = {};

    this.licensesList = {};
    this.licensesTypesList = {};
    this.licensesFile = {};

    this.systemTypes = {};
    this.blockTypes = {};
    this.editorSettings = {};

    this.maxParameterId = undefined;
    this.disableTransforms = disableTransforms;
    this.remoteServersPool = new RemoteServersPool(this);

    this.user = {
      id: null, //id user
      name: null, //name user
      token: null, //token user
      groupId: null, // уникальный идентификатор из таблицы Groups
      settings: {}, // настройки пользователя 
      isAuthorization: false,//авторизован пользователь или нет
    };

    this.elementConfigurationId = null;//id активного элемента в дереве конфигурации
    this.elementUsersTreeId = null;//id активного элемента в дереве пользователей
    this.elementLicensesTreeId = null;//id активного элемента в дереве лицензий
    this.flagRefreshUpdateTokenUser = false;//запущено ли обновление токена
    this.refreshTokenUser = null;//id setInterval обновляющего токен

    this.handleDataPageState = {};

    this.propertyHistoryList = {};//история изменения конкретного параметра

    this.oldActiveElementInTreeUser = {
      oldActiveElementTypeInTreeUser: null,//тип выделенного элемента если 0, то user, если 1 то группа
      oldActiveGroupInTreeUser: null,//id предыдущего активного элемента группы в дереве пользователей
      oldActiveUserInTreeUser: null,//id предыдущего активного элемента пользователя в дереве пользователей
    };

    this.navigatePath = null; // путь откуда пришел пользователь на страницу выбора конфигурации
    this.dragStartNodeId = null;
    this.dragOverNodeId = null;

    this.rules = {}; // права на объекты конфигурации

    this.idRemoteConfigurationObjects = []; // id удаленных объектов

    this.srvCalcDictionary = {}; // словарь функций для сервера расчетов

    this.settlementServerSetup = {}; // настройки сервера расчетов

    this.templateTasks = {}; // шаблонные задачи для сервера расчетов

    this.tasksForTaskServer = {}; //задачи для сервера задач

    this.setpoints = {}; // уставки
    this.templateSetpoints = {}; // шаблонные уставки

    this.alarms = {}; // тревоги

    this.justOneConfiguration = false;

    this.settings = {
      isRootUser: JSON.parse(localStorage.getItem("isRootUser")) || false,
      hasParameterIdInTree: JSON.parse(localStorage.getItem("hasParameterIdInTree")) || false,
    };

    this.stateElementConfigurationId = 0n;
  }

  // сервер тревог
  buildAlarm(alarmListStruct) {
    this.alarms = {};

    alarmListStruct.forEach((alarmStruct) => {
      if (this.alarms[alarmStruct.parameterId]) {
        this.alarms[alarmStruct.parameterId].push(new Alarm(this, alarmStruct));
      } else {
        this.alarms[alarmStruct.parameterId] = [new Alarm(this, alarmStruct)];
      }
    });
  }

  getAlarmListByParameterId(parameterId) {
    return this.alarms[parameterId];
  }

  getAlarmByParameterId(parameterId, alarmId) {
    return this.alarms[parameterId].find((alarmStruct) => alarmStruct.id == alarmId);
  }

  getAlarmById(alarmId) {
    return Object.values(this.alarms).flat().find(alarm => alarm.id == alarmId);
  }

  removeAlarmByParameterId(parameterId, alarmId) {
    this.alarms[parameterId] = this.alarms[parameterId].filter((alarmStruct) => alarmStruct.id != alarmId);
  }

  async downloadingAlarm() {
    try {
      const configurationId = this.getActiveConfiguration()?.id || JSON.parse(localStorage.getItem('activeIdConfig'))?.id;
      const alarmListStruct = (await AlarmService.downloadingAlarmListStruct(configurationId)).items;
      this.buildAlarm(alarmListStruct);
    } catch (error) {
      console.error('error: ', error);
    }
  }

  addNewAlarm(newAlarm) {
    if (this.alarms[newAlarm.parameterId]) {
      this.alarms[newAlarm.parameterId].push(new Alarm(this, newAlarm));
    } else {
      this.alarms[newAlarm.parameterId] = [new Alarm(this, newAlarm)];
    }
  }

  changeAlarmByParameterId(parameterId, alarmId, changeAlarm) {
    alarmId = parseInt(alarmId);

    if (changeAlarm.parameterId === parseInt(parameterId)) {
      this.alarms[parameterId] = this.alarms[parameterId].map((alarm) => {
        if (alarm.id === alarmId) {
          return new Alarm(this, changeAlarm);
        }
        return alarm;
      });
    } else {
      this.alarms[parameterId] = this.alarms[parameterId].filter(alarm => alarm.id !== alarmId);
      this.addNewAlarm(changeAlarm);
    }

  }
  // сервер тревог

  // загрузка и формирование дерева конфигурации
  setIdConfigInPageState(location) {
    if (location.state === null) {
      return JSON.parse(localStorage.getItem('activeIdConfig'));
    } else {
      return location.state;
    }
  };

  async downloadingBlockTypes() {
    if (this.getBlockTypes().length === 0) {
      const blockTypesList = await ReferenceBooksService.blockTypesList();
      this.buildBlockTypes(blockTypesList.items);
    }
  };

  async downloadingSystemTypes() {
    if (this.getSystemTypes().length === 0) {
      const systemTypesList = await ReferenceBooksService.systemTypesList();
      this.buildSystemTypes(systemTypesList.items);
    }
  };

  async downloadingEditorSettings() {
    if (this.getEditorSettings().length === 0) {
      const editorSettingsList = await ReferenceBooksService.editorSettingsList();
      this.buildEditorSettings(editorSettingsList.items);
    }
  };

  async downloadingConfigurationElem() {
    const configurationData = await ConfigurationService.getConfigurationElem();
    return configurationData.items;
  };

  async specificConfiguration(idConfigInPageState) {
    const [configuration, idRemoteConfigurationObjects] = await Promise.all([
      ConfigurationService.getSpecificConfiguration(idConfigInPageState),
      ConfigurationService.getIdRemoteConfigurationObjects(idConfigInPageState),
    ]);

    return { configuration, idRemoteConfigurationObjects };
  };

  createTree(configuration) {
    const configurationElement = configuration.items;
    this.buildParametersTree(configurationElement);
    return this.getRootParameter();
  };

  async buildTree(location) {
    const [blockTypesList, systemTypesList, editorSettingsList, configurationData] = await Promise.all([
      //получаем  типы блоков в сценарии когда пользователь обновил страницу
      this.downloadingBlockTypes(),
      //получаем  системных типов в сценарии когда пользователь обновил страницу
      this.downloadingSystemTypes(),
      // получаем editorSettings в сценарии когда пользователь обновил страницу
      this.downloadingEditorSettings(),
      // получаем пользователей в сценарии когда пользователь обновил страницу
      this.downloadingConfigurationElem(),
    ]);

    const idConfigInPageState = await this.setIdConfigInPageState(location);
    const activeConfiguration = configurationData.find((item) => parseInt(item.id) === parseInt(idConfigInPageState.id));

    if (activeConfiguration === undefined) throw new Error(); // в случае если в памяти некорректный id конфигурации

    this.unloadConfiguration();
    this.buildActiveConfiguration(activeConfiguration);

    const result = await this.specificConfiguration(idConfigInPageState.id);

    const { configuration, idRemoteConfigurationObjects } = result;
    this.idRemoteConfigurationObjects = idRemoteConfigurationObjects;
    const tree = this.createTree(configuration);

    return { tree };
  };
  // загрузка и формирование дерева конфигурации

  // работа с уставками
  buildSetpoints(setpointsListStruct) {
    this.setpoints = {};

    setpointsListStruct.forEach((setpoint) => {
      if (this.setpoints[setpoint.parameterId]) {
        this.setpoints[setpoint.parameterId].push(new Setpoints(this, setpoint));
      } else {
        this.setpoints[setpoint.parameterId] = [new Setpoints(this, setpoint)];
      }
    });

    if (this.setpoints[0]) {
      this.buildTemplateSetpointsTree(this.setpoints[0]);
    }
  }

  getSetpointsListByParameterId(parameterId) {
    return this.setpoints[parameterId];
  }

  getSetpointsByParameterId(parameterId, id) {
    return this.setpoints[parameterId].find(setpoint => parseInt(id) === setpoint.id);
  }

  getTemplateSetpointsByParameterId(systemTypeId, parameterId) {
    return this.templateSetpoints[systemTypeId].templateSetpointsList.find(templateSetpoints => parameterId === templateSetpoints.id);
  }

  removeSetpointsByParameterId(parameterId, setpointId) {
    this.setpoints[parameterId] = this.setpoints[parameterId].filter((setpointStruct) => setpointStruct.id != setpointId);

    if (this.setpoints[0]) {
      this.buildTemplateSetpointsTree(this.setpoints[0]);
    }
  }

  buildTemplateSetpointsTree(templateSetpointsList) {
    this.templateSetpoints = {};
    const templateSetpointsById = {};

    templateSetpointsList.forEach((templateSetpoint) => {
      if (templateSetpointsById[templateSetpoint.systemTypeId]) {
        templateSetpointsById[templateSetpoint.systemTypeId].push(templateSetpoint);
      } else {
        templateSetpointsById[templateSetpoint.systemTypeId] = [templateSetpoint];
      }
    });

    for (const key in templateSetpointsById) {
      this.templateSetpoints[key] = new TemplateSetpoints(this, templateSetpointsById[key], key);
    }
  }

  getTemplateSetpointsList() {
    return Object.values(this.templateSetpoints);
  }

  setNewSetpoint(setpointStruct) {
    if (this.setpoints[setpointStruct.parameterId]) {
      this.setpoints[setpointStruct.parameterId].push(new Setpoints(this, setpointStruct));
    } else {
      this.setpoints[setpointStruct.parameterId] = [new Setpoints(this, setpointStruct)];
    }
  }

  setChangeSetpointBySetpointId(parameterId, setpointId, newSetpoint) {
    setpointId = parseInt(setpointId);

    if (newSetpoint.parameterId.toString() === parameterId.toString()) {
      this.setpoints[parameterId] = this.setpoints[parameterId].map((setpoint) => {
        if (setpoint.id === setpointId) {
          return new Setpoints(this, newSetpoint);
        }
        return setpoint;
      });
    } else {
      this.setpoints[parameterId] = this.setpoints[parameterId].filter((setpoint) => setpoint.id !== setpointId);
      this.setNewSetpoint(newSetpoint);
    }

    if (this.setpoints[0]) {
      this.buildTemplateSetpointsTree(this.setpoints[0]);
    }
  }

  setNewTemplateSetpoint(setpointStruct) {
    // вставляем в основную структуру
    this.setNewSetpoint(setpointStruct);

    if (this.setpoints[0]) {
      this.buildTemplateSetpointsTree(this.setpoints[0]);
    };
  }
  // работа с уставками

  // работа с сервером расчетов 
  getSettlementServerSetupByParameterId(parameterId) {
    return this.settlementServerSetup[parameterId];
  }

  getTemplateTasksByParameterId(systemType, parameterId) {
    return this.templateTasks[systemType].tasksList.find(templateTask => parameterId === templateTask.id);
  }

  buildTemplateTaskTree(templateTaskList) {
    this.templateTasks = {};
    const templateSetpointsById = {};

    templateTaskList.forEach((templateTask) => {
      if (templateSetpointsById[templateTask.systemTypeId]) {
        templateSetpointsById[templateTask.systemTypeId].push(templateTask);
      } else {
        templateSetpointsById[templateTask.systemTypeId] = [templateTask];
      }
    });

    for (const key in templateSetpointsById) {
      this.templateTasks[key] = new TemplateTaskSrvCalc(this, templateSetpointsById[key], key);
    }
  }

  buildSettlementServerSetup(settlementServerSetupListStruct) {
    this.settlementServerSetup = {};

    settlementServerSetupListStruct.forEach((settlementServerSetupStruct) => {
      if (this.settlementServerSetup[settlementServerSetupStruct.parameterId]) {
        this.settlementServerSetup[settlementServerSetupStruct.parameterId].push(new SettlementServerSetup(this, settlementServerSetupStruct));
      } else {
        this.settlementServerSetup[settlementServerSetupStruct.parameterId] = [new SettlementServerSetup(this, settlementServerSetupStruct)];
      }
    });

    if (this.settlementServerSetup[0]) {
      this.buildTemplateTaskTree(this.settlementServerSetup[0]);
    }
  }

  setNewTask(settlementServerSetupStruct) {
    if (this.settlementServerSetup[settlementServerSetupStruct.parameterId]) {
      this.settlementServerSetup[settlementServerSetupStruct.parameterId].push(new SettlementServerSetup(this, settlementServerSetupStruct));
    } else {
      this.settlementServerSetup[settlementServerSetupStruct.parameterId] = [new SettlementServerSetup(this, settlementServerSetupStruct)];
    }
  }

  setNewTemplateTask(settlementServerSetupStruct) {
    // вставляем в основную структуру
    this.setNewTask(settlementServerSetupStruct);
    this.buildTemplateTaskTree(this.settlementServerSetup[0]);
  }

  setChangeTaskByTaskId(parameterId, taskId, newTask) {
    taskId = BigInt(taskId);

    this.settlementServerSetup[parameterId] = this.settlementServerSetup[parameterId].map((task) => {
      if (task.id === taskId) {
        return new SettlementServerSetup(this, newTask);
      }
      return task;
    });

    this.buildTemplateTaskTree(this.settlementServerSetup[0]);
  }
  // работа с сервером расчетов 

  // Сервер задач
  getTasksListForTaskServer() {
    return this.tasksForTaskServer;
  }

  setNewTaskInListTaskForTaskServer(newTask) {
    this.tasksForTaskServer[newTask.typeTask].children.push(new TaskServer(this, newTask));
    return this.tasksForTaskServer;
  }

  getTaskByIdAndTypeTask(taskId, typeTask) {
    return this.tasksForTaskServer[typeTask].children.find((task) => task.id === parseInt(taskId));
  }

  setTasksListForTaskServer(newTasksForTaskServer) {
    this.tasksForTaskServer = {};

    Object.values(EnumTypeTask).forEach((typeTask) => {
      this.tasksForTaskServer[typeTask.value] = {
        typeTask: typeTask.value,
        typeName: typeTask.text,
        isOpen: false,
        children: [],
      };
    });

    newTasksForTaskServer.forEach((newTaskForTaskServer, index) => {
      this.tasksForTaskServer[newTaskForTaskServer.typeTask].children.push(new TaskServer(this, newTaskForTaskServer));
    });
  }

  setIsOpenTaskTreeItem(typeTask) {
    this.tasksForTaskServer[typeTask].isOpen = !this.tasksForTaskServer[typeTask].isOpen;
    return this.tasksForTaskServer;
  }

  changedTask(data, activeTask) {
    this.moveTask(data.typeTask, activeTask.typeTask.value, data.taskId);
    const changeTask = this.getTaskByIdAndTypeTask(data.taskId, data.typeTask);

    for (const key in data) {
      changeTask.taskServerStruct[key] = data[key];
    }
  }

  moveTask(changeTypeTask, activeTypeTask, taskId) {

    const changeTask = this.getTaskByIdAndTypeTask(taskId, activeTypeTask);

    if (changeTypeTask !== activeTypeTask) {
      this.tasksForTaskServer[changeTypeTask].children.push(changeTask);
      this.tasksForTaskServer[activeTypeTask].children = this.tasksForTaskServer[activeTypeTask].children.filter((task) => task.id !== changeTask.id);
    }
  }
  // Сервер задач

  // словарь функций для сервера расчетов
  getSrvCalcDictionary() {
    return this.srvCalcDictionary;
  }

  getSrvCalcDictionaryByName(name) {
    return this.srvCalcDictionary[name];
  }

  getSrvCalcDictionaryList() {
    return Object.values(this.srvCalcDictionary);
  }

  setSrvCalcDictionary(newSrvCalcDictionary) {
    newSrvCalcDictionary.forEach((itemNewSrvCalcDictionary) => {
      this.srvCalcDictionary[itemNewSrvCalcDictionary.name] = itemNewSrvCalcDictionary;
    });
  }
  // словарь функций для сервера расчетов

  // работа с правами
  buildRules(rulesListStruct) {
    this.rules = {}; // очищаем значение 

    rulesListStruct.forEach((rulesStruct) => {
      if (this.rules[rulesStruct.parameterId]) {
        this.rules[rulesStruct.parameterId][rulesStruct.groupId] = new Rules(this, rulesStruct);
      } else {
        this.rules[rulesStruct.parameterId] = {};
        this.rules[rulesStruct.parameterId][rulesStruct.groupId] = new Rules(this, rulesStruct);
      }
    });
  }

  createNewRules(currentParameterId, groupId, rulesStruct) {
    if (this.rules[currentParameterId]) {
      this.rules[currentParameterId][groupId] = new Rules(this, rulesStruct);
    } else {
      this.rules[currentParameterId] = {};
      this.rules[currentParameterId][groupId] = new Rules(this, rulesStruct);
    }
  }

  cloneRules(rulesStruct, currentParameterId) {
    const cloneRules = new RulesStruct(rulesStruct.id, rulesStruct.groupId, this.configuration.id, currentParameterId, rulesStruct.type).clone();
    this.createNewRules(currentParameterId, cloneRules.groupId, cloneRules);

    return cloneRules;
  }

  getRulesByParameterIdAndGroupId(parameterId, currentParameterId, groupId) {
    groupId = BigInt(groupId);

    const groupContains = this.rules[parameterId] !== undefined ? Object.values(this.rules[parameterId])?.find((rule) => rule.rulesStruct.groupId === groupId) : undefined;

    if (this.rules[parameterId] && groupContains !== undefined) { // нашли rules для параметра или его родителя
      const cloneRules = this.cloneRules(groupContains.rulesStruct, currentParameterId);
      return new Rules(this, cloneRules);
    } else {
      if (parameterId !== 0n) {
        const parentId = this.parameters[parameterId].parameterStruct.parentId;
        return this.getRulesByParameterIdAndGroupId(parentId, currentParameterId, groupId);
      } else {
        const newRules = new Rules(this, new RulesStruct(0n, groupId, this.configuration.id, currentParameterId, 0n));

        this.createNewRules(currentParameterId, groupId, newRules);
        return newRules;
      }
    }

  }

  getRulesByIdAndParameterId(groupId, parameterId) {
    return Object.values(this.rules[parameterId]).find((rule) => rule.groupId === groupId);
  }
  // работа с правами

  // drag and drop элементов в дереве конфигурации
  setDragStartNodeId(newDragStartId) {
    this.dragStartNodeId = newDragStartId;
  }

  getDragStartNodeId() {
    return this.dragStartNodeId;
  }

  setDragOverNodeId(newDragOverNodeId) {
    this.dragOverNodeId = newDragOverNodeId;
  }

  getDragOverNodeId() {
    return this.dragOverNodeId;
  }
  // drag and drop  элементов в дереве конфигурации

  // работа с навигацией
  setNavigatePath(location) {
    this.navigatePath = location;
  }

  getNavigatePath() {
    return this.navigatePath;
  }
  // работа с навигацией


  // изменение параметров
  async setValues(newParametersValue) {
    const remoteServersPool = this.getRemoteServersPool();
    const result = await remoteServersPool.setParameterValue(newParametersValue); // return { RequestSetParameterValueDataStruct, ResponseSetParameterValueDataStruct } 

    if (result.responseSetParameterValueDataStruct.isOk) {
      const requestSetParameterValueDataListStruct = result.requestSetParameterValueDataListStruct.items;

      requestSetParameterValueDataListStruct.forEach((requestSetParameterValueDataStruct) => {
        const parameterStruct = this.getParameterById(requestSetParameterValueDataStruct.parameterId);
        parameterStruct.parameterStruct.timestamp = requestSetParameterValueDataStruct.timestamp;
        parameterStruct.parameterStruct.rawValue = requestSetParameterValueDataStruct.rawValue;
        parameterStruct.parameterStruct.strValue.value = requestSetParameterValueDataStruct.strValue.value;
      });

    } else {
      throw new Error("Ошибка при записи параметров!");
    }
  }
  // изменение параметров

  //управление состоянием NavApp layout
  initialSidebarState() {
    const clientWidth = window.screen.width;
    if (clientWidth < 1600) {
      this.sidebar = true;
    } else {
      this.sidebar = false;
    }
  }

  setSidebar(sidebarState) {
    return this.sidebar = sidebarState;
  }

  getSidebarState() {
    return this.sidebar;
  }
  //управление состоянием NavApp layout

  getRemoteServersPool() {
    return this.remoteServersPool;
  }

  //глобальное состояние для отслеживания изменения данных на странице
  setHandleDataPageState(value) {
    return this.handleDataPageState = value;
  }

  setHandleDataPageKeyState(key, value) {
    return this.handleDataPageState[key] = value;
  }

  getHandleDataPageAllState() {
    return this.handleDataPageState;
  }

  getHandleDataPageKeyState(key) {
    return this.handleDataPageState[key];
  }

  removeHandleDataPageAllState() {
    return this.handleDataPageState = {};
  }
  // конец глобального состояния для отслеживания изменения данных на странице

  //authorization
  authUser(payload) {
    const { id, name, token, groupId, settings } = payload;
    this.user.id = id;
    this.user.name = name;
    this.user.token = token;
    this.user.groupId = groupId;
    this.user.settings = settings;
    this.user.isAuthorization = true;
  }

  async setNewTokenUser() {
    try {
      const newToken = (await AuthService.refreshToken()).data.accessToken;
      localStorage.setItem("token", newToken);
      this.user.token = newToken;
    } catch (error) {
      console.warn(error);
    }
  };

  timerRefreshToken() {
    if (!this.flagRefreshUpdateTokenUser) {
      this.refreshTokenUser = setInterval(() => {
        this.setNewTokenUser();
      }, parseInt(process.env.REACT_APP_REFRESH_TOKEN_TIMEOUT) * 60 * 1000);

      this.flagRefreshUpdateTokenUser = true;
    }
  }

  removeTimerRefreshToken() {
    if (this.flagRefreshUpdateTokenUser) {//остановить таймер если он запущен
      clearInterval(this.refreshTokenUser);
      this.refreshTokenUser = null;
      this.flagRefreshUpdateTokenUser = false;
    }
  }

  logoutUser() {
    this.user.id = null;
    this.user.name = null;
    this.user.token = null;
    this.user.groupId = null;
    this.user.settings = null;
    this.user.isAuthorization = false;
  }

  getUser() {
    return this.user;
  }

  getUserById(userId) {
    return this.users[userId];
  }

  getUsers() {
    return Object.values(this.users);
  }

  setUsers(userListStruct) {
    return this.users = userListStruct;
  }

  getUsersGroups() {
    return Object.values(this.usersGroups);
  }

  // configuration
  getActiveConfiguration() {
    return this.configuration;
  }

  buildActiveConfiguration(configurationStruct) {
    this.configuration = new Configuration(this, configurationStruct);
  }

  unloadConfiguration() {
    this.configuration = null;
    this.parameters = {};
  }

  setElementConfigurationId(id) {
    return this.elementConfigurationId = id;
  }

  //id выделенного элемента в дереве пользователей 
  setElementUsersTreeId(id) {
    return this.elementUsersTreeId = id;
  }

  // system types
  getSystemTypeById(id) {
    return this.systemTypes[id];
  }

  getSystemTypes() {
    return Object.values(this.systemTypes);
  }

  buildSystemTypes(systemTypesStruct) {
    this.systemTypes = {};
    systemTypesStruct.forEach((systemTypeStruct) => {
      this.systemTypes[systemTypeStruct.id] = new SystemType(systemTypeStruct, this.disableTransforms);
    });
  }

  //
  //Editor Settings
  //
  getEditorSettings() {
    return Object.values(this.editorSettings);
  }

  buildEditorSettings(editorSettingsStruct) {
    this.editorSettings = {};

    editorSettingsStruct.forEach((editorSettingStruct) => {
      if (this.editorSettings[editorSettingStruct.systemType]) {
        this.editorSettings[editorSettingStruct.systemType].push(new EditorSettings(editorSettingStruct));
      } else {
        this.editorSettings[editorSettingStruct.systemType] = [new EditorSettings(editorSettingStruct)];
      }
    });
  }

  //
  // Block types
  //
  getBlockTypeById(id) {
    return this.blockTypes[id];
  }

  getBlockTypes() {
    return Object.values(this.blockTypes);
  }

  buildBlockTypes(blockTypesStruct) {
    this.blockTypes = {};
    blockTypesStruct.forEach((blockTypeStruct) => {
      this.blockTypes[blockTypeStruct.id] = new BlockType(blockTypeStruct);
    });
  }

  // parameters
  getParameterById(id) {
    return this.parameters[id];
  }

  getRootParameter() {
    return this.getParameterById(0n);
  }

  getParameters() {
    return Object.values(this.parameters);
  }

  getMaxParameterId() {
    return this.maxParameterId;
  }

  buildPropertyHistoryTableList(propertyHistoryListStruct, usersListStruct, parametrId) {

    this.propertyHistoryList = {};

    usersListStruct.forEach((userStruct) => {
      const user = new User(this, userStruct);
      this.users[user.id] = user;
    });

    propertyHistoryListStruct.forEach((propertyHistoryStruct) => {
      const propertyHistory = new PropertyHistory(this, propertyHistoryStruct, parametrId);
      this.propertyHistoryList[propertyHistoryStruct.id] = propertyHistory;
    });
  }

  getPropertyHistoryList() {
    const propertyHistoryListArr = Object.values(this.propertyHistoryList);
    return propertyHistoryListArr.sort((a, b) => a.dateСhangesProperty < b.dateСhangesProperty ? 1 : -1); // сортировка по дате изменения элемента 
  }

  buildParametersUserTree(usersListStruct, usersGroupListStruct) {
    usersListStruct.forEach((userStruct) => {
      const user = new User(this, userStruct);
      this.users[user.id] = user;
    });

    usersGroupListStruct.forEach(userGroupStruct => {
      const userGroup = new UsersGroup(this, userGroupStruct);
      this.usersGroups[userGroup.id] = userGroup;
    });

    usersListStruct.forEach((userStruct) => {
      this.usersGroups[userStruct.groupId].children.push(this.users[userStruct.id]);
    });
  }


  // работа с лицензиями лицензии 
  async downloadingLicensesList() {
    return (await LicensesService.licensesListStruct())?.items;
  }

  // build License
  buildLicenses(licensesListStruct) {
    licensesListStruct.forEach((licenseStruct) => {
      const license = new License(this, licenseStruct);
      this.licensesList[license.id] = license;
    });
  }

  async downloadingLicensesTypesList() {
    return (await LicensesService.licensesTypesListStruct())?.items;
  }

  // build LicenseType
  buildLicensesTypes(licensesTypesListStruct) {
    licensesTypesListStruct.forEach((licenseTypeStruct) => {
      const licenseType = new LicenseType(this, licenseTypeStruct);
      this.licensesTypesList[licenseType.id] = licenseType;
    });
  }

  async buildLicensesTree() {
    this.licensesList = {};
    this.licensesTypesList = {};

    const [licensesListStruct, licensesTypesListStruct] = await Promise.all([
      this.downloadingLicensesList(),
      this.downloadingLicensesTypesList(),
    ]);

    this.buildLicenses(licensesListStruct);
    this.buildLicensesTypes(licensesTypesListStruct);

    licensesListStruct.forEach((licenseStruct) => {
      if (!this.licensesTypesList[licenseStruct.licType]) {
        const id = licenseStruct.licType;
        const name = new StringStruct("Неизвестный тип сервиса");
        const licenseTypeStruct = new LicensesTypeStruct(id, name);
        const licenseType = new LicenseType(this, licenseTypeStruct);
        this.licensesTypesList[licenseType.id] = licenseType;
      } else {
        this.licensesTypesList[licenseStruct.licType].children.push(this.licensesList[licenseStruct.id]);
      }
    });

    const tree = this.getLicensesTypesList();

    return { licensesListStruct, licensesTypesListStruct, tree };
  }

  getLicensesList() {
    return Object.values(this.licensesList);
  }

  getLicenseById(id) {
    return this.licensesList[id];
  }

  getLicensesTypesList() {
    return Object.values(this.licensesTypesList);
  }

  //id выделенного элемента в дереве лицензий
  setElementLicensesTreeId(id) {
    return this.elementLicensesTreeId = id;
  }

  setLicensesFile(newLicensesFile) {
    return this.licensesFile = newLicensesFile;
  }

  //добавление новой лицензии в дерево лицензий
  addNewLicensesInLicensesTree(license) {
    const licenseStruct = new LicensesStruct(
      license.licType,
      license.dataBlockType,
      new StringStruct(license.closeSign.value),
      new StringStruct(license.uuid.value),
      new StringStruct(license.name.value),
      new StringStruct(license.statusState.value),
      new StringStruct(license.publicContent.value),
      new StringStruct(license.privateContent.value),
    );
    const newLicense = new License(this, licenseStruct);//обертка над лицензией
    this.licensesList[newLicense.id] = newLicense;//добавили лицензию в объект licensesList
    this.licensesTypesList[newLicense.licType].children.push(newLicense);//добавил лицензию в объект licensesTypesList
    return newLicense;
  }

  //изменение лицензии
  changingLicensesInLicensesTree(license) {
    const licenseStruct = new LicensesStruct(
      license.licType,
      license.dataBlockType,
      new StringStruct(license.closeSign.value),
      new StringStruct(license.uuid.value),
      new StringStruct(license.name.value),
      new StringStruct(license.statusState.value),
      new StringStruct(license.publicContent.value),
      new StringStruct(license.privateContent.value),
    );
    const newLicense = new License(this, licenseStruct);//обертка над лицензией
    this.licensesList[newLicense.id] = newLicense;//добавили лицензию в объект licensesList

    this.licensesTypesList[newLicense.licType].children.forEach((license, index) => {
      if (license.id === newLicense.id) {
        this.licensesTypesList[newLicense.licType].children.splice(index, 1, newLicense);
      }
    });

    return newLicense;
  }
  // работа с лицензиями лицензии

  //добавление новой группы
  addNewGroupInParametersUserTree(userGroupStruct) {
    const userGroup = new UsersGroup(this, userGroupStruct);
    this.usersGroups[userGroup.id] = userGroup;
    return userGroup;
  }

  //изменение группы 
  changingUserGroupInParametersUserTree(groupId, newUserGroupName) {
    const usersGroups = this.usersGroups[groupId];
    usersGroups.newName = newUserGroupName;
    return usersGroups;
  }

  //добавление пользователя
  addNewUserInParametersUserTree(user) {
    const newUserStructExtended = new UserStructExtended(user.id, user.groupId, new StringStruct(user.name.value), new StringStruct(user.password.value));//создаю расширенную структуру пользователя
    const newUser = new User(this, newUserStructExtended);//обертка над пользователем
    this.users[newUser.id] = newUser;//добавили пользователя в объект users
    this.usersGroups[newUser.groupId].children.push(newUser);//добавил пользователя в объект группы
    return newUser;
  }

  //изменение пользователя
  changingUserInParametersUserTree(user, oldGroupUser) {
    const newUserStructExtended = new UserStructExtended(user.id, user.groupId, new StringStruct(user.name.value), new StringStruct(user.password.value));//создаю расширенную структуру пользователя
    const newUser = new User(this, newUserStructExtended);//обертка над пользователем
    this.users[newUser.id] = newUser;//добавили пользователя в объект users

    if (newUser.groupId === oldGroupUser) {
      //когда ip групп одинаковые
      this.usersGroups[newUser.groupId].children.forEach((user, index) => {
        if (user.id === newUser.id) {
          this.usersGroups[newUser.groupId].children.splice(index, 1, newUser);
        }
      });//заменил пользователя в группе

    } else {
      //когда ip групп разные
      this.usersGroups[oldGroupUser].children.forEach((user, index) => {
        if (user.id === newUser.id) {
          this.usersGroups[oldGroupUser].children.splice(index, 1);
        }
      });//убираем из старой группы пользователя

      this.usersGroups[newUser.groupId].children.push(newUser);//добавил пользователя в объект групп
    }
    return newUser;
  }

  buildParametersTree(parametersStruct) {
    const configuration = this.getActiveConfiguration();

    const rootParameterStruct = new ParameterStruct(
      0n, // id
      0n, // parentId
      1, // systemType
      0, // snapshotType
      0, // countValue
      configuration.accessBits, // accessBits
    );


    const root = new ParametersExtended(
      this,
      rootParameterStruct,
      this.disableTransforms
    );

    Object.defineProperty(root, 'ObjectNameOtobrazhenie', {
      configurable: false,
      enumerable: true,
      get() {
        return configuration.name;
      },
    });

    this.parameters = {};
    this.parameters[root.id] = root;

    // fill hashtable
    let maxId = root.id;
    parametersStruct.forEach((parameterStruct) => {
      const parameter = new ParametersExtended(this, parameterStruct);
      this.parameters[parameter.id] = parameter;
      if (maxId < parameter.id) {
        maxId = parameter.id;
      }
    });
    this.maxParameterId = maxId;

    // установка флага isDeleted в обертке Parameters
    this.idRemoteConfigurationObjects.forEach(elementArr => {
      this.parameters[elementArr.parameter_id].isDeletedObject = true;
    });
    // установка флага isDeleted в обертке Parameters

    // link parents and children
    parametersStruct.forEach(parameterStruct => {

      const child = this.parameters[parameterStruct.id];
      const parent = this.parameters[parameterStruct.parentId];

      // установка флага isDeleted на дочернии элементы
      if (child.parent.isDeleted) {
        child.isDeletedObject = true;
      }
      // установка флага isDeleted на дочернии элементы


      if (child.isProperty) { // is property

        parent.properties.push(child);
        const propertyName = child.name;

        if (propertyName in parent) { // bugs in configuration
          console.error(`Property ${propertyName} is already defined in parameter ${parent.id}. Using parameter ${child.id} to redefine this property.`);
        }

        Object.defineProperty(parent, propertyName, {
          configurable: true, // allow redefinition while we have bugs in configurations
          enumerable: true,

          get() {
            return child.propertyValueRoundUp;
          },
        });// Cannot redefine property: ConfigNotTransmittedToController (configuration 22)

      } else {
        parent.children.push(child);
      }
    });

    // sort children
    [rootParameterStruct, ...parametersStruct].forEach((parameterStruct) => {
      const parameter = this.parameters[parameterStruct.id];

      if (parameter.children) {
        parameter.children.sort((a, b) => {
          const d1 = a.classify() - b.classify();
          const d2 = a.id - b.id;
          return d1 < 0 ? -1 : d1 > 0 ? 1 : (d2 < 0 ? -1 : d2 > 0 ? 1 : 0);
        });
      }
      if (parameter.properties) {
        parameter.properties.sort((a, b) => {
          const d = a.id - b.id;
          return d < 0 ? -1 : d > 0 ? 1 : 0;
        });
      }
    });
    // sort children


    /*parameters filter*/
    parametersStruct.forEach((parameterStruct) => {
      const child = this.parameters[parameterStruct.id];
      const parent = child.parent;


      if (child.isProperty) { // is property

        const systemType = child.systemType.id;
        const systemTypeParentId = parent?.typeNode?.systemTypeId;
        const parentBlockType = child.parentObject?.blockType?.id;
        const containedEditorSettings = this.editorSettings[systemType];

        if (containedEditorSettings) {//проверяем есть ли вообще правила для этого системного типа
          let canView = true;
          let canEdit = false;//если false то можно редактировать

          containedEditorSettings.forEach((editorSettings) => {

            if (
              editorSettings.systemTypeParent === 0 ||
              editorSettings.systemTypeParent === systemTypeParentId
            ) {//проверяем system type parent 

              if (
                editorSettings.typeBlockParent === 0 ||
                editorSettings.typeBlockParent === parentBlockType
              ) {//проверяем type block parent

                if (editorSettings.canView === 0) {
                  canView = false;
                }

                if (editorSettings.canEdit === 0) {
                  canEdit = true;
                }
              }
            }
          });

          if (this.user.settings.isRootUser) { // можно редактировать при root режиме
            child.canEdit = false;
            this.parameters[child.id].canViewParameter = true;
          } else {
            child.canEdit = canEdit;
            this.parameters[child.id].canViewParameter = canView;
          }
        }
        /*parameters filter*/
      }

    });
  }
}

export { Kernel };
