import classNames from 'classnames';

import styles from './logsChangeProperties.module.scss';

export default function LogsChangeProperties(props) {
  const {} = props;

  return (
    <div className={styles.logsListItem}>
      <div className={styles.logTitle}>
        <span className={styles.logTitleText}>Изменение свойства</span>
      </div>

      {/* пользователь (кто изменил) */}
      <div className={classNames(styles.logItem)}>
        <div className={styles.logAnnotation}>
          <strong className={styles.logTextStrong}>Кто:</strong>
        </div>
        <div className={styles.logDescription}>
          <span className={styles.logText}>
            Пользователь
            <span className={styles.logTextActive}>Администратор</span>
            изменил свойство с<span className={styles.logTextActive}>id=26</span>
          </span>
        </div>
      </div>

      {/* где изменили */}
      <div className={classNames(styles.logItem)}>
        <div className={styles.logAnnotation}>
          <strong className={styles.logTextStrong}>Где:</strong>
        </div>
        <div className={styles.logDescription}>
          <span className={styles.logTextActive}>QS.CORE.ADM 1 версии 1.0.0 </span>
        </div>
      </div>

      {/* значение изменения изменили */}
      <div className={classNames(styles.logValue)}>
        <div className={styles.logAnnotation}>
          <strong className={styles.logTextStrong}>Значение:</strong>
        </div>

        <div className={styles.logAnnotationItem}>
          <div className={styles.subLogAnnotation}>
            <strong className={styles.logText}>Старое значение -</strong>
          </div>
          <div className={styles.subLogDescription}>
            <span className={styles.logTextActive}>“Последнее время выхода прибора на связь”</span>
          </div>
        </div>
        <div className={styles.logAnnotationItem}>
          <div className={styles.subLogAnnotation}>
            <strong className={styles.logText}>Новое значение -</strong>
          </div>
          <div className={styles.subLogDescription}>
            <span className={styles.logTextActive}>“Последнее время выхода прибора на связь тест”</span>
          </div>
        </div>
      </div>

      {/* Дата и время события */}
      <div className={classNames(styles.logDateAndTime)}>
        <div className={styles.logAnnotation}>
          <strong className={styles.logTextStrong}>Дата и время события:</strong>
        </div>

        <div className={styles.logAnnotationItem}>
          <div className={styles.subLogAnnotation}>
            <strong className={styles.logText}>Дата -</strong>
          </div>
          <div className={styles.subLogDescription}>
            <span className={styles.logTextActive}>2022-12-19 </span>
          </div>
        </div>
        <div className={styles.logAnnotationItem}>
          <div className={styles.subLogAnnotation}>
            <strong className={styles.logText}>Время -</strong>
          </div>
          <div className={styles.subLogDescription}>
            <span className={styles.logTextActive}>12:35:06</span>
          </div>
        </div>
      </div>

      {/* статус изменения */}
      <div className={classNames(styles.logItem)}>
        <div className={styles.logAnnotation}>
          <strong className={styles.logTextStrong}>Статус выполнения:</strong>
        </div>
        <div className={styles.logDescription}>
          <span className={styles.logTextStatus}>успешно</span>
        </div>
      </div>
    </div>
  );
}
