import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { flagsParser } from '../../utils/flags-parser/flagsParser.mjs';
import { useUserTokenDecode } from '../../hooks/hooks.mjs';
import { NavApp, ContentApp, HeaderApp } from '../../layout/layout.mjs';
import { ComponentLicenses } from '../../hoc/hoc.mjs';
import { ErrorMessagePopup, TreeLicenses } from '../../components/components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';

import styles from './licenses.module.scss';

export default function Licenses() {
  const { kernel, currentLicense } = useKernel();
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [isError, setIsError] = useState(false); //если есть какая-то ошибка
  const [errorMessage, setErrorMessage] = useState('Ошибка!'); //сообщение об ошибке
  const [componentsTypeId, setComponentsTypeId] = useState(0); //какой компонент нужно выводить пользователю
  const [drag, setDrag] = useState(false); //перенесен файл в область или нет
  const [licensesTree, setLicensesTree] = useState([]); //структура дерева лицензий
  const [activeElementTree, setActiveElementTree] = useState(null); //активный пункт дерева
  const [idTreeElement, setIdTreeElement] = useState();
  const { flags } = useUserTokenDecode();
  const { hasAccessRightsLicensesModule } = flagsParser(flags);

  const actionPerformErrorMessagePopup = () => {
    return setIsError(false);
  };

  const initialPageUserState = () => {
    setUserName(kernel.getUser().name);
  };

  const buildTree = async () => {
    try {
      const { tree } = await kernel.buildLicensesTree();
      setLicensesTree(tree);
    } catch (error) {
      setIsError(true);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    initialPageUserState();
    buildTree();
    kernel.timerRefreshToken(); //запуск обновления токена
    return () => {};
  }, []);

  useEffect(() => {
    const { hasAccessLicensesModule = undefined } = currentLicense;
    if (hasAccessLicensesModule !== 'true' || hasAccessLicensesModule === undefined || hasAccessRightsLicensesModule === false) {
      navigate('/section-selection');
    }
    return () => {};
  }, [currentLicense]);

  return (
    <div className={styles.licenses}>
      {isError && <ErrorMessagePopup errorMessage={errorMessage} actionPerform={actionPerformErrorMessagePopup} />}

      <NavApp />
      <ContentApp>
        <div className={styles.headerContainer}>
          <HeaderApp configurationName="Лицензии модулей" userName={userName} />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.licensesTreeContainer}>
            <TreeLicenses
              licensesTree={licensesTree}
              setLicensesTree={setLicensesTree}
              activeElementTree={activeElementTree}
              setActiveElementTree={setActiveElementTree}
              setComponentsTypeId={setComponentsTypeId}
              setIdTreeElement={setIdTreeElement}
            />
          </div>
          <div className={styles.componentLicensesContainer}>
            <ComponentLicenses
              componentsTypeId={componentsTypeId}
              setComponentsTypeId={setComponentsTypeId}
              setIsError={setIsError}
              setErrorMessage={setErrorMessage}
              drag={drag}
              setDrag={setDrag}
              licensesTree={licensesTree}
              setLicensesTree={setLicensesTree}
              idTreeElement={idTreeElement}
            />
          </div>
        </div>
      </ContentApp>
    </div>
  );
}
