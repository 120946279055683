import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useUserTokenDecode } from '../../hooks/hooks.mjs';
import { ErrorMessagePopup, SearchTreeUser, TreeUser } from '../../components/components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { ComponentUser } from '../../hoc/hoc.mjs';
import { ContentApp, HeaderApp, NavApp } from '../../layout/layout.mjs';
import { UsersGroup } from '../../quantum-lib/Arm/Parameters/UsersGroup.mjs';
import { UsersService } from '../../services/services.mjs';
import { flagsParser } from '../../utils/flags-parser/flagsParser.mjs';

import styles from './users.module.scss';

export default function Users() {
  const navigate = useNavigate();
  const { kernel, currentLicense } = useKernel();
  const [userName, setUserName] = useState('');
  const [isError, setIsError] = useState(false); //если есть какая-то ошибка
  const [errorMessage, setErrorMessage] = useState('Ошибка!'); //сообщение об ошибке

  const [usersTree, setUsersTree] = useState([]);
  const [initialStateUserTree, setInitialStateUserTree] = useState([]);
  const [componentsTypeId, setComponentsTypeId] = useState(0);
  const [idTreeElement, setIdTreeElement] = useState();
  const [searchTreeUserInputValue, setSearchTreeUserInput] = useState('');
  const [activeElementTree, setActiveElementTree] = useState(null);
  const { flags } = useUserTokenDecode();
  const { hasAccessRightsUsersModule } = flagsParser(flags);

  const actionPerformErrorMessagePopup = () => {
    return setIsError(false);
  };

  const buildTreeUser = async () => {
    Promise.all([
      //получаем группы пользователей
      UsersService.usersGroupsListStruct(),
      //получаем пользователей
      UsersService.usersListStruct(),
    ])
      .then((values) => {
        const [usersGroupListStructStruct, usersListStructStruct] = [...values];
        const usersGroupListStruct = usersGroupListStructStruct.items;
        const usersListStruct = usersListStructStruct.items;

        kernel.buildParametersUserTree(usersListStruct, usersGroupListStruct);
        const userTree = Object.values(kernel.usersGroups);

        setInitialStateUserTree(userTree);
        setUsersTree(userTree);
      })
      .catch((error) => {
        setIsError(true);
        const message = error?.message || 'Ошибка!';
        setErrorMessage(message);
      });
  };

  const initialPageUserState = () => {
    setUserName(kernel.getUser().name);
    buildTreeUser();
  };

  const filterUsersTree = (searchText, usersTreeList) => {
    const newUsersTreeList = [];
    if (searchText.length === 0) {
      return initialStateUserTree;
    }

    usersTreeList.forEach((group, key) => {
      if (group.name.toLowerCase().includes(searchTreeUserInputValue.toLowerCase())) {
        newUsersTreeList.push(group);
      } else {
        const newChildren = [];
        group.children.forEach((user) => {
          if (user.name.toLowerCase().includes(searchTreeUserInputValue.toLowerCase())) {
            newChildren.push(user);
          }
        });

        //меняю данные в state (нельзя так) TODO
        if (newChildren.length > 0) {
          group.children = newChildren;
          newUsersTreeList.push(group);
        }
      }
    });

    return newUsersTreeList;
  };

  useEffect(() => {
    initialPageUserState();
    kernel.timerRefreshToken(); //запуск обновления токена
    return () => {};
  }, []);

  useEffect(() => {
    if (initialStateUserTree.length !== 0) {
      const debounce = setTimeout(() => {
        const cloneInitialStateUserTree = initialStateUserTree.map((item) => Object.assign(new UsersGroup(), item)); //клонирую объект состояния, чтобы не менять его (TODO)
        const filteredUsersTree = filterUsersTree(searchTreeUserInputValue, cloneInitialStateUserTree);
        setUsersTree(filteredUsersTree);
      }, 300);

      return () => {
        clearTimeout(debounce);
      };
    }
  }, [searchTreeUserInputValue]);

  useEffect(() => {
    const { hasAccessUsersModule = undefined } = currentLicense;
    if (hasAccessUsersModule !== 'true' || hasAccessUsersModule === undefined || hasAccessRightsUsersModule === false) {
      navigate('/section-selection');
    }
    return () => {};
  }, [currentLicense]);

  return (
    <div className={styles.users}>
      {isError && <ErrorMessagePopup errorMessage={errorMessage} actionPerform={actionPerformErrorMessagePopup} />}

      <NavApp />
      <ContentApp>
        <div className={styles.headerContainer}>
          <HeaderApp configurationName="Список пользователей" userName={userName} />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.treeUserContainer}>
            <SearchTreeUser setSearchTreeUserInput={setSearchTreeUserInput} searchTreeUserInputValue={searchTreeUserInputValue} />
            <div className={styles.treeContainer}>
              <TreeUser
                usersTree={usersTree}
                initialStateUserTree={initialStateUserTree}
                setUsersTree={setUsersTree}
                setComponentsTypeId={setComponentsTypeId}
                setIdTreeElement={setIdTreeElement}
                activeElementTree={activeElementTree}
                setActiveElementTree={setActiveElementTree}
              />
            </div>
          </div>
          <div className={styles.componentUserContainer}>
            <ComponentUser
              componentsTypeId={componentsTypeId}
              setComponentsTypeId={setComponentsTypeId}
              setIdTreeElement={setIdTreeElement}
              idTreeElement={idTreeElement}
              setIsError={setIsError}
              setErrorMessage={setErrorMessage}
              usersTree={usersTree}
              initialStateUserTree={initialStateUserTree}
              setUsersTree={setUsersTree}
              setInitialStateUserTree={setInitialStateUserTree}
            />
          </div>
        </div>
      </ContentApp>
    </div>
  );
}
