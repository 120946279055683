import classNames from 'classnames';
import { RxCross2 } from 'react-icons/rx';

import { removeValueItem } from '../../model/removeValueItem.mjs';

import styles from './valueItemsList.module.scss';

export function ValueItemsList(props) {
  const { optionsList, itemsList, isMulti, setItemsList, setOptionsList, setIsOpen, sortKey, inputState, optionsListRef } = props;

  const removeItem = ({ currentTarget }) => {
    const valueId = currentTarget.dataset.value;

    const { newOptionsList, valueItems } = removeValueItem(optionsList, itemsList, valueId, isMulti, sortKey);
    optionsListRef.current = newOptionsList;
    setOptionsList(newOptionsList);
    setItemsList(valueItems);
  };

  if (isMulti) {
    return (
      <div className={styles.valueItemsContainer}>
        {itemsList.map(({ value, label }) => {
          return (
            <div key={value} data-value={value} className={styles.valueItem} title={label}>
              <span className={styles.valueItemText}>{label}</span>
              <div className={styles.clearValueItem} data-value={value} onClick={removeItem}>
                <RxCross2 className={styles.clearValueItemIcon} />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  if (!isMulti) {
    const [item] = itemsList;

    return (
      inputState.value.length === 0 && (
        <span className={classNames(styles.valueItemText, styles.valueItemTextNotMulti)} data-value={item?.value}>
          {item?.label}
        </span>
      )
    );
  }
}
