import Utils from "../../utils/Utils.mjs";

export const createValidationScheme = (handleBlur, inputRef) => (
  {
    inputNumberInt16ValidationScheme: {
      required: 'Поле обязательно к заполнению',
      minLength: {
        value: 1,
        message: 'Поле должно содержать минимум 1 символ',
      },
      maxLength: {
        value: 7,
        message: 'Поле может содержать максимум 7 символов',
      },
      min: { value: -32768, message: 'Минимальное допустимое значение -32768' },
      max: { value: 32768, message: 'Максимальное допустимое значение 32768' },
      validate: (value) => {
        const str = value.toString();
        if (str.split('').includes('.')) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isNumber = Utils.testIsNumber(value);
        if (!isNumber) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isInteger = Utils.testIsInteger(value);
        if (!isInteger) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
      },
      onBlur: handleBlur,
    },
    inputNumberByteValidationScheme: {
      required: 'Поле обязательно к заполнению',
      minLength: {
        value: 1,
        message: 'Поле должно содержать минимум 1 символ',
      },
      maxLength: {
        value: 3,
        message: 'Поле может содержать максимум 3 символов',
      },
      min: { value: 0, message: 'Минимальное допустимое значение 0' },
      max: { value: 255, message: 'Максимальное допустимое значение 255' },
      validate: (value) => {
        const str = value.toString();
        if (str.split('').includes('.')) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isNumber = Utils.testIsNumber(value);
        if (!isNumber) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isInteger = Utils.testIsInteger(value);
        if (!isInteger) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
      },
      onBlur: handleBlur,
    },
    inputNumberInt32ValidationScheme: {
      required: 'Поле обязательно к заполнению',
      minLength: {
        value: 1,
        message: 'Поле должно содержать минимум 1 символ',
      },
      maxLength: {
        value: 12,
        message: 'Поле может содержать максимум 12 символ',
      },
      min: { value: -2147483648, message: 'Минимальное допустимое значение -2147483648' },
      max: { value: 2147483648, message: 'Максимальное допустимое значение 2147483648' },
      validate: (value) => {
        const str = value.toString();
        if (str.split('').includes('.')) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isNumber = Utils.testIsNumber(value);
        if (!isNumber) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isInteger = Utils.testIsInteger(value);
        if (!isInteger) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
      },
      onBlur: handleBlur,
    },
    inputNumberInt64ValidationScheme: {
      required: 'Поле обязательно к заполнению',
      minLength: {
        value: 1,
        message: 'Поле должно содержать минимум 1 символ',
      },
      maxLength: {
        value: 21,
        message: 'Поле может содержать максимум 21 символа',
      },
      min: { value: -9223372036854775808, message: 'Минимальное допустимое значение -9223372036854775808' },
      max: { value: 9223372036854775808, message: 'Максимальное допустимое значение 9223372036854775808' },
      validate: (value) => {
        const str = value.toString();
        if (str.split('').includes('.')) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isNumber = Utils.testIsNumber(value);
        if (!isNumber) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isInteger = Utils.testIsInteger(value);
        if (!isInteger) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
      },
      onBlur: handleBlur,
    },
    inputNumberUint16ValidationScheme: {
      required: 'Поле обязательно к заполнению',
      minLength: {
        value: 1,
        message: 'Поле должно содержать минимум 1 символ',
      },
      maxLength: {
        value: 5,
        message: 'Поле может содержать максимум 5 символов',
      },
      min: { value: 0, message: 'Минимальное допустимое значение 0' },
      max: { value: 65535, message: 'Максимальное допустимое значение 65535' },
      validate: (value) => {
        const str = value.toString();
        if (str.split('').includes('.')) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isNumber = Utils.testIsNumber(value);
        if (!isNumber) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isInteger = Utils.testIsInteger(value);
        if (!isInteger) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
      },
      onBlur: handleBlur,
    },
    inputNumberUint32ValidationScheme: {
      required: 'Поле обязательно к заполнению',
      minLength: {
        value: 1,
        message: 'Поле должно содержать минимум 1 символ',
      },
      maxLength: {
        value: 10,
        message: 'Поле может содержать максимум 10 символов',
      },
      min: { value: 0, message: 'Минимальное допустимое значение 0' },
      max: { value: 4294967295, message: 'Максимальное допустимое значение 4294967295' },
      validate: (value) => {
        const str = value.toString();
        if (str.split('').includes('.')) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isNumber = Utils.testIsNumber(value);
        if (!isNumber) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isInteger = Utils.testIsInteger(value);
        if (!isInteger) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
      },
      onBlur: handleBlur,
    },
    inputNumberUint64ValidationScheme: {
      required: 'Поле обязательно к заполнению',
      minLength: {
        value: 1,
        message: 'Поле должно содержать минимум 1 символ',
      },
      maxLength: {
        value: 20,
        message: 'Поле может содержать максимум 20 символов',
      },
      min: { value: 0, message: 'Минимальное допустимое значение 0' },
      max: { value: 18446744073709551615, message: 'Максимальное допустимое значение 18446744073709551615' },
      validate: (value) => {
        const str = value.toString();
        if (str.split('').includes('.')) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isNumber = Utils.testIsNumber(value);
        if (!isNumber) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isInteger = Utils.testIsInteger(value);
        if (!isInteger) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
      },
      onBlur: handleBlur,
    },
    inputTextString16ValidationScheme: {
      maxLength: {
        value: 16,
        message: 'Поле может содержать максимум 16 символа',
      },
      onBlur: handleBlur,
    },
    inputTextString64ValidationScheme: {
      maxLength: {
        value: 64,
        message: 'Поле может содержать максимум 64 символа',
      },
      onBlur: handleBlur,
    },
    inputTextString255ValidationScheme: {
      maxLength: {
        value: 255,
        message: 'Поле может содержать максимум 255 символа',
      },
      onBlur: handleBlur,
    },
    inputTextCharValidationScheme: {
      maxLength: {
        value: 1,
        message: 'Поле может содержать максимум 1 символ',
      },
      onBlur: handleBlur,
    },
    inputIpIpValidationScheme: {
      minLength: {
        value: 7,
        message: 'Поле может содержать минимум 7 символов',
      },
      pattern:
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      maxLength: {
        value: 19,
        message: 'Поле может содержать максимум 19 символов',
      },
      onBlur: handleBlur,
    },
    inputNumberFractionalFloatValidationScheme: {
      validate: (value) => {
        const str = value.toString();
        if (str.split('')[str.length - 1] === '.') {
          return 'Неправильный формат заполнения! Допустимый формат значений - числа!';
        }
        const isNumber = Utils.testIsNumber(value);
        if (!isNumber) {
          return 'Неправильный формат заполнения! Допустимый формат значений - числа!';
        }
      },
      onBlur: handleBlur,
    },
    inputNumberFractionalDoubleValidationScheme: {
      validate: (value) => {
        const str = value.toString();
        if (str.split('')[str.length - 1] === '.') {
          return 'Неправильный формат заполнения! Допустимый формат значений - числа!';
        }
        const isNumber = Utils.testIsNumber(value);
        if (!isNumber) {
          return 'Неправильный формат заполнения! Допустимый формат значений - числа!';
        }
      },
      onBlur: handleBlur,
    },
    inputCoordinatesPointGpsCoordinatesPointValidationScheme: {
      maxLength: {
        value: 64,
        message: 'Поле может содержать максимум 64 символов',
      },
      pattern: /^-?\d{1,3}(\.\d+)?\s*,\s*-?\d{1,3}(\.\d+)?$/,
      onBlur: handleBlur,
    },
    inputCoordinatesPointLineCoordinatesLineValidationScheme: {
      maxLength: {
        value: 255,
        message: 'Поле может содержать максимум 255 символов',
      },
      pattern: /^-?\d{1,3}(\.\d+)?\s*,\s*-?\d{1,3}(\.\d+)?(\s*\|\s*-?\d{1,3}(\.\d+)?\s*,\s*-?\d{1,3}(\.\d+)?)*$/,
      onBlur: handleBlur,
    },
    inputNumberTimeBetweenCallValidationScheme: {
      maxLength: {
        value: 3,
        message: 'Поле может содержать максимум 3 символов',
      },
      min: {
        value: 0,
        message: 'Минимальное допустимое значение 0',
      },
      max: {
        value: 255,
        message: 'Максимальное допустимое значение 255',
      },
      validate: (value) => {
        const str = value.toString();
        if (str.split('').includes('.')) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isNumber = Utils.testIsNumber(value);
        if (!isNumber) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isInteger = Utils.testIsInteger(value);
        if (!isInteger) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
      },
      onBlur: handleBlur,
    },
    inputNumberTimeWaitCallValidationScheme: {
      minLength: {
        value: 1,
        message: 'Поле должно содержать минимум 1 символ',
      },
      maxLength: {
        value: 3,
        message: 'Поле может содержать максимум 3 символов',
      },
      min: {
        value: 0,
        message: 'Минимальное допустимое значение 0',
      },
      max: {
        value: 255,
        message: 'Максимальное допустимое значение 255',
      },
      validate: (value) => {
        const str = value.toString();
        if (str.split('').includes('.')) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isNumber = Utils.testIsNumber(value);
        if (!isNumber) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
        const isInteger = Utils.testIsInteger(value);
        if (!isInteger) {
          return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
        }
      },
      onBlur: handleBlur,
    },
    inputPhonePhoneValidationScheme: {
      validate: () => inputRef.current.value.length === 18 || inputRef.current.value.length === 0,
      onBlur: handleBlur,
    },
    opcuaExternalTagString255ValidationScheme: {
      maxLength: {
        value: 255,
        message: 'Общая длинна не должна превышать 255 символов',
      },
      onBlur: handleBlur,
    },
  }
);