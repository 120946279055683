import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NavApp, ContentApp, HeaderApp } from '../../layout/layout.mjs';
import { ErrorMessagePopup, EventLogFilter, EventLogsList } from '../../components/components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { flagsParser } from '../../utils/flags-parser/flagsParser.mjs';
import { useUserTokenDecode } from '../../hooks/hooks.mjs';

import styles from './eventLogs.module.scss';

export default function EventLogs() {
  const navigate = useNavigate();
  const { kernel, currentLicense } = useKernel();

  const [userName, setUserName] = useState('');
  const [isError, setIsError] = useState(false); //если есть какая-то ошибка
  const [errorMessage, setErrorMessage] = useState('Ошибка!'); //сообщение об ошибке
  const { flags } = useUserTokenDecode();
  const { hasAccessRightsEventLogsModule } = flagsParser(flags);

  const actionPerformErrorMessagePopup = () => {
    return setIsError(false);
  };

  const initialPageUserState = () => {
    setUserName(kernel.getUser().name);
  };

  useEffect(() => {
    initialPageUserState();
    kernel.timerRefreshToken(); //запуск обновления токена
    return () => {};
  }, []);

  useEffect(() => {
    const { hasAccessEventLogsModule = undefined } = currentLicense;
    if (hasAccessEventLogsModule !== 'true' || hasAccessEventLogsModule === undefined || hasAccessRightsEventLogsModule === false) {
      navigate('/section-selection');
    }
    return () => {};
  }, [currentLicense]);

  return (
    <div className={styles.eventLogs}>
      {isError && <ErrorMessagePopup errorMessage={errorMessage} actionPerform={actionPerformErrorMessagePopup} />}

      <NavApp />
      <ContentApp>
        <div className={styles.headerContainer}>
          <HeaderApp configurationName="Журнал событий" userName={userName} />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.eventLogFilterContainer}>
            <EventLogFilter />
          </div>
          {/* <div className={styles.componentEventLogsContainer}> */}
          <EventLogsList />
          {/* </div> */}
        </div>
      </ContentApp>
    </div>
  );
}
