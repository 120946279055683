import classNames from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useKernel } from '../../context/ContextKernel.mjs';
import CustomSelect from '../custom-select/CustomSelect';

import styles from './eventLogFilter.module.scss';

export default function EventLogFilter(props) {
  const { kernel } = useKernel();

  const {
    register,
    unregister,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    console.log('data: ', data);
    try {
    } catch (error) {}
  };

  useEffect(() => {
    //получаем через Promise all данные: сервисы, экземпляры сервисов, группы пользователей, пользователей, список операций
    //создаем обьекты для option select
    //помещаем данные обьекты в state select (это нужно потому, что данные в select будут меняться в зависимости от выбора в предыдущем select)
    //
    return () => {};
  }, []);

  return (
    <div className={styles.eventLogFilter}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>Фильтр журналов</span>
      </div>
      <form className={styles.controllersContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.controllersItem}>
          <CustomSelect
            value={1}
            setValue={setValue}
            register={register}
            unregister={unregister}
            name="value"
            // disabled={canEdit && isWritable && isDeleted && sendingData}
            disabled={false}
            isMulti={false}
            options={[
              { value: 1, label: 'Включен', isDisabled: false },
              { value: 2, label: 'Option_2', isDisabled: false },
              { value: 3, label: 'Option_3', isDisabled: false },
              { value: 4, label: 'Option_4', isDisabled: false },
              { value: 5, label: 'Option_5', isDisabled: false },
              { value: 6, label: 'Option_6', isDisabled: false },
              { value: 7, label: 'Option_7', isDisabled: false },
            ]}
            // setHandleChangeComponentTableRow={setHandleChangeComponentTableRow}
          />
        </div>
        <div className={styles.controllersItem}></div>
        <div className={styles.controllersItem}></div>
        <div className={styles.controllersItem}></div>
        <div className={styles.controllersItem}></div>
        <div className={styles.controllersItem}></div>
        <div className={styles.controllersItem}></div>

        <button type="submit" className={classNames(styles.buttonSubmit)}>
          Получить лог
        </button>
      </form>
    </div>
  );
}
