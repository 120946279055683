import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { HiCheck } from 'react-icons/hi';

import Utils from '../../utils/Utils.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { SavePropertiesButtonModuleRs } from '../components.mjs';

import styles from './tableRowBoardAddressesModule.module.scss';

export default function TableRowBoardAddressesModuleAiDi(props) {
  const { stateElementConfigurationId, selectedParameter, setIsError, setErrorMessage } = props;

  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [errorParse, setErrorParse] = useState(false);
  const [changesDate, setChangesDate] = useState({ day: '', hour: '' }); // вывод даты и времени последнего изменения свойства (выводится время драйвер адреса)
  const [boardAddressStateValue, setBoardAddressStateValue] = useState(); // состояние компонента

  const dataUpdateInputModeStatus = useRef(false); // изменилось ли значение компонента состояние компонента или нет
  const { kernel } = useKernel();

  const saveProperty = async () => {
    setHandleChangeComponentTableRow(false);
    setSendingData(true);

    try {
      const parametersDriverAddress = createParametersDriverAddressObj();
      const boardAddresesValue = parseBoardAddress(parametersDriverAddress);

      const resultValuesArr = boardAddresesValue.map((boardAddreseValue) => {
        const parameterStruct = kernel.getParameterById(boardAddreseValue?.parameterDriverAddressId);
        const newValue = boardAddreseValue.parameterDriverAddressValue.replace(boardAddreseValue.boardAddressValue, boardAddressStateValue);

        return { parameterStruct, newValue };
      });

      await kernel.setValues(resultValuesArr);

      setDataSentSuccessfully(true);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setSendingData(false);
      }, 1500);
    } catch (error) {
      setSendingData(false);
      setIsError(true);
      setErrorMessage('Ошибка сохранения!');

      setBoardAddressStateValue(0);
      setHandleChangeComponentTableRow(false);
    }
  };

  const handleChangeSelectBoardAddress = (event) => {
    setBoardAddressStateValue(event.target.value);

    const parametersDriverAddress = createParametersDriverAddressObj();
    const boardAddresesValue = parseBoardAddress(parametersDriverAddress);
    const initialBoardAddress = boardAddresesValue[0].boardAddressValue; //начальное значение компонента

    if (event.target.value.toString() === initialBoardAddress.toString() && !errorParse) {
      dataUpdateInputModeStatus.current = false;
    } else {
      dataUpdateInputModeStatus.current = true;
    }

    if (dataUpdateInputModeStatus.current) {
      setHandleChangeComponentTableRow(true);
    } else {
      setHandleChangeComponentTableRow(false);
    }
  };

  const createParametersDriverAddressObj = () => {
    const parametersDriverAddress = selectedParameter.children.map((children) =>
      children.properties.find((childrenProperties) => childrenProperties.systemTypeId === 66),
    );
    return parametersDriverAddress;
  };

  const parseBoardAddress = (parametersDriverAddress) => {
    const parametersDriverAddressItemsValue = parametersDriverAddress.map((parameterDriverAddressItems) => {
      return {
        parameterDriverAddressId: parameterDriverAddressItems.id,
        parameterDriverAddressValue: parameterDriverAddressItems.propertyValueRoundUp.toString(),
        boardAddressValue: parameterDriverAddressItems.propertyValueRoundUp.toString().slice(1, 3),
      };
    });

    return parametersDriverAddressItemsValue;
  };

  useEffect(() => {
    const parametersDriverAddress = createParametersDriverAddressObj();
    if (parametersDriverAddress) {
      const day = Utils.convertTimestamp(parametersDriverAddress[0]?.propertyValueTimestamp);
      const hour = Utils.convertTimestampGetHour(parametersDriverAddress[0]?.propertyValueTimestamp);
      setChangesDate({ day: day, hour: hour });

      const boardAddresesValue = parseBoardAddress(parametersDriverAddress);

      // переделать
      const testBoardAddresesValue = boardAddresesValue.every(
        (currentValue) => boardAddresesValue[0].boardAddressValue === currentValue.boardAddressValue,
      );

      if (!testBoardAddresesValue) {
        setErrorParse(true);

        // инициализируем состояние компонента
        setBoardAddressStateValue(0);
        // сбрасываем состояние изменения компонента
        setHandleChangeComponentTableRow(false);
      } else {
        setErrorParse(false);
        const boardAddreses = boardAddresesValue[0].boardAddressValue;

        // инициализируем состояние компонента
        setBoardAddressStateValue(boardAddreses);
        // сбрасываем состояние изменения компонента
        setHandleChangeComponentTableRow(false);
      }
    }

    return () => {};
  }, [stateElementConfigurationId]);

  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableRowTd}>
        <span className={styles.tableRowText} title={'Адрес платы'}>
          Адрес платы
        </span>
      </td>
      <td className={styles.tableRowTd}>
        {/* компонент */}
        <div className={styles.workingModeAiDi}>
          <select
            className={classNames(styles.selectInputMode, sendingData && styles.sending)}
            value={boardAddressStateValue}
            onInput={handleChangeSelectBoardAddress}
            disabled={sendingData}
          >
            {Utils.boardAddressOptionValue.map((inputModeOption) => {
              return (
                <option key={inputModeOption.valueOption} value={inputModeOption.valueOption} className={styles.selectInputModeOption}>
                  {inputModeOption.textOption}
                </option>
              );
            })}
            {errorParse && (
              <option key={0} value={0} className={styles.selectInputModeOption}>
                Значение не определено
              </option>
            )}
          </select>
        </div>
        {/* компонент */}
      </td>
      <td className={styles.tableRowTd}>
        <span className={classNames(styles.tableRowText, styles.tableDate)}>{changesDate.day}</span>
        <span className={styles.tableRowText}>{changesDate.hour}</span>
      </td>
      <td className={styles.tableRowTd}>
        {dataSentSuccessfully ? (
          <HiCheck className={styles.checkControl} />
        ) : (
          <SavePropertiesButtonModuleRs
            sendingData={sendingData}
            handleChangeComponentTableRow={handleChangeComponentTableRow}
            saveProperty={saveProperty}
          />
        )}
      </td>
    </tr>
  );
}
