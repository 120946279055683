import { GroupForm, GroupFormChangeCurrentGroup, SelectActionsOnUsersPage, UserForm, UserFormChangeCurrentUser } from '../components/components.mjs';
import { useKernel } from '../context/ContextKernel.mjs';

export function ComponentUser(props) {
  const {
    componentsTypeId,
    setComponentsTypeId, //если 2 то это id пользователя если 4 то id группы
    setIdTreeElement,
    idTreeElement,
    setIsError,
    setErrorMessage,
    usersTree,
    initialStateUserTree,
    setUsersTree,
    setInitialStateUserTree,
  } = props;

  const { kernel } = useKernel();
  const users = kernel.users;
  const group = kernel.usersGroups;
  let dataForm;

  if (componentsTypeId === 2) {
    dataForm = users[idTreeElement];
  } else if (componentsTypeId === 4) {
    dataForm = group[idTreeElement];
  }

  const componentsByUsers = {};

  const SELECT_ACTIONS_USERS = 0;
  const CREATE_NEW_USER = 1;
  const CHANGE_CURRENT_USER = 2;
  const CREATE_NEW_GROUP = 3;
  const CHANGE_CURRENT_GROUP = 4;

  componentsByUsers[SELECT_ACTIONS_USERS] = (
    <SelectActionsOnUsersPage setIdTreeElement={setIdTreeElement} setComponentsTypeId={setComponentsTypeId} />
  );

  componentsByUsers[CREATE_NEW_USER] = (
    <UserForm
      idTreeElement={idTreeElement}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      usersTree={usersTree}
      initialStateUserTree={initialStateUserTree}
      setUsersTree={setUsersTree}
      setInitialStateUserTree={setInitialStateUserTree}
      setComponentsTypeId={setComponentsTypeId}
    />
  );

  componentsByUsers[CHANGE_CURRENT_USER] = (
    <UserFormChangeCurrentUser
      idTreeElement={idTreeElement}
      setComponentsTypeId={setComponentsTypeId}
      dataForm={dataForm}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      usersTree={usersTree}
      initialStateUserTree={initialStateUserTree}
      setUsersTree={setUsersTree}
      setInitialStateUserTree={setInitialStateUserTree}
    />
  );

  componentsByUsers[CREATE_NEW_GROUP] = (
    <GroupForm
      setComponentsTypeId={setComponentsTypeId}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      usersTree={usersTree}
      initialStateUserTree={initialStateUserTree}
      setUsersTree={setUsersTree}
      setInitialStateUserTree={setInitialStateUserTree}
    />
  );

  componentsByUsers[CHANGE_CURRENT_GROUP] = (
    <GroupFormChangeCurrentGroup
      setComponentsTypeId={setComponentsTypeId}
      dataForm={dataForm}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      usersTree={usersTree}
      initialStateUserTree={initialStateUserTree}
      setUsersTree={setUsersTree}
      setInitialStateUserTree={setInitialStateUserTree}
    />
  );

  let components;
  const setComponent = () => {
    if (componentsTypeId in componentsByUsers) {
      components = componentsByUsers[componentsTypeId];
    } else {
      components = <p>Произошла ошибка при выборе действия!</p>;
    }
  };
  setComponent();

  return <>{components}</>;
}
