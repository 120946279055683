import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';

import { LoadingInUserPageForm } from '../components.mjs';

import styles from './authFormPopUp.module.scss';

export default function AuthFormPopUp(props) {
  const { closePopup, success, loading, textButton, actionConfirmation, hasField = {} } = props;
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });

  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <AiOutlineCloseCircle className={styles.messageClose} onClick={closePopup} />
        <h3 className={styles.title}>Авторизуйтесь</h3>
        <form className={styles.form} onSubmit={handleSubmit(actionConfirmation)}>
          <div className={styles.formContainer}>
            {hasField?.name && (
              <div className={styles.inputWrap}>
                <input
                  className={classNames(styles.input, errors.name && styles.inputError)}
                  type="text"
                  placeholder="Имя пользователя"
                  {...register('name', {
                    required: 'Поле обязательно к заполнению',
                    minLength: {
                      value: 3,
                      message: 'Поле должно содержать минимум 3 символа',
                    },
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символа',
                    },
                  })}
                />
                {errors?.name && <p className={styles.errorMessage}>{errors?.name?.message || 'Ошибка заполнения!'}</p>}
              </div>
            )}
            {hasField?.password && (
              <div className={styles.inputWrap}>
                <input
                  className={classNames(styles.input, errors.password && styles.inputError)}
                  type="password"
                  autoComplete="current-password"
                  placeholder="Пароль"
                  {...register('password', {
                    required: 'Поле обязательно к заполнению',
                    minLength: {
                      value: 5,
                      message: 'Поле должно содержать минимум 5 символов',
                    },
                    maxLength: {
                      value: 255,
                      message: 'Поле может содержать максимум 255 символа',
                    },
                  })}
                />
                {errors?.password && <p className={styles.errorMessage}>{errors?.password?.message || 'Ошибка заполнения!'}</p>}
              </div>
            )}
          </div>
          <div className={styles.controlBlock}>
            <button
              className={
                loading || success
                  ? styles.creatingNewModuleFormSubmitSending
                  : classNames(styles.button, isValid ? styles.formValid : styles.formNoValid)
              }
              type="submit"
              disabled={loading || success || !isValid}
            >
              {success ? <HiCheck className={styles.checkControl} /> : loading ? <LoadingInUserPageForm /> : textButton}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
