import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ImInsertTemplate } from 'react-icons/im';
import { PiFunctionLight } from 'react-icons/pi';

import { SetpointsService } from '../../services/services.mjs';
import { ReactComponent as BsArrowRightCircleFill } from '../../assets/icon/BsArrowLeftCircle.svg';
import { useKernel } from '../../context/ContextKernel.mjs';
import { ErrorMessagePopup, SetpointsEditor, TreeConfiguration, TreeTemplateSetpoints } from '../../components/components.mjs';
import { ContentApp, HeaderApp, NavApp } from '../../layout/layout.mjs';
import { flagsParser } from '../../utils/flags-parser/flagsParser.mjs';
import { useActiveConfigurationId } from '../../hooks/useActiveConfigurationId.mjs';
import { useUserTokenDecode } from '../../hooks/hooks.mjs';

import styles from './setpoints.module.scss';

export default function Setpoints() {
  const location = useLocation();
  const navigate = useNavigate();
  const [configuration, setConfiguration] = useState(null);
  const [activeElementTree, setActiveElementTree] = useState(0);
  const [configurationName, setConfigurationName] = useState(null);
  const [stateElementConfigurationId, setStateElementConfigurationId] = useState(0n);
  const [userName, setUserName] = useState('');
  const [isError, setIsError] = useState(false);
  const [isErrorLoadingConfiguration, setIsErrorLoadingConfiguration] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Ошибка!');
  const [componentsTypeId, setComponentsTypeId] = useState(0);
  const [selectedParameter, setSelectedParameter] = useState({});
  const [rotate, setRotate] = useState(false);

  const [setpointsEditorState, setSetpointsEditorState] = useState({ setpointsList: [], activeSetpoint: {}, activeSetpointId: null });
  const [treeTemplateState, setTreeTemplateState] = useState([]);

  const [treeTab, setTreeTab] = useState(1);
  const { kernel, currentLicense } = useKernel();
  const { activeConfigurationId } = useActiveConfigurationId();
  const { flags } = useUserTokenDecode();
  const { hasAccessRightsSetpointsModule } = flagsParser(flags);

  const handleChangeTreeTabItem = ({ currentTarget: { dataset } }) => {
    setTreeTab(parseInt(dataset.treeTabId));
  };

  const getSetpoints = async () => {
    try {
      const { items } = await SetpointsService.getSetpointsListStruct(activeConfigurationId);
      kernel.buildSetpoints(items);
    } catch (error) {
      setIsErrorLoadingConfiguration(true);
      setErrorMessage('Ошибка загрузки данных по уставкам!');
    }
  };

  const buildConfigurationTree = async () => {
    try {
      const { tree } = await kernel.buildTree(location);

      setConfiguration(tree);
      setConfigurationName(kernel.configuration.name);
      setUserName(kernel.getUser().name);
    } catch (error) {
      setIsErrorLoadingConfiguration(true);
      setErrorMessage('Ошибка загрузки конфигурации!');
    }
  };

  useEffect(() => {
    buildConfigurationTree();
    getSetpoints();

    kernel.timerRefreshToken(); //запуск обновления токена
    kernel.setNavigatePath('/setpoints');
    return () => {};
  }, []);

  useEffect(() => {
    setActiveElementTree(0n);
    setStateElementConfigurationId(0n);
    return () => {};
  }, [treeTab]);

  useEffect(() => {
    const { hasAccessSetpointsModule = undefined } = currentLicense;
    if (hasAccessSetpointsModule !== 'true' || hasAccessSetpointsModule === undefined || hasAccessRightsSetpointsModule === false) {
      navigate('/section-selection');
    }
    return () => {};
  }, [currentLicense]);

  return (
    <div className={styles.setpoints}>
      {isError && <ErrorMessagePopup errorMessage={errorMessage} actionPerform={() => setIsError(false)} />}
      {isErrorLoadingConfiguration && (
        <ErrorMessagePopup
          errorMessage={errorMessage}
          actionPerform={() => {
            setIsErrorLoadingConfiguration(false);
            kernel.setNavigatePath('/setpoints');
            navigate('/configuration-selection');
          }}
        />
      )}
      <NavApp />
      <ContentApp>
        <div className={styles.headerContainer}>
          <HeaderApp configurationName={configurationName} userName={userName} />
        </div>
        <div className={styles.contentContainer}>
          <div className={classNames(styles.treeContainer, rotate && styles.collapsedTree)}>
            <div className={classNames(styles.treeTab, rotate && styles.collapsedTreeTab)}>
              <div
                data-tree-tab-id={1}
                className={classNames(styles.treeTabItem, treeTab === 1 && styles.treeTabItemActive)}
                onClick={handleChangeTreeTabItem}
              >
                <PiFunctionLight className={styles.treeTabIcon} />
                <span className={styles.treeTabItemText}>Специальные</span>
              </div>
              <div
                data-tree-tab-id={2}
                className={classNames(styles.treeTabItem, treeTab === 2 && styles.treeTabItemActive)}
                onClick={handleChangeTreeTabItem}
              >
                <ImInsertTemplate className={styles.treeTabIcon} />
                <span className={styles.treeTabItemText}>Шаблонные</span>
              </div>
            </div>
            <div className={classNames(styles.treeWrap, rotate && styles.collapsedTreeWrap)}>
              {treeTab === 1 ? (
                <TreeConfiguration
                  configuration={configuration}
                  stateElementConfigurationId={stateElementConfigurationId}
                  setStateElementConfigurationId={setStateElementConfigurationId}
                  setComponentsTypeId={setComponentsTypeId}
                  setSelectedParameter={setSelectedParameter}
                  parameterDisabled={true}
                />
              ) : (
                <TreeTemplateSetpoints
                  configuration={configuration}
                  setSetpointsEditorState={setSetpointsEditorState}
                  activeElementTree={activeElementTree}
                  setActiveElementTree={setActiveElementTree}
                  treeTemplateState={treeTemplateState}
                  setTreeTemplateState={setTreeTemplateState}
                />
              )}
            </div>
            <BsArrowRightCircleFill
              className={classNames(styles.treeControl, rotate && styles.treeControlActive)}
              onClick={() => setRotate(!rotate)}
            />
          </div>
          <div className={styles.codeEditorContainer}>
            <SetpointsEditor
              stateElementConfigurationId={stateElementConfigurationId}
              setIsError={setIsError}
              setErrorMessage={setErrorMessage}
              configuration={configuration}
              setpointsEditorState={setpointsEditorState}
              setSetpointsEditorState={setSetpointsEditorState}
              treeTab={treeTab}
              setTreeTemplateState={setTreeTemplateState}
              activeElementTree={activeElementTree}
              setActiveElementTree={setActiveElementTree}
            />
          </div>
        </div>
      </ContentApp>
    </div>
  );
}
