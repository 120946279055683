import { Struct } from './Struct.mjs'

const TYPE_DEFAULT = 0
const TYPE_NUMBER = 1
const TYPE_LOGICAL = 2
const TYPE_DATETIME = 3
const TYPE_STRING = 4
const TYPE_SOURCE = 255

const TEXT_TRANSFORM_NONE = 0
const TEXT_TRANSFORM_UPPERCASE = 1
const TEXT_TRANSFORM_LOWERCASE = 2
const TEXT_TRANSFORM_UCFIRST = 3
const TEXT_TRANSFORM_UCWORDS = 4

const BITS_MASK_DISPLAY_UNIT_NAME = 0x01
const BITS_MASK_GROUP_DIGITS = 0x02
const BITS_MASK_DISPLAY_DATE = 0x04
const BITS_MASK_DISPLAY_TIME = 0x08

class FormatEncodedStruct extends Struct {

    constructor(
        type = TYPE_SOURCE,
        fractionDigits = 0,
        textTransform = TEXT_TRANSFORM_NONE,
        bits = BITS_MASK_GROUP_DIGITS | BITS_MASK_DISPLAY_DATE | BITS_MASK_DISPLAY_TIME
    ) {
        super()
        this.type = type
        this.fractionDigits = fractionDigits
        this.textTransform = textTransform
        this.bits = bits
    }

    readFrom(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        this.type = dataView.getUint8(endOffset)
        endOffset ++
        this.fractionDigits = dataView.getUint8(endOffset)
        endOffset ++
        this.textTransform = dataView.getUint8(endOffset)
        endOffset ++
        this.bits = dataView.getUint8(endOffset)
        endOffset ++
        return endOffset
    }

    writeTo(dataView, startOffset, isLittleEndian = true) {
        let endOffset = startOffset
        dataView.setUint8(endOffset, this.type)
        endOffset ++
        dataView.setUint8(endOffset, this.fractionDigits)
        endOffset ++
        dataView.setUint8(endOffset, this.textTransform)
        endOffset ++
        dataView.setUint8(endOffset, this.bits)
        endOffset ++
        return endOffset
    }

    toInt() {
        let value = 0
        value = value * 256 + this.type
        value = value * 256 + this.fractionDigits
        value = value * 256 + this.textTransform
        value = value * 256 + this.bits
        return value
    }

    static fromInt(value) {
        let bits = value & 0xff
        value /= 256
        let textTransform = value & 0xff
        value /= 256
        let fractionDigits = value & 0xff
        value /= 256
        let type = value & 0xff
        return new FormatEncodedStruct(type, fractionDigits, textTransform, bits)
    }

    getCurrentSize() {
        return 0
            + 1 // type
            + 1 // fractionDigits
            + 1 // textTransform
            + 1 // bits
    }

    clone() {
        let clone = new FormatEncodedStruct(this.type, this.fractionDigits, this.textTransform, this.bits)
        clone.setParserOptions(this.getParserOptions())
        return clone
    }

    get displayUnitName() {
        return (this.bits & BITS_MASK_DISPLAY_UNIT_NAME) !== 0
    }

    set displayUnitName(value) {
        if (value) {
            this.bits |= BITS_MASK_DISPLAY_UNIT_NAME
        } else {
            this.bits &= ~BITS_MASK_DISPLAY_UNIT_NAME
        }
    }

    get groupDigits() {
        return (this.bits & BITS_MASK_GROUP_DIGITS) !== 0
    }

    set groupDigits(value) {
        if (value) {
            this.bits |= BITS_MASK_GROUP_DIGITS
        } else {
            this.bits &= ~BITS_MASK_GROUP_DIGITS
        }
    }

    get displayDate() {
        return (this.bits & BITS_MASK_DISPLAY_DATE) !== 0
    }

    set displayDate(value) {
        if (value) {
            this.bits |= BITS_MASK_DISPLAY_DATE
        } else {
            this.bits &= ~BITS_MASK_DISPLAY_DATE
        }
    }

    get displayTime() {
        return (this.bits & BITS_MASK_DISPLAY_TIME) !== 0
    }

    set displayTime(value) {
        if (value) {
            this.bits |= BITS_MASK_DISPLAY_TIME
        } else {
            this.bits &= ~BITS_MASK_DISPLAY_TIME
        }
    }
}

FormatEncodedStruct.TYPE_DEFAULT = TYPE_DEFAULT
FormatEncodedStruct.TYPE_NUMBER = TYPE_NUMBER
FormatEncodedStruct.TYPE_LOGICAL = TYPE_LOGICAL
FormatEncodedStruct.TYPE_DATETIME = TYPE_DATETIME
FormatEncodedStruct.TYPE_STRING = TYPE_STRING
FormatEncodedStruct.TYPE_SOURCE = TYPE_SOURCE

FormatEncodedStruct.BITS_MASK_DISPLAY_UNIT_NAME = BITS_MASK_DISPLAY_UNIT_NAME
FormatEncodedStruct.BITS_MASK_GROUP_DIGITS = BITS_MASK_GROUP_DIGITS
FormatEncodedStruct.BITS_MASK_DISPLAY_DATE = BITS_MASK_DISPLAY_DATE
FormatEncodedStruct.BITS_MASK_DISPLAY_TIME = BITS_MASK_DISPLAY_TIME

FormatEncodedStruct.TEXT_TRANSFORM_NONE = TEXT_TRANSFORM_NONE
FormatEncodedStruct.TEXT_TRANSFORM_UPPERCASE = TEXT_TRANSFORM_UPPERCASE
FormatEncodedStruct.TEXT_TRANSFORM_LOWERCASE = TEXT_TRANSFORM_LOWERCASE
FormatEncodedStruct.TEXT_TRANSFORM_UCFIRST = TEXT_TRANSFORM_UCFIRST
FormatEncodedStruct.TEXT_TRANSFORM_UCWORDS = TEXT_TRANSFORM_UCWORDS

export {

    FormatEncodedStruct
}
