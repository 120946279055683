import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineExitToApp } from 'react-icons/md';

import { useUserTokenDecode } from '../../hooks/hooks.mjs';
import { AddNewConfiguration, CreateNewConfiguration, ErrorMessagePopup, Loading, ConfigurationList } from '../../components/components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';
import { ReferenceBooksService, ConfigurationService } from '../../services/services.mjs';
import { flagsParser } from '../../utils/flags-parser/flagsParser.mjs';

import styles from './configurationSelection.module.scss';

export default function ConfigurationSelection() {
  const [configurationData, setConfigurationData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Ошибка!');
  const [isAddingConfig, setIsAddingConfig] = useState(false);
  const { kernel, currentLicense } = useKernel();
  const { hasAccessCreatingConfigurations } = currentLicense;
  const { flags } = useUserTokenDecode();
  const { hasAccessRightsCreatingConfigurations } = flagsParser(flags);

  const navigate = useNavigate();

  const getData = () => {
    Promise.all([
      ReferenceBooksService.blockTypesList(),
      ReferenceBooksService.systemTypesList(),
      ReferenceBooksService.editorSettingsList(),
      ConfigurationService.getConfigurationElem(),
    ])
      .then((values) => {
        const [blockTypesList, systemTypesList, editorSettingsList, configurationItems] = values;

        kernel.buildBlockTypes(blockTypesList.items);
        kernel.buildSystemTypes(systemTypesList.items);
        kernel.buildEditorSettings(editorSettingsList.items);
        setConfigurationData(configurationItems.items);

        if (configurationItems.items.length === 1 && hasAccessRightsCreatingConfigurations === false) {
          const [configurationStruct] = configurationItems.items;
          kernel.justOneConfiguration = true;
          redirect(configurationStruct.id);
        }
        setLoadData(true);
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage(error.message);
        console.error(error);
      });
  };

  const actionPerformErrorMessagePopup = () => {
    setIsError(false);
    getData();
  };

  const redirect = (idConfig) => {
    localStorage.setItem('activeIdConfig', JSON.stringify({ id: idConfig }));

    const pathLocation = kernel.getNavigatePath() ?? '/property-editor';
    return navigate(pathLocation, { state: { id: idConfig } });
  };

  const appExit = () => {
    kernel.removeTimerRefreshToken();
    localStorage.removeItem('token');
    localStorage.removeItem('activeIdConfig');
    kernel.justOneConfiguration = false;
    kernel.logoutUser();
    return navigate('/');
  };

  useEffect(() => {
    getData();
    kernel.timerRefreshToken(); //запуск обновления токена
    return () => {};
  }, []);

  return (
    <div className={styles.configuration}>
      {isError && <ErrorMessagePopup errorMessage={errorMessage} actionPerform={actionPerformErrorMessagePopup} />}
      {isAddingConfig && (
        <CreateNewConfiguration
          setIsAddingConfig={setIsAddingConfig}
          configurationData={configurationData}
          setConfigurationData={setConfigurationData}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
        />
      )}
      <div className={styles.wrap}>
        <div className={styles.containerText}>
          <h1 className={styles.configurationTitle}>Выберите конфигурацию:</h1>
        </div>
        <div className={styles.configurationWrap}>
          {!loadData ? (
            <Loading />
          ) : configurationData.length === 0 ? (
            <>
              <span className={styles.configurationMessage}>
                Для данной учетной записи конфигураций не найдено. Обратитесь к ответственному лицу.
              </span>
              <div onClick={appExit} className={styles.exit}>
                <span className={styles.exitText}>Выйти</span>
                <MdOutlineExitToApp className={styles.exitIcon} />
              </div>
            </>
          ) : (
            <ConfigurationList configurationData={configurationData} redirect={redirect} />
          )}
        </div>
        {hasAccessCreatingConfigurations !== undefined &&
        hasAccessCreatingConfigurations === 'true' &&
        hasAccessRightsCreatingConfigurations === true ? (
          <AddNewConfiguration setIsAddingConfig={setIsAddingConfig} />
        ) : null}
      </div>
    </div>
  );
}
