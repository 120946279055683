import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import { AiTwotoneSave } from 'react-icons/ai';

import { useKernel } from '../../context/ContextKernel.mjs';
import { LoadingInTableSave } from '../components.mjs';
import { RulesService } from '../../services/services.mjs';
import Utils from '../../utils/Utils.mjs';

import styles from './tableRowSettingsPermission.module.scss';

const CheckBox = (props) => {
  const { rules, data, state, setState, handleChangeRules } = props;

  const handleChange = () => {
    // если отказано в доступе то снимать галочки везде
    setState(
      state.map((objState) => {
        if (objState.name === data.name) {
          if (data.name === 'accessDenied') {
            rules[data.name] = !objState.checked; // изменение данных в kernel
          }

          if (data.name !== 'accessDenied' && rules['accessDenied'] !== true) {
            rules[data.name] = !objState.checked; // изменение данных в kernel
          }
        }

        return {
          ...objState,
          checked: rules[objState.name],
        };
      }),
    );
    handleChangeRules();
  };

  const getAccessDeniedItemState = () => {
    return data.name === 'accessDenied' ? false : state.find((itemsState) => itemsState.name === 'accessDenied').checked;
  };

  return (
    <div className={classNames(styles.control)}>
      <label className={classNames(styles.checkbox, styles.hasLabel)} htmlFor={data.id}>
        <div className={styles.checkBoxContainer}>
          <input type="checkbox" className={styles.controlInput} id={data.id} checked={data.checked} onInput={handleChange} />
          <span className={styles.checkIcon}></span>
        </div>
        <span className={classNames(styles.tableRowText, styles.checkBoxText, getAccessDeniedItemState() && styles.disabled)} title={data.text}>
          {data.text}
        </span>
      </label>
    </div>
  );
};

export default function TableRowSettingsPermission(props) {
  const { group, setIsError, setErrorMessage, stateElementConfigurationId } = props;
  const { kernel } = useKernel();

  const { id: groupId, name: groupName } = group;

  const rules = kernel.getRulesByParameterIdAndGroupId(stateElementConfigurationId, stateElementConfigurationId, groupId);
  const { accessDenied, isReadable, canAdd, isWritable, canDelete, canEditAccess, isAcknowledgeable } = rules;

  const [sendingData, setSendingData] = useState(false);
  const [dataSentSuccessfully, setDataSentSuccessfully] = useState(false);
  const [state, setState] = useState([]);

  const [handleChangeComponentTableRow, setHandleChangeComponentTableRow] = useState(false);

  const initialState = useRef({});

  const handleChangeRules = () => {
    const parameterId = rules.parameterId;
    const ruleId = rules.groupId;
    const rule = kernel.getRulesByIdAndParameterId(ruleId, parameterId);

    let test = false;
    for (const key in initialState.current) {
      if (initialState.current[key] !== rule[key]) {
        test = true;
      }
    }

    setHandleChangeComponentTableRow(test);
  };

  const saveGroupAccessSettings = async (data) => {
    setHandleChangeComponentTableRow(false);
    setSendingData(true);
    try {
      const ruleParameterId = rules.parameterId;
      const ruleId = rules.groupId;
      const rule = kernel.getRulesByIdAndParameterId(ruleId, ruleParameterId);

      const id = rule.rulesStruct.id.toString();
      const groupId = rule.rulesStruct.groupId.toString();
      const configId = rule.rulesStruct.configId.toString();
      const parameterId = rule.rulesStruct.parameterId.toString();
      const type = rule.rulesStruct.type.toString();

      // вызов  сервиса для сохранения данных
      await RulesService.changeRules({ id, groupId, configId, parameterId, type });
      // kernel.buildRules(rulesListStruct.items);

      const Rules = kernel.getRulesByIdAndParameterId(BigInt(groupId), parameterId);

      // обновление начального состояние
      initialState.current = {
        accessDenied: Rules.accessDenied,
        isReadable: Rules.isReadable,
        canAdd: Rules.canAdd,
        isWritable: Rules.isWritable,
        canDelete: Rules.canDelete,
        canEditAccess: Rules.canEditAccess,
        isAcknowledgeable: Rules.isAcknowledgeable,
      };

      setDataSentSuccessfully(true);
      setTimeout(() => {
        setDataSentSuccessfully(false);
        setSendingData(false);
      }, 1000);
    } catch (error) {
      setSendingData(false);
      //показать сообщение об ошибке
      setIsError(true);
      setErrorMessage('Ошибка сохранения!');
      setHandleChangeComponentTableRow(true);
    }
  };

  useEffect(() => {
    initialState.current = {
      accessDenied: accessDenied,
      isReadable: isReadable,
      canAdd: canAdd,
      isWritable: isWritable,
      canDelete: canDelete,
      canEditAccess: canEditAccess,
      isAcknowledgeable: isAcknowledgeable,
    };
    setState([
      {
        name: 'accessDenied',
        column: 1,
        row: 1,
        checked: accessDenied,
        id: `accessDenied${groupId}`,
        text: 'Отказано в доступе',
      },
      {
        name: 'isReadable',
        column: 1,
        row: 2,
        checked: isReadable,
        id: `isReadable${groupId}`,
        text: 'Чтение',
      },
      {
        name: 'canAdd',
        column: 1,
        row: 2,
        checked: canAdd,
        id: `canAdd${groupId}`,
        text: 'Добавление объектов',
      },
      {
        name: 'isWritable',
        column: 1,
        row: 2,
        checked: isWritable,
        id: `isWritable${groupId}`,
        text: 'Запись',
      },
      {
        name: 'canDelete',
        column: 2,
        row: 2,
        checked: canDelete,
        id: `canDelete${groupId}`,
        text: 'Удаление объектов',
      },
      {
        name: 'canEditAccess',
        column: 2,
        row: 2,
        checked: canEditAccess,
        id: `canEditAccess${groupId}`,
        text: 'Полный доступ',
      },
      {
        name: 'isAcknowledgeable',
        column: 2,
        row: 2,
        checked: isAcknowledgeable,
        id: `isAcknowledgeable${groupId}`,
        text: 'Квитирование',
      },
    ]);

    return () => {};
  }, [stateElementConfigurationId]);

  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableRowTd}>
        <span className={styles.tableRowText} title={groupName}>
          {groupName}
        </span>
      </td>
      <td className={styles.tableRowTd}>
        <div className={styles.accessContainer}>
          <div className={styles.accessWrap}>
            <div className={styles.row}>
              {state.map((item) => {
                return (
                  item.column === 1 &&
                  item.row === 1 && (
                    <CheckBox key={state.id} rules={rules} data={item} state={state} setState={setState} handleChangeRules={handleChangeRules} />
                  )
                );
              })}
            </div>
            <div className={styles.row}>
              <div className={styles.column}>
                {state.map((item) => {
                  return (
                    item.column === 1 &&
                    item.row === 2 && (
                      <CheckBox key={state.id} rules={rules} data={item} state={state} setState={setState} handleChangeRules={handleChangeRules} />
                    )
                  );
                })}
              </div>
              <div className={styles.column}>
                {state.map((item) => {
                  return (
                    item.column === 2 &&
                    item.row === 2 && (
                      <CheckBox key={state.id} rules={rules} data={item} state={state} setState={setState} handleChangeRules={handleChangeRules} />
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className={styles.tableRowTd}>
        {dataSentSuccessfully ? (
          <HiCheck className={styles.checkControl} />
        ) : sendingData ? (
          <LoadingInTableSave />
        ) : (
          <button
            className={styles.saveControlButton}
            title={Utils.titleFromButtonSaveProperty(false, true, true, handleChangeComponentTableRow)}
            disabled={!handleChangeComponentTableRow}
            onClick={saveGroupAccessSettings}
          >
            <AiTwotoneSave
              className={handleChangeComponentTableRow ? classNames(styles.saveControl, styles.saveControlActive) : styles.saveControl}
            />
          </button>
        )}
      </td>
    </tr>
  );
}
