import Utils from "../../../utils/Utils.mjs";

export const createValidationSchemeByInputNumber = (handleBlur) => {
  return {
    required: 'Поле обязательно к заполнению',
    min: {
      value: 0,
      message: 'Минимальное допустимое значение 0',
    },
    max: {
      value: 51699,
      message: 'Максимальное допустимое значение 51699',
    },
    validate: (value) => {
      const str = value.toString();
      if (str.split('').includes('.')) {
        return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
      }
      const isNumber = Utils.testIsNumber(value);
      if (!isNumber) {
        return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
      }
      const isInteger = Utils.testIsInteger(value);
      if (!isInteger) {
        return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
      }
    },
    onBlur: handleBlur,
  };
};

export const createValidationSchemeByInputText = (handleBlur, maxLengthValue = 64) => {
  return {
    required: 'Поле обязательно к заполнению',
    minLength: {
      value: 2,
      message: 'Поле может содержать минимум 2 символов',
    },
    maxLength: {
      value: maxLengthValue,
      message: `Поле может содержать максимум ${maxLengthValue} символа`,
    },
    onBlur: handleBlur,
  };
};

export const createValidationSchemeByObjectId = (handleBlur) => {
  return {
    required: 'Поле обязательно к заполнению',
    min: {
      value: 0,
      message: 'Минимальное допустимое значение 0',
    },
    max: {
      value: Number.MAX_SAFE_INTEGER,
      message: `Минимальное допустимое значение ${Number.MAX_SAFE_INTEGER}`,
    },
    validate: (value) => {
      const str = value.toString();
      if (str.split('').includes('.')) {
        return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
      }
      const isNumber = Utils.testIsNumber(value);
      if (!isNumber) {
        return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
      }
      const isInteger = Utils.testIsInteger(value);
      if (!isInteger) {
        return 'Неправильный формат заполнения! Допустимый формат значений - целые числа!';
      }
    },
    onBlur: handleBlur,
  };
};