import { LuSettings2 } from 'react-icons/lu';
import { AiFillCode } from 'react-icons/ai';
import { useState } from 'react';

import { Tabs } from '../components.mjs';
import { useKernel } from '../../context/ContextKernel.mjs';

import { CreatingCustomParametersForms, CreatingTemplateParametersForm } from './ui/index.mjs';
import styles from './createNewParameterForm.module.scss';

const createTabsListItems = (currentLicense) => {
  return [
    {
      id: 1,
      icon: <LuSettings2 className="tabListIcon" />,
      text: 'Шаблонные',
      renderingConditions: currentLicense.hasAccessAddTemplateParameters === 'true',
    },
    {
      id: 2,
      icon: <AiFillCode className="tabListIcon" />,
      text: 'Произвольные',
      renderingConditions: currentLicense.hasAccessAddCustomParameters === 'true',
    },
  ];
};

export default function CreateNewParameterForm(props) {
  const { setComponentsTypeId, setConfiguration, setIsError, setErrorMessage, selectedParameter } = props;

  const [tabVisible, setTabVisible] = useState(1);
  const { currentLicense } = useKernel();

  const handleTabVisible = (event) => {
    const tabId = parseInt(event.currentTarget.dataset.tab);
    setTabVisible(tabId);
  };

  return (
    <div className={styles.container}>
      <Tabs tabsList={createTabsListItems(currentLicense)} tabVisible={tabVisible} handleTabVisible={handleTabVisible} />

      <div className={styles.formsContainer}>
        {tabVisible === 1 && (
          <CreatingTemplateParametersForm
            setComponentsTypeId={setComponentsTypeId}
            setConfiguration={setConfiguration}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
            selectedParameter={selectedParameter}
          />
        )}
        {tabVisible === 2 && (
          <CreatingCustomParametersForms
            setComponentsTypeId={setComponentsTypeId}
            setConfiguration={setConfiguration}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
            selectedParameter={selectedParameter}
          />
        )}
      </div>
    </div>
  );
}
