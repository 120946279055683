import {
  CreatingNewObject,
  CreatingNewModule,
  Property,
  PropertyHistory,
  CreatingNewDeviceInModule,
  SettingsPermissionObject,
  CreateNewParameterForm,
  CreateNewPropertyForm,
} from '../components/components.mjs';

export function ComponentPropertyEditor(props) {
  const {
    componentsTypeId,
    setComponentsTypeId,
    stateElementConfigurationId,
    setIsError,
    setErrorMessage,
    selectedParameter,
    setConfiguration,
    property,
    setProperty,
    buildConfigurationTree,
  } = props;

  const componentsByPropertyEditor = {};

  const TABLE_PROPERTY = 0;
  const TABLE_HISTORY = 1;
  const CREATE_NEW_OJECT = 2;
  const CREATE_NEW_MODULE = 3;
  const CREATE_NEW_DEVICE_IN_MODULE = 4;
  const SETTINGS_PERMISSION_OBJECT = 5;
  const CREATE_NEW_PARAMETER = 6;
  const CREATE_NEW_PROPERTY = 7;

  componentsByPropertyEditor[TABLE_PROPERTY] = (
    <Property
      setComponentsTypeId={setComponentsTypeId}
      stateElementConfigurationId={stateElementConfigurationId}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      setProperty={setProperty}
      setConfiguration={setConfiguration}
      buildConfigurationTree={buildConfigurationTree}
    />
  );

  componentsByPropertyEditor[TABLE_HISTORY] = (
    <PropertyHistory setIsError={setIsError} setErrorMessage={setErrorMessage} property={property} setComponentsTypeId={setComponentsTypeId} />
  );

  componentsByPropertyEditor[CREATE_NEW_OJECT] = (
    <CreatingNewObject
      setComponentsTypeId={setComponentsTypeId}
      selectedParameter={selectedParameter}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      setConfiguration={setConfiguration}
    />
  );

  componentsByPropertyEditor[CREATE_NEW_MODULE] = (
    <CreatingNewModule
      setComponentsTypeId={setComponentsTypeId}
      selectedParameter={selectedParameter}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      setConfiguration={setConfiguration}
    />
  );

  componentsByPropertyEditor[CREATE_NEW_DEVICE_IN_MODULE] = (
    <CreatingNewDeviceInModule
      setComponentsTypeId={setComponentsTypeId}
      selectedParameter={selectedParameter}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      setConfiguration={setConfiguration}
    />
  );

  componentsByPropertyEditor[SETTINGS_PERMISSION_OBJECT] = (
    <SettingsPermissionObject
      stateElementConfigurationId={stateElementConfigurationId}
      setConfiguration={setConfiguration}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
    />
  );

  componentsByPropertyEditor[CREATE_NEW_PARAMETER] = (
    <CreateNewParameterForm
      setComponentsTypeId={setComponentsTypeId}
      setConfiguration={setConfiguration}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      selectedParameter={selectedParameter}
    />
  );
  componentsByPropertyEditor[CREATE_NEW_PROPERTY] = (
    <CreateNewPropertyForm
      setComponentsTypeId={setComponentsTypeId}
      setConfiguration={setConfiguration}
      setIsError={setIsError}
      setErrorMessage={setErrorMessage}
      selectedParameter={selectedParameter}
    />
  );

  let components;
  const setComponent = () => {
    if (componentsTypeId in componentsByPropertyEditor) {
      components = componentsByPropertyEditor[componentsTypeId];
    } else {
      components = (
        <div style={{ display: 'flex', flex: '1', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ color: '#d61111' }}>Произошла ошибка при выборе действия!</p>
        </div>
      );
    }
  };
  setComponent();

  return <>{components}</>;
}
