import { Struct } from '../../../Scada/Parser/Struct.mjs';
import { StringStruct } from '../../../Scada/Parser/StringStruct.mjs';


class PropertyHistoryStruct extends Struct {

  constructor(
    id = 0, // 4 uint 32
    timestamp = 0, //4 uint 32
    rawValue = 0n, //8 bigInt64
    strValue = new StringStruct(''),
    sourceId = 0n,//4 uint 32
  ) {
    super();
    this.id = id;
    this.timestamp = timestamp;
    this.rawValue = rawValue;
    this.strValue = strValue;
    this.sourceId = sourceId;
  }

  readFrom(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    this.id = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.timestamp = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;
    this.rawValue = dataView.getBigUint64(endOffset, isLittleEndian);
    endOffset += 8;
    endOffset = this.strValue.readFrom(dataView, endOffset, isLittleEndian);
    this.sourceId = dataView.getUint32(endOffset, isLittleEndian);
    endOffset += 4;

    return endOffset;
  }

  writeTo(dataView, startOffset, isLittleEndian = true) {
    let endOffset = startOffset;
    dataView.setUint32(endOffset, this.id, isLittleEndian);
    endOffset += 4;
    dataView.setUint32(endOffset, this.timestamp, isLittleEndian);
    endOffset += 4;
    dataView.setBigUint64(endOffset, this.rawValue, isLittleEndian);
    endOffset += 8;
    endOffset = this.strValue.writeTo(dataView, endOffset, isLittleEndian);
    dataView.setUint32(endOffset, this.sourceId, isLittleEndian);
    endOffset += 4;

    return endOffset;
  }

  getCurrentSize() {
    return 0
      + 4 // id
      + 4 // timestamp
      + 8 // rawValue
      + this.strValue.getCurrentSize() // strValue
      + 4; // sourceId
  }

  clone() {
    const clone = new PropertyHistoryStruct(
      this.id,
      this.timestamp,
      this.rawValue,
      this.strValue.clone(),
      this.sourceId,
    );
    clone.setParserOptions(this.getParserOptions());
    return clone;
  }
}


export {

  PropertyHistoryStruct,
};
