import classNames from 'classnames';
import { MdSearch } from 'react-icons/md';

import styles from './searchTreeUser.module.scss';

export default function SearchTreeUser(props) {
  const { setSearchTreeUserInput, searchTreeUserInputValue } = props;

  const handleChangeSearchTreeUserInput = (event) => {
    setSearchTreeUserInput(event.target.value);
  };

  return (
    <div className={styles.searchTreeUser}>
      <input className={styles.searchTreeUserInput} type="text" value={searchTreeUserInputValue} onInput={handleChangeSearchTreeUserInput} />
      <MdSearch className={classNames(styles.searchTreeUserIcon, searchTreeUserInputValue?.length > 0 && styles.searchTreeUserIconActive)} />
    </div>
  );
}
